<template>
  <div class="events-cont">
    <el-tabs v-model="activeTab">
      <el-tab-pane label="Attended Events" name="attendedEvents">
        <p v-if="attendedEvents.length == 0" class="empty-text ft-primary ft-size-18 text-center">You don't have any attended events at the moment</p>
        <div class="events-inner-cont horizontal layout wrap">
          <div v-for="(event,index) in attendedEvents" v-bind:key="index" class="event border-radius-4 vertical layout align-center justify-center relative">
            <div class="circle border-radius-full"></div>
            <p class="ft-size-16 ft-neutral-white text-center">{{event.name}}</p>
            <p class="certificate-url ft-size-14 ft-neutral-white pointer m-0" v-on:click="openPage(event.cert_url)"><i class="fas fa-link"></i> CERTIFICATE</p>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="Upcoming Events" name="upcomingEvents">
        <p v-if="upcomingEvents.length == 0" class="empty-text ft-primary ft-size-18 text-center">You don't have any upcoming events at the moment</p>
        <div class="events-inner-cont horizontal layout wrap">
          <div v-for="(event,index) in upcomingEvents" v-bind:key="index" class="event border-radius-4 vertical layout align-center justify-center">
            <div class="circle border-radius-full"></div>
            <p class="ft-size-16 ft-neutral-white text-center">{{event.name}}</p>
            <button class="ft-size-16 ft-bright-green border-radius-2" v-on:click="openPage(event.url)">
              <i class="fas fa-long-arrow-alt-right"></i>
              Register
            </button>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
  import Vue from "vue";
  import {TabPane,Tabs} from "element-ui";

  Vue.component("el-tabs",Tabs);
  Vue.component("el-tab-pane",TabPane);

  export default {
    name: "events",
    components: {
      Tabs,
      TabPane
    },
    created() {
      this.getAttendedEvents();
      this.getUpcomingEvents();
    },
    mounted() {

    },
    data() {
      return {
        activeTab: "attendedEvents",
        attendedEvents: [],
        upcomingEvents: []
      }
    },
    methods: {
      getAttendedEvents: function() {
        let module = this;

        this.xhrRequest("GET","/user/attended_events",null,
          function(response) {
            module.attendedEvents = response.attended_events;
          },
          function(error) {
            console.log(error);
          }
        );
      },
      getUpcomingEvents: function() {
        let module = this;

        this.xhrRequest("GET","/user/upcoming_events",null,
          function(response) {
            module.upcomingEvents = response.upcoming_events;
          },
          function(error) {
            console.log(error);
          }
        );
      },
      openPage: function(url) {
        window.open(url,'_blank');
      }
    }
  };
</script>

<style scoped lang="scss">
  .events-cont{
    .empty-text{
      margin-top: 75px;
    }
    .events-inner-cont{
      padding: 32px 0;
      .event{
        width: 250px;
        height: 250px;
        margin-right: 12px;
        margin-bottom: 16px;
        background: linear-gradient(180deg, #2D2F3D 37.14%, #00AF5B 146.11%), #FFFFFF;
        box-shadow: 2px 3px 10px rgba(119, 119, 119, 0.3);
        .circle{
          width: 85px;
          height: 85px;
          background-color: transparent;
          border: 5px solid #FFF;
        }
        button{
          width: 100px;
          height: 32px;
          margin-top: 12px;
          background-color: transparent;
          border: 1px solid #0EEC6A;
          &:hover{
            background-color: #FFF;
            border-color: #FFF;
            color: #234a43;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 768px){
    .events-cont{
      .events-inner-cont{
        .event{
          margin: 0 auto 16px auto;
        }
      }
    }
  }
</style>