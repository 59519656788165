<template>
  <div class="upload-stack-cont bg-neutral-white border-radius-4 relative shadow">
    <p class="ft-size-14 ft-primary-dark"><span class="ft-w-bold">Uploading: </span>{{data.fileName}}</p>
    <el-progress v-bind:text-inside="true" v-bind:stroke-width="20" v-bind:percentage="data.progress" status="success"></el-progress>
  </div>
</template>

<script>
  import Vue from "vue";
  import {Progress} from "element-ui";

  Vue.component("el-progress",Progress);

  export default {
    name: "upload-stack",
    components: {
      Progress
    },
    mounted() {
      
    },
    props: {
      data: null
    },
    data() {
      return {

      }
    },
    methods: {
   
    }
  };
</script>

<style scoped lang="scss">
  .upload-stack-cont{
    padding: 10px 14px;
    margin-bottom: 8px;
    width: 300px;
    &>p{
      margin: 0 0 10px 0;
      line-height: 20px;
    }
  }

  @media only screen and (max-width: 575px){
    .upload-stack-cont{
      
    }
  }
</style>