<template>
  <div id="app" class="relative">
    <router-view/>
    <div class="upload-stack fixed">
      <upload-stack v-for="(item,index) in $store.state.uploadStack" v-bind:key="index" v-bind:data="item"></upload-stack>
    </div>
  </div>
</template>

<script>
  import Vue from "vue";
  import UploadStackItem from "./components/Shared/UploadStack.vue";

  Vue.component("upload-stack",UploadStackItem);

  export default {
    name: "app",
    components: {
      UploadStackItem
    },
    created() {
      this.setPageTitle();
    },
    mounted() {

    },
    watch: {
      $route: function() {
        this.setPageTitle();
      }
    },
    data() {
      return {
        
      }
    },
    methods: {
      setPageTitle: function() {
        if(this.$route.meta.pageTitle)
          document.title = "VirtuPort | " + this.$route.meta.pageTitle;
      }
    }
  };
</script>

<style lang="scss">
  #app{
    min-height: 100vh;
    .upload-stack{
      top: 15px;
      right: 15px;
      z-index: 9999999;
    }
  }
</style>