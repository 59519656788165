<template>
  <div class="fallback-cont">
    <p class="text-center">404 | NOT FOUND</p>
  </div>
</template>

<script>
  export default {
    name: "fallback",
    mounted() {

    },
    data() {
      return {

      }
    },
    methods: {

    }
  };
</script>

<style scoped lang="scss">
  .fallback-cont{
    font-size: 70px;
    padding-top: 200px;
  }
</style>