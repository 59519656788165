import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import mixin from "./assets/scripts/helper.js";
import {Notification} from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import lang from "element-ui/lib/locale/lang/en";
import locale from "element-ui/lib/locale";

require("./assets/styles/main.scss");
require("./assets/styles/styles.scss");
require("./assets/styles/normalize/8.0.1/normalize.min.css");
require("./assets/styles/fontawesome-free-5.15.2-web/css/all.min.css");

Vue.mixin(mixin);
locale.use(lang);
Vue.prototype.$notify = Notification;
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app")