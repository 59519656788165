<template>
  <div class="sponsor-page-cont">
    <el-tabs v-model="activeTab" v-if="found">
      <el-tab-pane label="Sponsor Package" name="sponsorPackage">
        <div class="sponsor-package-cont w-100 relative">
          <p class="title ft-size-18 ft-green">Sponsor Package</p>
          <div class="tuple-cont">
            <div class="tuple">
              <div class="tuple horizontal layout align-center relative">
                <p class="ft-primary ft-size-16 m-0">Package:</p>
                <select class="ft-primary w-100 pointer" v-bind:class="{'placeholder-color':selectedOption == '0'}" v-model="selectedOption">
                  <option value="0" disabled>Choose package</option>
                  <option v-for="(item,index) in packages" v-bind:key="'package' + index" v-bind:value="item.id" class="ft-primary">{{item.name}}</option>
                </select>
                <i class="fas fa-chevron-down postfix-icon ft-icon-color absolute" v-on:click="clickSelect"></i>
              </div>
            </div>
            <div class="tuple">
              <div class="tuple horizontal layout align-center">
                <p class="ft-primary ft-size-16 m-0">Package Expiration Date:</p>
                <el-date-picker v-model="packageExpireDate" type="date" placeholder="Pick a day" format="yyyy-MM-dd" class="w-100" ref="packageDate"></el-date-picker>
              </div>
            </div>
          </div>
          <button class="primary ft-size-16 ft-neutral-white border-radius-4 absolute" v-on:click="submitSponsorPackage">
            Submit
          </button>
        </div>
      </el-tab-pane>
      <el-tab-pane label="Sponsor Items" name="sponsorItems">
        <div class="sponsor-items-cont w-100 relative">
          <p class="title ft-size-18 ft-green">Sponsor Items</p>
          <div class="form-cont">
            <div v-for="(field,fieldIndex) in sponsorItems" v-bind:key="'sponsor' + fieldIndex" class="field flex">
              <field-component v-bind:details="field" v-bind:noOptionalLabel="true" v-bind:adminMode="true" v-bind:statuses="statuses"></field-component>
            </div>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane v-if="speakers && speakers.length > 0" label="Speakers Items" name="speakersItems">
        <div class="speakers-items-cont w-100 relative">
          <p class="title ft-size-18 ft-green">Speakers</p>
          <div class="speakers-cont horizontal layout wrap">
            <speaker-card v-for="(speaker,index) in speakers" v-bind:key="'speaker' + index" v-bind:speaker="speaker" v-bind:admin="true" v-bind:entity="'speaker'" v-bind:statuses="statuses"></speaker-card>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane v-for="(tab,index) in listingTabs" v-bind:key="'listingtab' + index" v-bind:label="tab.tab_name" v-bind:name="tab.tab_name">
        <div class="speakers-items-cont w-100 relative">
          <p class="title ft-size-18 ft-green">{{tab.tab_name}}</p>
          <div class="speakers-cont horizontal layout wrap">
            <speaker-card v-for="(listingTabItem,index) in tab.list" v-bind:key="'listingTabItem' + index" v-bind:speaker="listingTabItem" v-bind:admin="true" v-bind:entity="tab.tab_name" v-bind:statuses="statuses"></speaker-card>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane v-for="(tab,index) in dynamicTabs" v-bind:key="'dynamic' + index" v-bind:label="tab.tab_name" v-bind:name="tab.tab_name">
        <div class="dynamic-items-cont w-100 relative">
          <p class="title ft-size-18 ft-green">{{tab.tab_name}} Items</p>
          <div class="form-cont">
            <div v-for="(field,fieldIndex) in tab.tab_form" v-bind:key="'dynamicfield' + fieldIndex" class="field flex">
              <field-component v-bind:details="field" v-bind:noOptionalLabel="true" v-bind:adminMode="true" v-bind:statuses="statuses"></field-component>
            </div>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
    <p v-else class="ft-size-18 ft-green">Sponsor not found</p>
  </div>
</template>

<script>
  import Vue from "vue";
  import {TabPane,Tabs,Dialog,DatePicker} from "element-ui";
  import FieldComponent from "../../components/Shared/FieldComponent.vue";
  import SpeakerCard from "../../components/Sponsor/SpeakerCard.vue";

  Vue.component("el-tabs",Tabs);
  Vue.component("el-tab-pane",TabPane);
  Vue.component("el-dialog",Dialog);
  Vue.component("field-component",FieldComponent);
  Vue.component("speaker-card",SpeakerCard);
  Vue.component("el-date-picker",DatePicker);

  export default {
    name: "sponsor-page",
    components: {
      Tabs,
      TabPane,
      Dialog,
      FieldComponent,
      SpeakerCard,
      DatePicker
    },
    created() {
      this.userGroupId = this.$route.params.id; 
      this.sponsorId = this.$route.params.sponsorId;
      this.getTabs();
    },
    mounted() {

    },
    computed: {

    },
    data() {
      return {
        userGroupId: "",
        sponsorId: "",
        sponsorItems: [],
        speakers: [],
        listingTabs: [],
        dynamicTabs: [],
        found: false,
        statuses: [],
        packages: [],
        packageExpireDate: "",
        activeTab: "sponsorPackage",
        selectedOption: 0
      }
    },
    methods: {
      clickSelect: function() {
        this.$el.querySelector("select").click();
      },
      getTabs: function() {
        let module = this;

        let url = "/admin/user_groups/" + this.userGroupId + "/user_sponsor/" + this.sponsorId + "/user_sponsor_required_items";
        this.xhrRequest("GET",url,null,
          function(response) {
            module.found = true;
            module.sponsorItems = response.required_items;
            module.speakers = response.speakers;
            module.listingTabs = response.listing_tabs;
            module.dynamicTabs = response.tabs;
            module.statuses = response.status;
            module.packages = response.packages;
            if(module.packages && module.packages.find(item => item.selected))
              module.selectedOption = module.packages.find(item => item.selected).id;
            module.packageExpireDate = response.package_expiration_date;
          },
          function(error) {
            console.log(error);
          }
        );
      },
      submitSponsorPackage: function() {
        let module = this;

        if(this.selectedOption == 0 || !this.packageExpireDate){
          this.showNotification("Error","Please choose package and fill in expiration date","error");
          return false;
        }

        let data = {
          package_id: this.selectedOption,
          package_expiration_date: this.$refs.packageDate.$el.querySelector("input").value
        };

        let url = "/admin/user_groups/" + this.userGroupId + "/user_sponsor/" + this.sponsorId + "/user_sponsor_package";
        this.xhrRequest("POST",url,data,
          function(response) {
            module.getTabs();
            module.showNotification("Success","Sponsor package updated successfully","success");
          },
          function(error) {
            let errorMsg = error.msg;
            module.showNotification("Error",errorMsg,"error");
          }
        );
      }
    }
  };
</script>

<style scoped lang="scss">
  .sponsor-page-cont{
    .el-tabs{
      max-width: calc(100vw - 350px);
    }
    .sponsor-items-cont,.dynamic-items-cont,.sponsor-package-cont{
      padding: 32px 0;
      .title{
        margin: 0 0 32px 0;
      }
      .form-cont{
        max-width: 100%;
        &>div{
          margin-bottom: 18px;
        }
      }
    }
    .sponsor-package-cont{
      .tuple-cont{
        max-width: 100%;
        .tuple{
          margin-bottom: 20px;
          p{
            width: 350px;
            padding-right: 24px;
          }
          input{
            height: 48px;
          }
          select{
            height: 48px;
            padding-right: 35px;
            &.placeholder-color{
              color: #c4c4c4;
            }
          }
          i{
            right: 15px;
            top: 16px;
            pointer-events: none;
          }
        }
      }
      button{
        top: 22px;
        right: 0;
        height: 42px;
        width: 115px;
      }
    }
    .speakers-items-cont{
      padding: 32px 0;
      .title{
        margin: 0 0 42px 0;
      }
    }
  }

  @media only screen and (max-width: 575px){
    .sponsor-page-cont{

    }
  }
</style>