<template>
  <div class="my-profile-cont">
    <el-tabs v-model="activeTab">
      <el-tab-pane label="Personal Information" name="personalInfo">
        <div class="personal-info-cont w-100">
          <p class="title ft-size-18 ft-green">Personal Information</p>
          <div v-for="(tuple,index) in userInfo" v-bind:key="index">
            <div v-for="(item,index) in tuple" v-bind:key="index" class="tuple horizontal layout align-center">
              <p class="ft-primary ft-size-16 m-0">{{index}}:</p>
              <input type="text" class="ft-primary w-100" disabled v-bind:value="item">
            </div>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="Reset Password" name="resetPassword">
        <div class="reset-password-cont w-100">
          <div class="tuple horizontal layout align-center">
            <p class="ft-primary ft-size-16 m-0">Current Password:</p>
            <input type="password" placeholder="Enter your current password" class="ft-primary w-100" v-model="oldPassword">
          </div>
          <div class="tuple horizontal layout align-center">
            <p class="ft-primary ft-size-16 m-0">New Password:</p>
            <input type="password" placeholder="Enter your new password" class="ft-primary w-100" v-model="newPassword">
          </div>
          <div class="tuple horizontal layout">
            <span class="flex"></span>
            <button class="primary ft-size-16 ft-neutral-white border-radius-4" v-on:click="changePassword">Change Password</button>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
  import Vue from "vue";
  import {TabPane,Tabs} from "element-ui";

  Vue.component("el-tabs",Tabs);
  Vue.component("el-tab-pane",TabPane);

  export default {
    name: "my-profile",
    components: {
      Tabs,
      TabPane
    },
    created() {
      this.getUserInfo();
    },
    mounted() {

    },
    computed: {
      currentBoard: function() {
        return this.$route.path.split("/")[1];
      }
    },
    data() {
      return {
        activeTab: "personalInfo",
        userInfo: [],
        oldPassword: "",
        newPassword: ""
      }
    },
    methods: {
      getUserInfo: function() {
        let module = this;

        let url = "";
			  switch(this.currentBoard){
          case "user-dashboard":
            url = "/user/info";
            break;
          case "sponsor-dashboard":
            url = "/sponsor/info";
            break;
        }

        this.xhrRequest("GET",url,null,
          function(response) {
            switch(module.currentBoard){
              case "user-dashboard":
                module.userInfo = response.user_info;   
                break;
              case "sponsor-dashboard":
                module.userInfo = response.sponsor_info;
                break;
            }
          },
          function(error) {
            console.log(error);
          }
        );
      },
      changePassword: function() {
        let module = this;

        if(this.oldPassword.trim() == "" || this.newPassword.trim() == ""){
          this.showNotification("Error","Please fill in both current and new password","error");
          return false;
        }

        let data = {
          password: this.oldPassword,
          new_password: this.newPassword
        };

        let url = "";
			  switch(this.currentBoard){
          case "user-dashboard":
            url = "/user/reset_password";
            break;
          case "sponsor-dashboard":
            url = "/sponsor/reset_password";
            break;
        }

        this.xhrRequest("POST",url,data,
          function(response) {
            module.showNotification("Success","Password updated successfully","success");
          },
          function(error) {
            let errorMsg = error.msg;
            module.showNotification("Error",errorMsg,"error");
          }
        );
      }
    }
  };
</script>

<style scoped lang="scss">
  .my-profile-cont{
    .personal-info-cont{
      padding: 32px 0;
      max-width: 700px;
      .title{
        margin: 0 0 32px 0;
      }
    }
    .reset-password-cont{
      padding: 32px 0;
      max-width: 700px;
    }
    .tuple{
      margin-bottom: 20px;
      p{
        width: 225px;
      }
      input{
        height: 48px;
      }
    }
    button{
      width: 150px;   
      height: 40px;
      margin-top: 24px;
    }
  }

  @media only screen and (max-width: 768px){
    .my-profile-cont{

    }
  }
</style>