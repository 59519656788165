<template>
  <div class="delegate-page-cont">
    <el-tabs v-model="activeTab" v-if="found">
      <el-tab-pane label="Delegate Info" name="delegateInfo">
        <div class="delegate-info-cont w-100 relative">
          <p class="title ft-size-18 ft-green">Delegate Info</p>
          <div v-for="(tuple,index) in delegateInfo" v-bind:key="index" class="tuple-cont">
            <div class="tuple horizontal layout align-center">
              <p class="ft-primary ft-size-16 m-0">{{tuple.key}}:</p>
              <input v-if="tuple.field_type == 'text'" type="text" class="ft-primary w-100" v-model="delegateInfo[index].value" v-bind:data-id="tuple.id" ref="delegateInfoInput">
              <input v-if="tuple.field_type == 'number'" type="number" class="ft-primary w-100" v-model="delegateInfo[index].value" v-bind:data-id="tuple.id" ref="delegateInfoInput">
              <el-switch v-if="tuple.field_type == 'boolean'" active-color="#13ce66" v-model="delegateInfo[index].value" class="w-100" v-bind:data-id="tuple.id" ref="delegateInfoInput"></el-switch>
              <el-date-picker v-if="tuple.field_type == 'date'" v-model="delegateInfo[index].value" type="date" placeholder="Pick a day" format="yyyy-MM-dd" class="w-100" v-bind:data-id="tuple.id" ref="delegateInfoInput"></el-date-picker>
            </div>
          </div>
          <button class="primary ft-size-16 ft-neutral-white border-radius-4 absolute" v-on:click="submitInfo">
            Submit
          </button>
        </div>
      </el-tab-pane>
      <el-tab-pane label="Delegate Password" name="delegatePassword">
        <div v-if="authorized" class="password-body">
          <div class="tuple">
            <p class="ft-primary ft-size-16">Current Password:</p>
            <input type="text" placeholder="Enter admin password" class="ft-primary w-100" disabled v-model="currentPassword">
          </div>
          <p class="title ft-size-18 ft-green">Change delegate password</p>
          <div class="tuple">
            <p class="ft-primary ft-size-16">New Password:</p>
            <input type="password" placeholder="Enter new password" class="ft-primary w-100" v-model="newPassword">
          </div>
          <div class="tuple">
            <p class="ft-primary ft-size-16">Confirm Password:</p>
            <input type="password" placeholder="Enter confirm password" class="ft-primary w-100" v-model="confirmPassword">
          </div>
          <button class="primary ft-size-16 ft-neutral-white w-100 border-radius-8 d-block" v-on:click="updatePassword">Update Password</button>
        </div>
        <div v-else class="authorize-cont">
          <p class="title ft-size-18 ft-primary">To access this section, please enter Admin password below</p>
          <div class="tuple">
            <p class="ft-primary ft-size-16">Password:</p>
            <input type="password" placeholder="Enter admin password" class="ft-primary w-100" v-model="adminPassword">
          </div>
          <button class="primary ft-size-16 ft-neutral-white w-100 border-radius-8 d-block" v-on:click="authorize">Authorize</button>
        </div>
      </el-tab-pane>
    </el-tabs>
    <p v-else class="ft-size-18 ft-green">Delegate not found</p>
  </div>
</template>

<script>
  import Vue from "vue";
  import {TabPane,Tabs} from "element-ui";

  Vue.component("el-tabs",Tabs);
  Vue.component("el-tab-pane",TabPane);

  export default {
    name: "delegate-page",
    components: {
      Tabs,
      TabPane
    },
    created() {
      this.userGroupId = this.$route.params.id; 
      this.delegateId = this.$route.params.delegateId;
      this.getDelegateInfo();
    },
    mounted() {

    },
    data() {
      return {
        userGroupId: "",
        delegateId: "",
        found: false,
        activeTab: "delegateInfo",
        delegateInfo: [],
        authorized: false,
        adminPassword: "",
        currentPassword: "",
        newPassword: "",
        confirmPassword: ""
      }
    },
    methods: {
      getDelegateInfo: function() {
        let module = this;

        let url = "/admin/user_groups/" + this.userGroupId + "/delegate/" + this.delegateId;
        this.xhrRequest("GET",url,null,
          function(response) {
            module.found = true;
            module.delegateInfo = response.data;
          },
          function(error) {
            console.log(error);
          }
        );
      },
      submitInfo: function() {
        let module = this;

        let delegateInfoData = [];
        this.delegateInfo.forEach(function(item,index){
          let id = "";
          let value = "";

          switch(item.field_type){
            case "text":
            case "number":
              id = module.$refs.delegateInfoInput[index].getAttribute("data-id");
              value = module.$refs.delegateInfoInput[index].value;
              break;
            case "boolean":
              id = module.$refs.delegateInfoInput[index].$el.getAttribute("data-id");
              value = module.data[index].value;
              break;
            case "date":
              id = module.$refs.delegateInfoInput[index].$el.getAttribute("data-id");
              value = module.$refs.delegateInfoInput[index].$el.querySelector("input").value;
              break;
          }

          let delegateInfoItem = {
            id: id,
            value: value
          };

          delegateInfoData.push(delegateInfoItem);
        });
        
        let data = {
          data: delegateInfoData
        };

        let url = "/admin/user_groups/" + this.userGroupId + "/delegate/" + this.delegateId;
        this.xhrRequest("PUT",url,data,
          function(response) {
            module.showNotification("Success","Delegate info updated successfully","success");
          },
          function(error) {
            console.log(error);
          }
        );
      },
      authorize: function() {
        let module = this;

        if(this.adminPassword.trim() == ""){
          this.showNotification("Error","Please fill in password","error");
          return false;
        }

        let data = {
          password: this.adminPassword
        };

        let url = "/admin/user_groups/" + this.userGroupId + "/delegate/" + this.delegateId + "/unlock_change_password";
        this.xhrRequest("POST",url,data,
          function(response) {
            module.authorized = true;
            module.currentPassword = response.delegate_password;
          },
          function(error) {
            console.log(error);
          }
        );
      },
      updatePassword: function() {
        let module = this;

        if(this.newPassword.trim() == "" || this.confirmPassword.trim() == ""){
          this.showNotification("Error","Please fill in new password and confirm password","error");
          return false;
        }

        if(this.newPassword != this.confirmPassword){
          this.showNotification("Error","New password doesn't match confirm password","error");
          return false;
        }

        let data = {
          password: this.newPassword
        };

        let url = "/admin/user_groups/" + this.userGroupId + "/delegate/" + this.delegateId + "/change_password";
        this.xhrRequest("PUT",url,data,
          function(response) {
            module.showNotification("Success","Delegate password updated successfully","success");
            window.location.reload();
          },
          function(error) {
            module.showNotification("Error",error.msg,"error");
            console.log(error);
          }
        );
      }
    }
  };
</script>

<style scoped lang="scss">
  .delegate-page-cont{
    .delegate-info-cont{
      padding: 32px 0;
      .title{
        margin: 0 0 32px 0;
      }
      .tuple-cont{
        max-width: 700px;
        .tuple{
          margin-bottom: 20px;
          p{
            width: 350px;
            padding-right: 24px;
          }
          input{
            height: 48px;
          }
        }
      }
      button{
        top: 22px;
        right: 0;
        height: 42px;
        width: 115px;
      }
    }
    .authorize-cont{
      .tuple{
        width: 300px;
        p{
          margin: 0 0 8px 0;
        }
        input{
          height: 36px;
        }
      }
      button{
        width: 135px;
        height: 32px;
        margin: 18px 0 0 0;
      }
    }
    .password-body{
      padding-top: 24px;
      .title{
        margin: 50px 0 20px 0;
      }
      .tuple{
        width: 300px;
        margin-bottom: 20px;
        p{
          margin: 0 0 8px 0;
        }
        input{
          height: 36px;
        }
      }
      button{
        width: 180px;
        height: 32px;
        margin: 18px 0 0 0;
      }
    }
  }

  @media only screen and (max-width: 575px){
    .delegate-page-cont{

    }
  }
</style>