<template>
  <div class="account-reset-password-cont relative">
    <div v-if="token" class="form-cont d-flex align-center relative">
      <div class="inner-form-cont w-100 height-fit">
        <img v-bind:src="userFormImage" alt="Form logo" class="w-100">
        <div class="tuple">
          <p class="ft-primary ft-size-16">New password:</p>
          <input type="text" placeholder="Enter your new password" class="ft-primary w-100" v-model="newPassword">
        </div>
        <div class="tuple">
          <p class="ft-primary ft-size-16">Confirm password:</p>
          <input type="password" placeholder="Confirm your password" class="ft-primary w-100" v-model="confirmPassword">
        </div>
        <button class="primary ft-size-18 ft-neutral-white w-100 border-radius-32 d-block" v-on:click="reset">Reset Password</button>
      </div>
      <p class="copyrights-text ft-size-12 ft-grey absolute text-center m-0">{{currentYear}} Copyrights © <span class="ft-green">MENA ISC</span></p>
    </div>
    <img v-else src="../assets/images/spinner.gif" alt="spinner" style="position: absolute;width: 35px;left: calc(50% - 25px);top: 75px;">
  </div>
</template>

<script>
  export default {
    name: "account-reset-password",
    created() {
      this.getAssets();
      this.validateToken();
    },
    mounted() {

    },
    computed: {
      currentYear: function() {
        return new Date().getFullYear();
      }
    },
    data() {
      return {
        userFormImage: "",
        newPassword: "",
        confirmPassword: "",
        token: ""
      }
    },
    methods: {
      getAssets: function() {
        let module = this;

        this.xhrRequest("GET","/banners",null,
          function(response) {
            module.userFormImage = response.banners.registration_logo;
          },
          function(error) {
            console.log(error);
          }
        );
      },
      validateToken: function() {
        let module = this;

        let data = {
          token: this.$route.params.token
        };

        this.xhrRequest("POST","/user/validate_reset_password_token",data,
          function(response) {
            if(response.token)
              module.token = response.token;
            else
              module.$router.push({path:"/user-login"});
          },
          function(error) {
            console.log(error);
          }
        );
      },
      reset: function() {
        let module = this;

        if(this.newPassword.trim() == "" || this.confirmPassword.trim() == ""){
          this.showNotification("Error","Please fill in both password and confirm password","error");
          return false;
        }

        if(this.newPassword != this.confirmPassword){
          this.showNotification("Error","New password and confirmed password doesn't match","error");
          return false;
        }

        let data = {
          password: this.newPassword,
          token: this.token
        };

        this.xhrRequest("POST","/user/reset_password_by_token",data,
          function(response) {
            module.showNotification("Success","Password has been updated successfully","success");
            module.$router.push({path:"/user-login"});
          },
          function(error) {
            console.log(error);
          }
        );
      }
    }
  };
</script>

<style scoped lang="scss">
  .account-reset-password-cont{
    .form-cont{
      width: 425px;
      max-width: 100%;
      padding: 0 42px;
      margin: 0 auto;
      min-height: 100vh;
      .inner-form-cont{
        margin: 100px 0;
        img{
          margin-bottom: 36px;
        }
        &>div:nth-of-type(1){
          margin-bottom: 20px;
        }
        .tuple{
          p{
            margin: 0 0 8px 0;
          }
          input{
            height: 48px;
          }
        }
        button{
          max-width: 300px;
          height: 48px;
          margin: 68px auto 0 auto;
        }
      }
      .copyrights-text{
        bottom: 24px;
        right: 0;
        left: 0;
      }
    }
  }

  @media only screen and (max-width: 575px){
    .account-reset-password-cont{
      .form-cont{
        padding: 0 24px;
        .inner-form-cont{
          margin: -100px 0 0 0;
        }
      }
    }
  }
</style>