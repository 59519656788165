<template>
  <div class="certificates-cont w-100 relative">
    <p class="title ft-size-20 ft-green ft-w-bold">Certificates</p>
    <div class="certificates-cont horizontal layout wrap">
      <certificate-card v-for="(certificateItem,index) in certificates" v-bind:key="index" v-bind:certificate="certificateItem" @refresh="getCertificates"></certificate-card>
    </div>
    <button class="primary ft-size-16 ft-neutral-white border-radius-4 absolute" v-on:click="newCertificateDialogVisible = true">+ Create New Certificate</button>
    <el-dialog class="new-certificate-dialog" title="Create New Certificate" v-bind:visible.sync="newCertificateDialogVisible" width="500px">
      <div class="form-cont">
        <div class="field flex">
          <p class="ft-size-18 ft-primary">Certificate name:</p>
          <input type="text" placeholder="Certificate name" spellcheck="false" class="ft-size-16 ft-primary w-100" v-model="certificateName">
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <button class="secondary ft-size-16 ft-primary border-radius-4" v-on:click="newCertificateDialogVisible = false">Cancel</button>
        <button class="primary ft-size-16 ft-neutral-white border-radius-4 relative" v-on:click="addCertificate">Create</button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import Vue from "vue";
  import CertificateCard from "../../components/Admin/CertificateCard.vue";
  
  Vue.component("certificate-card",CertificateCard);

  export default {
    name: "certificates",
    mounted() {
      this.getCertificates();
    },
    watch: {
      newCertificateDialogVisible: function() {
        if(this.newCertificateDialogVisible){
          this.certificateName = "";
        }
      }
    },
    data() {
      return {
        certificates: [],
        newCertificateDialogVisible: false,
        certificateName: ""
      }
    },
    methods: {
      getCertificates: function() {
        let module = this;

        this.xhrRequest("GET","/admin/certificates",null,
          function(response) {
            module.certificates = response.certificates;
          },
          function(error) {
            console.log(error);
          }
        );
      },
      addCertificate: function() {
        let module = this;

        if(this.certificateName.trim() == ""){
          this.showNotification("Error","Please fill in all fields","error");
          return false;
        }

        let data = {
          name: this.certificateName
        };

        this.xhrRequest("POST","/admin/certificates",data,
          function(response) {
            module.showNotification("Success","Certificate created successfully","success");
            module.newCertificateDialogVisible = false;
            module.getCertificates();
          },
          function(error) {
            let errorMsg = error.msg;
            module.showNotification("Error",errorMsg,"error");
          }
        );
      }
    }
  };
</script>

<style scoped lang="scss">
  .certificates-cont{
    padding: 32px 0;
    .title{
      margin: 0 0 24px 0;
    }
    &>button{
      top: 22px;
      right: 0;
      height: 42px;
      width: 200px;
    }
    .new-certificate-dialog{
      .form-cont{
        &>div{
          margin-bottom: 18px;
          p{
            margin: 0 0 8px 0;
          }
          input{
            height: 48px;
          }
          .el-switch{
            margin: -2px 8px 0 8px;
          }
        }
      }
      button{
        width: 115px;
        height: 32px;
        margin-left: 8px;
      }
    }
  }

  @media only screen and (max-width: 575px){
    .certificates-cont{

    }
  }
</style>