<template>
  <div class="dashboard-cont">
    <dashboard-header></dashboard-header>
    <div class="content horizontal layout relative" v-bind:style="{'overflow':($store.state.mobileNavExpanded)?'hidden':'auto'}">
      <div class="nav vertical layout transition-300 sticky">
        <div class="default-icon-cont relative">
          <img v-if="image" v-bind:src="image" alt="user avatar" class="border-radius-full d-block">
          <i v-else class="fas fa-user user-icon border-radius-full text-center bg-neutral-white"></i>
          <i class="fas fa-edit edit-icon ft-size-14 ft-neutral-white border-radius-full center absolute pointer" v-on:click="openImagePicker"></i>
          <input id="user-image-input" class="user-image-input" type="file" accept="image/jpeg, image/png" hidden v-on:change="imagePicked">
        </div>
        <p class="name-text ft-size-18 ft-neutral-white ft-w-bold text-center">{{name}}</p>
        <p class="title-text ft-size-14 ft-green text-center">{{title}}</p>
        <hr class="w-100"/>
        <div class="tabs">
          <div class="tab horizontal layout align-center pointer transition-300" v-bind:class="{'selected':$route.name == 'my-profile-user' || $route.name == 'my-profile-sponsor'}" v-on:click="myProfileAction" v-if="currentBoard == 'user-dashboard' || currentBoard == 'sponsor-dashboard'">
            <i class="fas fa-user ft-size-20 ft-neutral-white transition-300"></i>
            <p class="ft-size-16 ft-neutral-white transition-300">My Profile</p>
          </div>
          <div class="tab horizontal layout align-center pointer transition-300" v-bind:class="{'selected':$route.name == 'events'}" v-on:click="tabSelected('/user-dashboard/events')" v-if="currentBoard == 'user-dashboard'">
            <i class="fas fa-calendar-day ft-size-20 ft-neutral-white transition-300"></i>
            <p class="ft-size-16 ft-neutral-white transition-300">Events</p>
          </div>
          <div v-if="currentBoard == 'sponsor-dashboard'">
            <div class="tab horizontal layout align-center pointer transition-300" v-bind:class="{'selected':$route.name == 'leads'}" v-on:click="tabSelected('/sponsor-dashboard/leads')">
              <i class="fas fa-chart-line ft-size-20 ft-neutral-white transition-300"></i>
              <p class="ft-size-16 ft-neutral-white transition-300">Leads</p>
            </div>
            <div class="tab horizontal layout align-center pointer transition-300" v-bind:class="{'selected':$route.name == 'required-items'}" v-on:click="tabSelected('/sponsor-dashboard/required-items')">
              <i class="fas fa-file-alt ft-size-20 ft-neutral-white transition-300"></i>
              <p class="ft-size-16 ft-neutral-white transition-300">Required Items</p>
            </div>
            <div v-if="graphTabAllowed" class="tab horizontal layout align-center pointer transition-300" v-bind:class="{'selected':$route.name == 'graphs'}" v-on:click="tabSelected('/sponsor-dashboard/graphs')">
              <i class="far fa-chart-bar ft-size-20 ft-neutral-white transition-300"></i>
              <p class="ft-size-16 ft-neutral-white transition-300">Graphs</p>
            </div>
          </div>
          <div v-if="currentBoard == 'admin-dashboard'">
            <div class="tab horizontal layout align-center pointer transition-300" v-bind:class="{'selected':$route.name == 'user-groups' || $route.name == 'user-group' || $route.name == 'sponsor-page' || $route.name == 'delegate-page' || $route.name == 'delegates-form'}" v-on:click="tabSelected('/admin-dashboard/user-groups')">
              <i class="fas fa-users ft-size-20 ft-neutral-white transition-300"></i>
              <p class="ft-size-16 ft-neutral-white transition-300">User Groups</p>
            </div>
            <div class="tab horizontal layout align-center pointer transition-300" v-bind:class="{'selected':$route.name == 'packages'}" v-on:click="tabSelected('/admin-dashboard/packages')">
              <i class="fas fa-cubes ft-size-20 ft-neutral-white transition-300"></i>
              <p class="ft-size-16 ft-neutral-white transition-300">Packages</p>
            </div>
            <div class="tab horizontal layout align-center pointer transition-300" v-bind:class="{'selected':$route.name == 'certificates' || $route.name == 'certificate'}" v-on:click="tabSelected('/admin-dashboard/certificates')">
              <i class="fas fa-certificate ft-size-20 ft-neutral-white transition-300"></i>
              <p class="ft-size-16 ft-neutral-white transition-300">Certificates</p>
            </div>
            <div class="tab horizontal layout align-center pointer transition-300" v-bind:class="{'selected':$route.name == 'admin-graphs'}" v-on:click="tabSelected('/admin-dashboard/graphs')">
              <i class="far fa-chart-bar ft-size-20 ft-neutral-white transition-300"></i>
              <p class="ft-size-16 ft-neutral-white transition-300">Graphs</p>
            </div>
          </div>
        </div>
        <hr class="w-100"/>
        <span class="flex"></span>
        <div class="tab horizontal layout align-center pointer transition-300" v-bind:class="{'selected':$route.name == 'settings'}" v-on:click="tabSelected('/admin-dashboard/settings')" v-if="currentBoard == 'admin-dashboard'">
          <i class="fas fa-cog ft-size-20 ft-neutral-white transition-300"></i>
          <p class="ft-size-16 ft-neutral-white transition-300">Settings</p>
        </div>
        <div class="tab horizontal layout align-center pointer transition-300" v-on:click="logout(true)">
          <i class="fas fa-power-off ft-size-20 ft-neutral-white transition-300"></i>
          <p class="ft-size-16 ft-neutral-white transition-300">Logout</p>
        </div>
      </div>
      <div class="main-content flex relative">
        <router-view/>
        <div v-if="$store.state.mobileNavExpanded" class="black-overlay w-100 fixed" v-on:click="$store.commit('shrinkMobileNav')"></div>
      </div>
    </div>
    <dashboard-footer></dashboard-footer>
  </div>
</template>

<script>
  import Vue from "vue";
  import DashboardHeader from "../components/Dashboard/DashboardHeader.vue";
  import DashboardFooter from "../components/Dashboard/DashboardFooter.vue";

  Vue.component("dashboard-header",DashboardHeader);
  Vue.component("dashboard-footer",DashboardFooter);

  export default {
    name: "dashboard",
    components: {
      DashboardHeader,
      DashboardFooter
    },
    created() {
      this.$store.commit("init");
      this.getUserInfo();
    },
    mounted() {
      
    },
    watch: {
      mobileNavExpanded: function() {
        if(window.innerWidth <= 768){
          if(this.mobileNavExpanded){
            this.$el.querySelector(".nav").style.left = "0px";
          }else{
            this.$el.querySelector(".nav").style.left = "-250px";
          }
        }
      }
    },
    computed: {
      mobileNavExpanded: function() {
        return this.$store.state.mobileNavExpanded;
      },
      currentBoard: function() {
        return this.$route.path.split("/")[1];
      }
    },
    data() {
      return {
        name: "",
        title: "",
        image: null,
        graphTabAllowed: false
      }
    },
    methods: {
      myProfileAction: function() {
        switch(this.currentBoard){
          case "user-dashboard":
            this.tabSelected('/user-dashboard/my-profile');
            break;
          case "sponsor-dashboard":
            this.tabSelected('/sponsor-dashboard/my-profile');
            break;
        }
      },
      tabSelected: function(path) {
        this.$store.commit("shrinkMobileNav");
        this.$router.push(path).catch(err => {});
      },
      getUserInfo: function() {
        let module = this;
        
        let url = "";
			  switch(this.currentBoard){
          case "user-dashboard":
            url = "/user/info";
            break;
          case "sponsor-dashboard":
            url = "/sponsor/info";
            break;
          case "admin-dashboard":
            url = "/admin/info";
            break;
        }
        
        this.xhrRequest("GET",url,null,
          function(response) {
            module.name = response.name;
            module.title = response.title;
            module.image = response.image;
            if(module.currentBoard == "sponsor-dashboard")
              module.graphTabAllowed = response.graph;
          },
          function(error) {
            console.log(error);
          }
        );
      },
      openImagePicker: function() {
        this.$el.querySelector(".user-image-input").click();
      },
      imagePicked: function() {
        let module = this;
        let reader = new FileReader();
        let file = this.$el.querySelector("#user-image-input").files[0];
        if(file){
          reader.onload = function(e) {
            if(e.total > 1000000){
              module.showNotification("Error","File size is too big, max 1MB","error");
              return false;
            }

            module.image = e.target.result;
            module.updateUserImage(file);
          }
          reader.readAsDataURL(this.$el.querySelector("#user-image-input").files[0]);
        }
      },
      updateUserImage: function(file) {
        let module = this;
        let fileName = file.name;
        let data = new FormData();
        data.append("file",file);

        let url = "";
        switch(this.currentBoard){
          case "user-dashboard":
            url = "/user/upload_image";
            break;
          case "sponsor-dashboard":
            url = "/sponsor/upload_image";
            break;
          case "admin-dashboard":
            url = "/admin/upload_image";
            break;
        }
        this.fileUploader("POST",url,data,fileName,
          function(response) {
            module.showNotification("Success","User profile image updated successfully","success");
          },
          function(error) {
            console.log(error);
          }
        );
      }
    }
  };
</script>

<style scoped lang="scss">
  .dashboard-cont{
    .content{
      height: calc(100vh - 100px);
      .nav{
        width: 250px;
        padding-bottom: 10px;
        background: linear-gradient(180deg, #2D2F3D -0.03%, #2D2F3D 55.72%, #00AF5B 100.01%);
        z-index: 9;
        height: fit-content;
        min-height: 100%;
        top: 0;
        img{
          width: 175px;
          height: 175px;
          margin: 40px auto 0 auto;
          padding: 6px;
          object-fit: cover;
          object-position: center;
        }
        .default-icon-cont{
          .user-icon{
            padding-top: 32px;
            color: #2d2f3d;
            margin: 40px auto 0 auto;
            width: 175px;
            height: 175px;
            font-size: 100px;
            display: block;
          }
          .edit-icon{
            background-color: #2d2f3d;
            bottom: 10px;
            left: 0;
            right: 0;
            padding: 8px 0 0 8px;
            width: 30px;
            height: 30px;
          }
        }
        .name-text{
          margin: 22px 0 8px 0;
        }
        .title-text{
          margin: 0 0 32px 0;
        }
        hr{
          border: 0;
          border-top: 1px solid white;
          margin: 0;
        }
        .tabs{
          margin: 16px 0;
        }
        .tab{
          height: 50px;
          padding-left: 32px;
          border-left: 1px solid transparent;
          p{
            margin: 0 0 0 16px;
          }
          i{
            margin: 0;
          }
          &:hover,&.selected{
            background: #162928;
            box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
            border-left: 1px solid #0EEC6A;
            i,p{
              color: #0EEC6A;
            }
          }
        }
      }
      .main-content{
        padding: 40px;
        .black-overlay{
          top: 60px;
          left: 0;
          height: calc(100% - 100px);
          background-color: rgba(0,0,0,0.5);
          z-index: 5;
        }
      }
    }
  }

  @media only screen and (max-width: 768px){
    .dashboard-cont{
      .content{
        .nav{
          position: fixed;
          left: -250px;
          height: calc(100% - 100px);
          min-height: unset;
          top: 60px;
        }
        .main-content{
          padding: 20px;
        }
      }
    }
  }
</style>