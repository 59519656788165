<template>
  <div class="user-registration-cont">
    <img v-if="logo" v-bind:src="logo" alt="Form logo" class="header-logo">
    <div v-if="formData" class="page-body center">
      <div class="steps-cont horizontal layout center">
        <div v-for="step in formData" v-bind:key="step.index" class="step horizontal layout flex justify-center relative">
          <div v-if="step.index != 1" class="step-connector bg-light-grey absolute" v-bind:style="{'width':barWidth,'right':barRight}" v-bind:class="(step.index <= selectedStep)?'selected':''"></div>
          <div class="vertical layout d-flex align-center"> 
            <div class="step-circle border-radius-full bg-neutral-white d-flex align-center justify-center" v-bind:class="(step.index <= selectedStep)?'selected':''">
              <p class="ft-primary text-center m-0">{{step.index}}</p>
            </div>
            <p class="ft-size-16 ft-primary flex text-center">{{step.name}}</p>
          </div>
        </div>
      </div>
      <div class="forms-cont">
        <div v-for="step in formData" v-bind:key="step.index" v-show="step.index == selectedStep" class="form-cont">
          <div v-for="(fieldGroup,fieldGroupIndex) in step.fields_group" v-bind:key="fieldGroupIndex" class="field-group horizontal layout">
            <div v-for="(field,fieldIndex) in fieldGroup" v-bind:key="fieldIndex" class="field flex" v-bind:class="fieldMargin(fieldIndex,fieldGroup.length)">
              <field-component v-bind:details="field" v-bind:ref="'field_step_' + step.index"></field-component>
            </div>
          </div>
        </div>
      </div>
      <div class="actions-cont horizontal layout">
        <span class="flex"></span>
        <button v-if="selectedStep > 1" class="secondary ft-size-14 ft-primary ft-w-bold border-radius-4 relative" v-on:click="selectedStep--">
          Previous
          <i class="arrow-left fas fa-chevron-left ft-size-12 absolute"></i>
        </button>
        <button v-if="selectedStep < formData.length" class="primary ft-size-14 ft-neutral-white ft-w-bold border-radius-4 relative" v-on:click="goToNextStep">
          Next Step
          <i class="arrow-right fas fa-chevron-right ft-size-12 absolute"></i>
        </button>
        <button v-if="selectedStep == formData.length" class="primary ft-size-14 ft-neutral-white ft-w-bold border-radius-4 relative" v-on:click="submitForm">Complete</button>
      </div>
    </div>
    <div v-else>
      <p style="font-size: 24px;text-align: center;margin: 100px 0;">REGISTRATION HAS BEEN CLOSED</p>
    </div>
    <p class="copyrights-text ft-size-12 ft-grey text-center m-0">{{currentYear}} Copyrights © <span class="ft-green">MENA ISC</span></p>
  </div>
</template>

<script>
  import Vue from "vue";
  import FieldComponent from "../../components/Shared/FieldComponent.vue";

  Vue.component("field-component",FieldComponent);

  export default {
    name: "user-registration",
    components: {
      FieldComponent
    },
    created() {
      this.getTrafficSource();
      this.getFormData();
      this.getAssets();
      this.selectedStep = 1;
    },
    mounted() {
      if(this.$el.querySelector(".steps-cont"))
        this.stepsContWidth = this.$el.querySelector(".steps-cont").offsetWidth;
    },
    computed: {
      barWidth: function() {
        let stepsCount = this.formData.length;
        let stepWidth = this.stepsContWidth/stepsCount;
        let barWidth = (stepWidth - 40);
        return barWidth + "px";
      },
      barRight: function() {
        let stepsCount = this.formData.length;
        let stepWidth = this.stepsContWidth/stepsCount;
        let barRight = ((stepWidth - 40) / 2) + 40;
        return barRight + "px";
      },
      currentYear: function() {
        return new Date().getFullYear();
      }
    },
    watch: {
      selectedStep: function(to,from) {
        window.scrollTo(0,0);
      }
    },
    data() {
      return {
        formData: [],
        selectedStep: null,
        stepsContWidth: null,
        logo: null,
        source: null
      }
    },
    methods: {
      getTrafficSource: function() {
        if(this.$route.query && this.$route.query.source){
          this.source = this.$route.query.source;
          this.$router.push({path: this.$route.path});
        }
      },
      getFormData: function() {
        let module = this;
        let formId = this.$route.params.id;

        let url = "/user/form_templates/" + formId;
        this.xhrRequest("GET",url,null,
          function(response) {
            module.formData = response.form_template.template;
          },
          function(error) {
            console.log(error);
            module.formData = null;
          }
        );
      },
      getAssets: function() {
        let module = this;

        this.xhrRequest("GET","/banners",null,
          function(response) {
            module.logo = response.banners.registration_logo;
          },
          function(error) {
            console.log(error);
          }
        );
      },
      fieldMargin: function(fieldIndex,fieldGroupLength){
        if(fieldGroupLength == 1)
          return "";

        if(fieldIndex == 0){
          return "fieldRightMargin";
        }
        if((fieldIndex + 1) ==  fieldGroupLength){
          return "fieldLeftMargin";
        }
        if(fieldIndex != 0 && (fieldIndex + 1) !=  fieldGroupLength){
          return "fieldBothMargin";
        }
      },
      goToNextStep: function() {
        let valid = this.validateCurrentStep();

        if(valid)
          this.selectedStep++;
        else
          this.showNotification("Error","Please fill in all required fields, and make sure emails/phones are valid","error");
      },
      validateCurrentStep: function() {
        let valid = true;
        let currentStepFields = this.$refs[Object.keys(this.$refs)[this.selectedStep - 1]];
        currentStepFields.forEach(item => {
          if(!item.isValid())
            valid = false;
        });

        return valid;
      },
      submitForm: function() {
        let module = this;

        let valid = this.validateCurrentStep();

        if(!valid)
          this.showNotification("Error","Please fill in all required fields, and make sure emails/phones are valid","error");
        else{
          let url = this.formData[this.formData.length-1].step_action;
          let data = [];
          let allFieldsGroups = [];

          Object.entries(this.$refs).forEach(item => {
            allFieldsGroups.push(item[1]);
          });

          let allFields = allFieldsGroups.flat();
          allFields.forEach(item => {
            data.push(item.getInputValuePair());
          });

          let requestData = {
            data: data
          };
          if(this.source)
            requestData.source = this.source;

          this.xhrRequest("POST",url,requestData,
            function(response) {
              module.showNotification("Success","Account registered successfully","success");
              let path = "/user-verification";
              module.$router.push(path);
            },
            function(error) {
              let errorMsg = error.msg;
              module.showNotification("Error",errorMsg,"error");
            }
          );
        }
      }
    }
  };
</script>

<style scoped lang="scss">
  .user-registration-cont{
    padding: 32px 50px;
    min-height: 100vh;
    background-image: url("../../assets/images/User/user-registration-hero.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center bottom;
    background-attachment: fixed;
    .header-logo{
      height: 70px;
      margin-bottom: 32px;
    }
    .page-body{
      width: 950px;
      max-width: 100%;
      .steps-cont{
        width: 80%;
        margin-bottom: 32px;
        .step{
          .step-circle{
            width: 60px;
            height: 60px;
            z-index: 1;
            border: 2px solid #DDDFE1;
            &.selected{
              background: linear-gradient(180deg, rgba(255, 255, 255, 0.5) 0%, #00AF36 56.25%), #00AF36;
              border: none;
              p{
                color: #FFF;
              }
            }
          }
          .step-connector{
            height: 10px;
            top: 24px;
            &.selected{
              background: linear-gradient(180deg, rgba(255, 255, 255, 0.5) 0%, #00AF36 56.25%), #00AF36;
              opacity: 0.8;
            }
          }
        }
      }
      .forms-cont{
        .form-cont{
          .field-group{
            margin-bottom: 36px;
            .field{
              &.share-space{
                max-width: calc(100% - 16px);
              }
              &.fieldRightMargin{
                margin-right: 16px;
              }
              &.fieldLeftMargin{
                margin-left: 16px;
              }
              &.fieldBothMargin{
                margin-right: 16px;
                margin-left: 16px;
              }
            }
          }
        }
      }
      .actions-cont{
        margin-bottom: 100px;
        button{
          width: 130px;
          height: 40px;
          margin-left: 8px;
          .arrow-left{
            top: 14px;
            left: 14px;
          }
          .arrow-right{
            top: 14px;
            right: 14px;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 575px){
    .user-registration-cont{
      padding: 16px;
      .page-body{
        .steps-cont{
          width: 100%;
          .step{
            &>div{
              &>p{
                max-width: 90px;
              }
            }
          }
        }
        .forms-cont{
          .form-cont{
            .field-group{
              .field{
                &.fieldRightMargin{
                  margin-right: 8px;
                }
                &.fieldLeftMargin{
                  margin-left: 8px;
                }
                &.fieldBothMargin{
                  margin-right: 8px;
                  margin-left: 8px;
                }
              }
            }
          }
        }
      }
    }
  }
</style>