<template>
  <div class="user-verification-cont relative">
    <img src="../../assets/images/User/form-logo.png" alt="Form logo" class="header-logo">
    <div class="page-body center">
      <i class="fas fa-check-circle ft-size-50 ft-green text-center"></i>
      <p class="title ft-size-20 ft-primary ft-w-bold text-center">Thank you for your Registration. We will be notifying you via e-mail for further information.</p>
    </div>
    <p class="copyrights-text ft-size-12 ft-grey text-center m-0 absolute">{{currentYear}} Copyrights © <span class="ft-green">MENA ISC</span></p>
  </div>
</template>

<script>
  export default {
    name: "user-verification",
    mounted() {

    },
    computed: {
      currentYear: function() {
        return new Date().getFullYear();
      }
    },
    data() {
      return {
        code: ""
      }
    },
    methods: {

    }
  };
</script>

<style scoped lang="scss">
  .user-verification-cont{
    padding: 32px 50px;
    min-height: 100vh;
    background-image: url("../../assets/images/User/user-registration-hero.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center bottom;
    background-attachment: fixed;
    .header-logo{
      height: 70px;
      margin-bottom: 32px;
    }
    .page-body{
      width: 750px;
      max-width: 100%;
      &>i{
        display: block;
        margin: 18px 0;
      }
      .title{
        margin: 0 0 16px 0;
      }
    }
    .copyrights-text{
      left: 0;
      right: 0;
      bottom: 25px;
    }
  }

  @media only screen and (max-width: 575px){
    .user-verification-cont{
      padding: 16px;
    }
  }
</style>
