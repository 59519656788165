<template>
  <div class="user-groups-cont w-100 relative">
    <p class="title ft-size-20 ft-green ft-w-bold">User Groups</p>
    <p class="sub-title ft-size-18 ft-primary">Active User Groups</p>
    <div class="horizontal layout wrap">
      <usergroup-card v-for="(activeUserGroup,index) in activeUserGroups" v-bind:key="index" v-bind:userGroup="activeUserGroup"></usergroup-card>
    </div>
    <p v-if="activeUserGroups.length == 0" class="ft-size-14 ft-primary text-center">No active user groups at the moment</p>
    <hr/>
    <p class="sub-title ft-size-18 ft-primary">Inactive User Groups</p>
    <div class="horizontal layout wrap">
      <usergroup-card v-for="(inactiveUserGroup,index) in inactiveUserGroups" v-bind:key="index" v-bind:userGroup="inactiveUserGroup"></usergroup-card>
    </div>
    <p v-if="inactiveUserGroups.length == 0" class="ft-size-14 ft-primary text-center">No inactive user groups at the moment</p>
  </div>
</template>

<script>
  import Vue from "vue";
  import UsergroupCard from "../../components/Admin/UsergroupCard.vue";

  Vue.component("usergroup-card",UsergroupCard);

  export default {
    name: "user-groups",
    components: {
      UsergroupCard
    },
    mounted() {
      this.getUserGroups();
    },
    data() {
      return {
        activeUserGroups: [],
        inactiveUserGroups: []
      }
    },
    methods: {
      getUserGroups: function() {
        let module = this;

        this.xhrRequest("GET","/admin/user_groups",null,
          function(response) {
            module.activeUserGroups = response.active;
            module.inactiveUserGroups = response.in_active;
          },
          function(error) {
            console.log(error);
          }
        );
      }
    }
  };
</script>

<style scoped lang="scss">
  .user-groups-cont{
    padding: 32px 0;
    .title{
      margin: 0 0 24px 0;
    }
    .sub-title{
      margin: 24px 0;
    }
    hr{
      border: 0;
      border-top: 1px solid #e8e8e8;
    }
  }

  @media only screen and (max-width: 575px){
    .user-groups-cont{

    }
  }
</style>