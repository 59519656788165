<template>
  <div class="certificate-card-cont border-radius-4 relative shadow">
    <div class="gradient transition-300 border-radius-4 w-100 h-100 absolute"></div>
    <div class="card-content vertical layout align-center relative">
      <i class="fas fa-certificate text-center ft-neutral-white border-radius-full"></i>
      <p class="ft-size-18 ft-primary m-0 transition-300 text-center">{{certificate.name}}</p>
      <div class="actions-cont vertical layout w-100">
        <button class="ft-size-14 ft-primary bg-neutral-white transition-300 relative" v-on:click="viewCertificate">
          <i class="fas fa-eye absolute"></i>
          View
        </button>
        <button class="ft-size-14 ft-primary bg-neutral-white transition-300 relative" v-on:click="editCertificateDialogVisible = true">
          <i class="fas fa-pen absolute"></i>
          Edit
        </button>
        <button class="ft-size-14 ft-primary bg-neutral-white transition-300 relative" v-on:click="deleteCertificateDialogVisible = true">
          <i class="fas fa-trash-alt absolute"></i>
          Delete
        </button>
      </div>
    </div>
    <el-dialog class="delete-certificate-dialog" v-bind:title="deleteTitle" v-bind:visible.sync="deleteCertificateDialogVisible" width="400px">
      <p class="ft-size-16 ft-primary m-0">Are you sure you want to delete this certificate?</p>
      <span slot="footer" class="dialog-footer">
        <button class="secondary ft-size-16 ft-primary border-radius-4" v-on:click="deleteCertificateDialogVisible = false">Cancel</button>
        <button class="primary ft-size-16 ft-neutral-white border-radius-4" v-on:click="deleteCertificate">Delete</button>
      </span>
    </el-dialog>
    <el-dialog class="edit-certificate-dialog" title="Edit Certificate" v-bind:visible.sync="editCertificateDialogVisible" width="500px">
      <div class="form-cont">
        <div class="field flex">
          <p class="ft-size-18 ft-primary">Certificate name:</p>
          <input type="text" placeholder="Certificate name" spellcheck="false" class="ft-size-16 ft-primary w-100" v-model="localCertificateName">
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <button class="secondary ft-size-16 ft-primary border-radius-4" v-on:click="editCertificateDialogVisible = false">Cancel</button>
        <button class="primary ft-size-16 ft-neutral-white border-radius-4 relative" v-on:click="submit">Save</button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import Vue from "vue";
  import {Dialog} from "element-ui";

  Vue.component("el-dialog",Dialog);

  export default {
    name: "certificate-card",
    components: {
      Dialog
    },
    mounted() {
      
    },
    watch: {
      editCertificateDialogVisible: function() {
        if(this.editCertificateDialogVisible)
          this.localCertificateName = this.certificateName;
      }
    },
    computed: {
      deleteTitle: function() {
        return "Delete " + this.certificate.name + " Certificate";
      },
      certificateName: function() {
        return this.certificate.name;
      }
    },
    props: {
      certificate: Object
    },
    data() {
      return {
        deleteCertificateDialogVisible: false,
        editCertificateDialogVisible: false,
        localCertificateName: ""
      }
    },
    methods: {
      deleteCertificate: function() {
        let module = this;
    
        let url = "/admin/certificates/" + this.certificate.id;
        this.xhrRequest("DELETE",url,null,
          function(response) {
            module.showNotification("Success","Certificate removed successfully","success");
            module.deleteCertificateDialogVisible = false;
            module.$emit("refresh");
          },
          function(error) {
            let errorMsg = error.msg;
            module.showNotification("Error",errorMsg,"error");
          }
        );
      },
      submit: function() {
        let module = this;

        if(this.localCertificateName.trim() == ""){
          this.showNotification("Error","Please fill in all fields","error");
          return false;
        }

        let data = {
          name: this.localCertificateName
        };

        let url = "/admin/certificates/" + this.certificate.id;
        this.xhrRequest("PUT",url,data,
          function(response) {
            module.showNotification("Success","Certificate updated successfully","success");
            module.editCertificateDialogVisible = false;
            module.$emit("refresh");
          },
          function(error) {
            let errorMsg = error.msg;
            module.showNotification("Error",errorMsg,"error");
          }
        );
      },
      viewCertificate: function() {
        let url = "/admin-dashboard/certificates/" + this.certificate.id;
        this.$router.push({path:url});
      }
    }
  };
</script>

<style scoped lang="scss">
  .certificate-card-cont{
    border: 1px solid #DDDFE1;
    width: 200px;
    min-height: 210px;
    margin: 0 18px 18px 0;
    .gradient{
      background: linear-gradient(180deg, #2D2F3D -0.03%, #2D2F3D 55.72%, #00AF5B 100.01%);
      opacity: 0;
    }
    .infinity-icon{
      top: 8px;
      right: 10px;
      z-index: 1;
    }
    .card-content{
      &>i{
        font-size: 45px;
        background-color: #dcdcdc;
        padding: 18px;
        width: 80px;
        height: 80px;
        margin: 24px 0;
      }
      &>p{
        padding: 0 8px;
      }
      .actions-cont{
        margin: 10px 0;
        button{
          border: 1px solid #DDDFE1;
          height: 32px;
          margin: 4px 15px;
          i{
            left: 40px;
          }
        }
      }
    }
    &:hover{
      .gradient{
        opacity: 1;
      }
      .card-content{
        &>p{
          color: #FFF;
        }
        .actions-cont{
          button{
            background-color: transparent;
            border-color: #FFF;
            color: #FFF;
          }
        }
      }
    }
    .delete-certificate-dialog{
      button{
        width: 100px;
        height: 32px;
        margin-left: 8px;
      }
    }
    .edit-certificate-dialog{
      .form-cont{
        &>div{
          margin-bottom: 18px;
          p{
            margin: 0 0 8px 0;
          }
          input{
            height: 48px;
          }
          .el-switch{
            margin: -2px 8px 0 8px;
          }
        }
      }
      button{
        width: 100px;
        height: 32px;
        margin-left: 8px;
      }
    }
  }

  @media only screen and (max-width: 575px){
    .certificate-card-cont{
      margin: 0 auto 18px auto;
    }
  }
</style>