<template>
  <div class="field-component-cont horizontal layout align-end">
    <div class="flex">
      <p class="label ft-size-18 ft-primary">
        {{details.label}} <span class="ft-size-12 ft-grey" v-if="details.optional && !noOptionalLabel">(optional)</span>
        <a v-if="details.link" v-bind:href="details.link" target="_blank">
          <i class="fas fa-link ft-icon-color ft-size-14 pointer"></i>
        </a>
      </p>
      <div v-if="details.input_type == 'text' || details.input_type == 'number' || details.input_type == 'email'" class="type-text relative">
        <input v-bind:type="details.input_type" v-bind:placeholder="details.placeholder" spellcheck="false" class="ft-primary w-100" v-bind:style="{'padding-right':(details.postfix)?'42px':'16px'}" v-bind:disabled="details.read_only" v-model="textValue">
        <i v-bind:class="details.postfix" class="postfix-icon ft-icon-color absolute"></i>
      </div>
      <div v-else-if="details.input_type == 'dropdown'" class="type-dropdown relative">
        <select class="dropdown-select ft-primary w-100 pointer" v-bind:class="{'placeholder-color':selectedOption == '0'}" v-model="selectedOption">
          <option value="0" disabled>{{details.placeholder}}</option>
          <option v-for="(item,index) in details.data" v-bind:key="index" v-bind:value="item.key" class="ft-primary">{{item.value}}</option>
          <option v-if="details.other_values" value="other" class="ft-primary">Other</option>
        </select>
        <i class="fas fa-chevron-down postfix-icon ft-icon-color absolute" v-on:click="clickSelect"></i>
        <input v-if="otherMode" placeholder="Enter other value" spellcheck="false" class="ft-primary w-100" v-model="textValue">
      </div>
      <div v-else-if="details.input_type == 'checkbox'" class="type-checkbox">
        <div v-for="(item,index) in details.placeholder" v-bind:key="index">
          <input type="checkbox" class="relative pointer" v-model="checked[index]">
          <label class="ft-size-16 ft-grey relative">{{item}}</label>
        </div>
      </div>
      <div v-else-if="details.input_type == 'radio'" class="type-radio">
        <el-radio-group v-model="radioValue">
          <el-radio v-for="(item,index) in details.placeholder" v-bind:key="index" v-bind:label="item">{{item}}</el-radio>
        </el-radio-group>
      </div>
      <div v-else-if="details.input_type == 'file'" class="type-file">
        <input type="file" id="file-input" class="file-input" hidden v-on:change="filePicked">
        <div class="file-cont horizontal layout align-center w-100">
          <button class="ft-size-14 ft-primary" v-bind:disabled="isUploading" v-on:click="openFilePicker">
            <i class="fas fa-file"></i>
            {{fileName}}
          </button>
          <p class="ft-size-14 ft-primary">{{details.placeholder}}</p>
        </div>
      </div>
      <div v-else-if="details.input_type == 'phone'" class="type-phone horizontal layout relative">
        <div class="relative">
          <select class="phone-select ft-primary w-100 pointer" v-bind:class="{'placeholder-color':phoneSelectedOption == '0'}" v-model="phoneSelectedOption">
            <option value="0" disabled>Code</option>
            <option value="+966" class="ft-primary">
              🇸🇦
              +966
            </option>
            <option value="+971" class="ft-primary">
              🇦🇪
              +971
            </option>
            <option value="+962" class="ft-primary">
              🇯🇴
              +962
            </option>
            <option value="+20" class="ft-primary">
              🇪🇬
              +20
            </option>
            <option value="+965" class="ft-primary">
              🇰🇼
              +965
            </option>
            <option value="+968" class="ft-primary">
              🇴🇲
              +968
            </option>
            <option value="+974" class="ft-primary">
              🇶🇦
              +974
            </option>
            <option value="+973" class="ft-primary">
              🇧🇭
              +973
            </option>
          </select>
          <i class="fas fa-chevron-down postfix-icon ft-icon-color absolute" v-on:click="clickSelectPhone"></i>
        </div>
        <input v-bind:type="details.input_type" v-bind:placeholder="details.placeholder" spellcheck="false" class="ft-primary w-100" v-bind:style="{'padding-right':(details.postfix)?'42px':'16px'}" v-bind:disabled="details.read_only" v-model="textValue">
        <i v-bind:class="details.postfix" class="postfix-icon ft-icon-color absolute"></i>
      </div>
      <div v-else-if="details.input_type == 'country'" class="type-country relative">
        <select class="country-select ft-primary w-100 pointer" v-bind:class="{'placeholder-color':countrySelectedOption == '0'}" v-model="countrySelectedOption">
          <option value="0" disabled>{{details.placeholder}}</option>
          <option v-for="(item,index) in details.data" v-bind:key="index" v-bind:value="item.value" class="ft-primary">
            {{countryFlag(item.value)}}
            {{countryName(item.value)}}
          </option>
        </select>
        <i class="fas fa-chevron-down postfix-icon ft-icon-color absolute" v-on:click="clickSelectCountry"></i>
      </div>
      <p v-if="details.desc" class="desc ft-size-12 ft-primary">{{details.desc}}</p>
    </div>
    <div v-if="details.start_date" class="date-cont horizontal layout align-center">
      <div>
        <p class="ft-size-12 ft-w-bold ft-primary m-0 text-center">Start Date</p>
        <p class="ft-size-12 ft-primary m-0 text-center">{{timeConverter(details.start_date)}}</p>
      </div>
      <div>
        <p class="ft-size-12 ft-w-bold ft-primary m-0 text-center">End Date</p>
        <p class="ft-size-12 ft-primary m-0 text-center">{{timeConverter(details.end_date)}}</p>
      </div>
    </div>
    <div v-if="details.status" class="status-cont">
      <p class="ft-size-12 ft-w-bold ft-primary m-0 text-center">Status</p>
      <p v-if="!adminMode" class="ft-size-12 ft-primary m-0 text-center">{{details.status}}</p>
      <div v-else class="relative">
        <select class="ft-primary ft-size-12 pointer" v-on:change="updateFieldStatus" v-model="selectedStatus">
          <option v-for="(item,index) in statuses" v-bind:key="index" v-bind:value="item" class="ft-primary ft-size-14">{{item}}</option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
  import Vue from "vue";
  import {RadioGroup,Radio} from "element-ui";

  Vue.component("el-radio-group",RadioGroup);
  Vue.component("el-radio",Radio);

  export default {
    name: "field-component",
    components: {
      RadioGroup,
      Radio
    },
    mounted() {
      if(this.details.value){
        switch(this.details.input_type){
          case "text":
          case "number":
          case "email":
            this.textValue = this.details.value;
            break;
          case "dropdown":
            this.selectedOption = this.details.value;
            break;
          case "country":
            this.countrySelectedOption = this.countryCode(this.details.value);
            break;
          case "phone":
            this.textValue = this.details.value.phone;
            this.phoneSelectedOption = this.details.value.code;
            break;
          case "checkbox":
            let checkboxValues = this.details.value.split(",");
            this.checked = checkboxValues.map(function(item){
              return item = (item == "true");
            });
            break;
          case "radio":
            this.radioValue = this.details.value;
            break;
          case "file":
            this.file = this.details.value;
            this.fileName = this.details.file_name;
            break;
        }
      }else{
        switch(this.details.input_type){
          case "checkbox":
            this.checked = Array(this.details.placeholder.length).fill(false);
            break;
        }
      }

      if(this.adminMode)
        this.selectedStatus = this.details.status;
    },
    watch: {
      selectedOption: function() {
        (this.selectedOption == "other")? this.otherMode = true : this.otherMode = false;
      }
    },
    computed: {
      sortedCountries: function() {
        return this.countries.sort((a, b) => parseFloat(a.dial_code) - parseFloat(b.dial_code));
      }
    },
    props: {
      details: null,
      noOptionalLabel: false,
      adminMode: false,
      statuses: null,
      speakerId: null,
      entity: null
    },
    data() {
      return {
        textValue: "",
        selectedOption: "0",
        countrySelectedOption: "0",
        phoneSelectedOption: "0",
        checked: [],
        otherMode: false,
        fileName: "Choose File",
        file: null,
        selectedStatus: null,
        isUploading: false,
        radioValue: null
      }
    },
    methods: {
      clickSelect: function() {
        this.$el.querySelector(".dropdown-select").click();
      },
      clickSelectCountry: function() {
        this.$el.querySelector(".country-select").click();
      },
      clickSelectPhone: function() {
        this.$el.querySelector(".phone-select").click();
      },
      openFilePicker: function() {
        this.$el.querySelector(".file-input").click();
      },
      filePicked: function() {
        let module = this;
        let file = this.$el.querySelector("#file-input").files[0];

        if(file){
          this.fileName = file.name;
          let data = new FormData();
          data.append("file",file);
          this.isUploading = true;

          let url = "";
          let currentBoard = this.$route.path.split("/")[1];
          switch(currentBoard){
            case "user-dashboard":
              url = "/user/upload_file";
              break;
            case "sponsor-dashboard":
              url = "/sponsor/upload_file";
              break;
            case "admin-dashboard":
              url = "/admin/upload_file";
              break;
          }

          if(url){
            this.fileUploader("POST",url,data,this.fileName,
              function(response) {
                module.file = response.blob_id;
                module.isUploading = false;
              },
              function(error) {
                console.log(error);
                module.isUploading = false;
              }
            );
          }
        }
      },
      isValid: function() {
        let valid = false;

        if(this.details.input_type){
          switch(this.details.input_type){
            case "text":
              if(this.textValue.trim() != "" || this.details.optional)
                valid = true;
              break;
            case "email":
              let emailRegex = /\S+@\S+\.\S+/;
              if(this.details.optional && this.textValue.trim() == "") // optional and empty
                valid = true;
              else{ // not optional OR optional but has value
                if(emailRegex.test(this.textValue))
                  valid = true;
              }
              break;
            case "phone":
              let phoneRegex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
              if(this.details.optional && this.textValue.trim() == "" && this.phoneSelectedOption == "0") // optional and empty
                valid = true;
              else{ // not optional OR optional but has value
                let phoneInput = this.phoneSelectedOption + this.textValue;
                if(this.textValue.trim() != "" && this.phoneSelectedOption != "0" && phoneRegex.test(phoneInput))
                  valid = true;
              }
              break;
            case "number":
              if(this.textValue.trim() != "" || this.details.optional)
                valid = true;
              break;
            case "dropdown":
              if(this.otherMode){
                if(this.textValue.trim() != "" || this.details.optional)
                  valid = true;
              }else{
                if(this.selectedOption != 0 || this.details.optional)
                  valid = true;
              }
              break;
            case "country":
              if(this.countrySelectedOption != 0 || this.details.optional)
                valid = true;
              break;
            case "checkbox":
              if(this.details.optional){
                valid = true;
              }else{
                this.checked.forEach(function(item){
                  if(item)
                    valid = true;
                });
              }
              break;
            case "radio":
              if(this.radioValue || this.details.optional)
                valid = true;
              break;
            case "file":
              if(this.file || this.details.optional)
                valid = true;
              break;
          }
        }else{
          valid = true;
        }

        return valid;
      },
      getInputValuePair: function() {
        let value = null;
        let data = {};

        if(!this.details.read_only){
          switch(this.details.input_type){
            case "text":
            case "number":
            case "email":
              value = this.textValue;
              break;
            case "dropdown":
              if(this.otherMode)
                value = this.textValue;
              else
                if(this.selectedOption != 0)
                  value = this.details.data[this.selectedOption - 1].value;
              break;
            case "country":
              if(this.countrySelectedOption != 0)
                value = this.countryName(this.countrySelectedOption);
              break;
            case "phone":
              value = {
                phone: this.textValue,
                code: this.phoneSelectedOption
              };
              break;
            case "checkbox":
              let checkedArray = [];
              this.checked.forEach(item => {
                (item)? checkedArray.push(true) : checkedArray.push(false);
              });
              value = (checkedArray).join(",");
              break;
            case "radio":
              value = this.radioValue;
              break;
            case "file": 
              value = this.file;
              break;
          }
        }

        data[this.details.id] = value;
        return data;
      },
      updateFieldStatus: function() {
        let module = this;
        let status = this.selectedStatus; 

        let data = {
          file: (this.details.input_type == "file"),
          status: status
        };
        
        let userGroupId = this.$route.params.id; 
        let sponsorId = this.$route.params.sponsorId; 
        let url = "";
        if(this.speakerId){
          if(this.entity == "speaker")
            url = "/admin/user_groups/" + userGroupId +"/user_sponsor/" + this.speakerId + "/speaker_update_status/" + this.details.id;
          else
            url = "/admin/user_groups/" + userGroupId +"/user_sponsor/" + this.speakerId + "/tab_listing_update_status/" + this.details.id;
        }
        else
          url = "/admin/user_groups/" + userGroupId +"/user_sponsor/" + sponsorId + "/update_status/" + this.details.id;

        this.xhrRequest("POST",url,data,
          function(response) {
            module.showNotification("Success","Status updated successfully","success");
          },
          function(error) {
            let errorMsg = error.msg;
            module.showNotification("Error",errorMsg,"error");
          }
        );
      },
      countryCode: function(name) {
        let country = this.countries.find(country => country.name == name);
        if(country)
          return country.code;
        else
          return "INVALID COUNTRY";
      },
      countryName: function(code) {
        let country = this.countries.find(country => country.code == code);
        if(country)
          return country.name;
        else
          return "INVALID COUNTRY";
      },
      countryFlag: function(code) {
        let country = this.countries.find(country => country.code == code);
        if(country)
          return country.flag;
        else
          return "";
      }
    }
  };
</script>

<style scoped lang="scss">
  .field-component-cont{
    .label{
      margin: 0 0 8px 0;
      i{
        margin-left: 2px;
      }
    }
    .type-text{
      input{
        height: 48px;
      }
    }
    .type-phone{
      input{
        height: 48px;
        border-left: 0;
      }
      select{
        height: 48px;
        width: 125px;
        padding-right: 35px;
        &.placeholder-color{
          color: #c4c4c4;
        }
      }
    }
    .type-dropdown,.type-country{
      select{
        height: 48px;
        padding-right: 35px;
        &.placeholder-color{
          color: #c4c4c4;
        }
      }
      input{
        height: 48px;
        margin-top: 10px;
      }
    }
    .type-checkbox{
      margin: 16px 0;
      &>div{
        margin-bottom: 8px;
      }
      label{
        margin: 0 0 0 28px;
        top: 7px;
        line-height: 28px;
      }
      input[type=checkbox]:before {
        content: "";
        position: absolute;
        width: 24px;
        height: 24px;
        top: 0;
        left: 0;
        border: 2px solid #DDDFE1;
        background-color: white;
      }
      input[type=checkbox]:checked:after {
        content: "";
        width: 7px;
        height: 14px;
        border: solid #00902c;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
        position: absolute;
        top: 3px;
        left: 9px;
      }
    }
    .type-radio{
      margin-top: 16px;
    }
    .type-file{
      .file-cont{
        height: 48px;
        border: 1px solid #DDDFE1;
        padding: 14px;
        button{
          width: 110px;
          height: 32px;
          background: #efefef;
          box-shadow: none;
          overflow: hidden;
          white-space: pre;
          text-overflow: ellipsis;
        }
        p{
          margin-left: 16px;
        }
      }
    }
    .postfix-icon{
      right: 15px;
      top: 16px;
      pointer-events: none;
    }
    .desc{
      white-space: pre-line;
      line-height: 16px;
      margin-top: 10px;
    }
    .date-cont{
      &>div{
        margin: 10px 6px;
      }
    }
    .status-cont{
      margin: 10px 6px;
      select{
        padding: 4px 10px;
      }
    }
  }

  @media only screen and (max-width: 700px){
    .field-component-cont{
      .type-phone{
        flex-direction: column;
        select{
          width: 100%;
        }
        input{
          border-left: 1px solid #DDDFE1;
          border-top: 0;
        }
        &>.postfix-icon{
          top: 63px;
        }
      }
    }
  }
</style>
