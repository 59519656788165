import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    mobileNavExpanded: false,
    dashboardUserType: null,
    dashboardLoggedIn: false,
    uploadStack: []
  },
  mutations: {
    init(state) {
      if(localStorage.getItem("token")){
        state.dashboardLoggedIn = true;
        state.dashboardUserType = localStorage.getItem("user-type");
      }else{
        state.dashboardLoggedIn = false;
        state.dashboardUserType = null;
      }
    },
    dashboardLoggedOut(state) {
      localStorage.removeItem("token");
      localStorage.removeItem("user-type");
      state.dashboardLoggedIn = false;
      state.dashboardUserType = null;
    },
    expandMobileNav(state) {
      state.mobileNavExpanded = true;
    },
    shrinkMobileNav(state) {
      state.mobileNavExpanded = false;
    },
    addToUploadStack(state,item) {
      state.uploadStack.push(item);
    },
    uploadStackProgressing(state,data) {
      state.uploadStack.find(item => item.id == data.id).progress = data.progress;
    },
    removeUploadStack(state,itemId) {
      let item = state.uploadStack.find(item => item.id == itemId);
      let index = state.uploadStack.indexOf(item);
      state.uploadStack.splice(index,1);
    }
  },
  actions: {

  },
  modules: {

  }
});
