<template>
  <div class="dashboard-footer-cont d-flex justify-center align-center">
    <p class="copyrights-text ft-size-12 ft-grey text-center m-0">{{currentYear}} Copyrights © <span class="ft-green">{{copyrights}}</span></p>
  </div>
</template>

<script>
  export default {
    name: "dashboard-footer",
    mounted() {

    },
    computed: {
      currentYear: function() {
        return new Date().getFullYear();
      },
      copyrights: function() {
        let copyrights = "";

        let currentBoard = this.$route.path.split("/")[1];
			  switch(currentBoard){
          case "user-dashboard":
            copyrights = "MENA ISC";
            break;
          case "sponsor-dashboard":
          case "admin-dashboard":
            copyrights = "VirtuPort";
            break;
        }

        return copyrights;      
      }
    },
    data() {
      return {
 
      }
    },
    methods: {

    }
  };
</script>

<style scoped lang="scss">
  .dashboard-footer-cont{
    height: 40px;
    border-top: 1px solid #e8e8e8;
  }

  @media only screen and (max-width: 575px){
    .dashboard-footer-cont{

    }
  }
</style>