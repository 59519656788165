<template>
  <div class="package-card-cont border-radius-4 relative shadow">
    <i v-if="packageItem.package_type == 'unlimited'" class="infinity-icon fas fa-infinity ft-green absolute" title="Unlimited package"></i>
    <div class="gradient transition-300 border-radius-4 w-100 h-100 absolute"></div>
    <div class="card-content vertical layout align-center relative">
      <i class="fas fa-cube text-center ft-neutral-white border-radius-full"></i>
      <p class="ft-size-18 ft-primary m-0 transition-300 text-center">{{packageItem.name}}</p>
      <div class="actions-cont vertical layout align-center">
        <button class="ft-size-14 ft-primary bg-neutral-white transition-300 relative" v-on:click="editPackageDialogVisible = true">
          <i class="fas fa-pen absolute"></i>
          Edit
        </button>
        <button class="ft-size-14 ft-primary bg-neutral-white transition-300 relative" v-on:click="duplicatePackageDialogVisible = true">
          <i class="fas fa-clone absolute"></i>
          Duplicate
        </button>
        <button class="ft-size-14 ft-primary bg-neutral-white transition-300 relative" v-on:click="formPackageDialogVisible = true">
          <i class="fas fa-list absolute"></i>
          Form
        </button>
        <button class="ft-size-14 ft-primary bg-neutral-white transition-300 relative" v-on:click="deletePackageDialogVisible = true">
          <i class="fas fa-trash-alt absolute"></i>
          Delete
        </button>
      </div>
    </div>
    <el-dialog class="delete-package-dialog" v-bind:title="deleteTitle" v-bind:visible.sync="deletePackageDialogVisible" width="400px">
      <p class="ft-size-16 ft-primary m-0">Are you sure you want to delete this package?</p>
      <span slot="footer" class="dialog-footer">
        <button class="secondary ft-size-16 ft-primary border-radius-4" v-on:click="deletePackageDialogVisible = false">Cancel</button>
        <button class="primary ft-size-16 ft-neutral-white border-radius-4" v-on:click="deletePackage">Delete</button>
      </span>
    </el-dialog>
    <el-dialog class="edit-package-dialog" title="Edit Package" v-bind:visible.sync="editPackageDialogVisible" width="500px">
      <div class="form-cont">
        <div class="field flex">
          <p class="ft-size-18 ft-primary">Package name:</p>
          <input type="text" placeholder="Package name" spellcheck="false" class="ft-size-16 ft-primary w-100" v-model="localPackageName">
        </div>
        <div class="field flex">
          <span class="ft-size-18 ft-primary">Unlimited Package</span>
          <el-switch active-color="#13ce66" v-model="localLimited"></el-switch>
          <span class="ft-size-18 ft-primary">Limited Package</span>
        </div>
        <div v-if="localLimited" class="field flex">
          <p class="ft-size-18 ft-primary">Package limit:</p>
          <input type="number" placeholder="Package limit" spellcheck="false" class="ft-size-16 ft-primary w-100" v-model="localPackageLimit">
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <button class="secondary ft-size-16 ft-primary border-radius-4" v-on:click="editPackageDialogVisible = false">Cancel</button>
        <button class="primary ft-size-16 ft-neutral-white border-radius-4 relative" v-on:click="submit">Save</button>
      </span>
    </el-dialog>
    <el-dialog class="duplicate-package-dialog" title="Duplicate Package" v-bind:visible.sync="duplicatePackageDialogVisible" width="500px">
      <div class="form-cont">
        <div class="field flex">
          <p class="ft-size-18 ft-primary">New package name:</p>
          <input type="text" placeholder="new package name" spellcheck="false" class="ft-size-16 ft-primary w-100" v-model="duplicatePackageName">
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <button class="secondary ft-size-16 ft-primary border-radius-4" v-on:click="duplicatePackageDialogVisible = false">Cancel</button>
        <button class="primary ft-size-16 ft-neutral-white border-radius-4 relative" v-on:click="duplicate">Create</button>
      </span>
    </el-dialog>
    <el-dialog class="form-package-dialog" v-bind:title="packageItem.name + ' Form'" v-bind:visible.sync="formPackageDialogVisible" width="800px">
      <div class="form-cont">
        <form-builder v-bind:url="url" v-bind:packageForm="true" v-bind:label="'tab'"></form-builder>
      </div>
      <span slot="footer" class="dialog-footer">
        <button class="secondary ft-size-16 ft-primary border-radius-4" v-on:click="formPackageDialogVisible = false">Cancel</button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import Vue from "vue";
  import {Dialog} from "element-ui";
  import FormBuilder from "../Shared/FormBuilder.vue";
  import {Switch as ElSwitch} from "element-ui";

  Vue.component("el-dialog",Dialog);
  Vue.component("form-builder",FormBuilder);
  Vue.component("el-switch",ElSwitch);

  export default {
    name: "package-card",
    components: {
      Dialog,
      FormBuilder,
      ElSwitch
    },
    mounted() {
      
    },
    watch: {
      editPackageDialogVisible: function() {
        if(this.editPackageDialogVisible){
          this.localPackageName = this.packageName;
          this.localPackageLimit = this.packageLimit;
          this.localLimited = this.limited;
        }
      },
      duplicatePackageDialogVisible: function() {
        if(this.duplicatePackageDialogVisible)
          this.duplicatePackageName = "";
      }
    },
    computed: {
      deleteTitle: function() {
        return "Delete " + this.packageItem.name + " Package";
      },
      packageName: function() {
        return this.packageItem.name;
      },
      packageLimit: function() {
        return this.packageItem.package_limit;
      },
      limited: function() {
        if(this.packageItem.package_type == "limited")
          return true;
        else
          return false;
      },
      url: function() {
        return "/admin/packages/" + this.packageItem.id +"/form";
      }
    },
    props: {
      packageItem: Object
    },
    data() {
      return {
        deletePackageDialogVisible: false,
        editPackageDialogVisible: false,
        duplicatePackageDialogVisible: false,
        formPackageDialogVisible: false,
        localPackageName: "",
        localPackageLimit: "",
        localLimited: false,
        duplicatePackageName: ""
      }
    },
    methods: {
      deletePackage: function() {
        let module = this;
    
        let url = "/admin/packages/" + this.packageItem.id;
        this.xhrRequest("DELETE",url,null,
          function(response) {
            module.showNotification("Success","Package removed successfully","success");
            module.deletePackageDialogVisible = false;
            module.$emit("refresh");
          },
          function(error) {
            let errorMsg = error.msg;
            module.showNotification("Error",errorMsg,"error");
          }
        );
      },
      submit: function() {
        let module = this;

        if(this.localPackageName.trim() == "" || (this.localLimited && this.localPackageLimit == "")){
          this.showNotification("Error","Please fill in all fields","error");
          return false;
        }
        
        let package_type = "";
        if(this.localLimited)
          package_type = 0
        else
          package_type = 1;

        let data = {
          name: this.localPackageName,
          package_type: package_type
        };

        if(this.localLimited)
          data.package_limit = this.localPackageLimit;

        let url = "/admin/packages/" + this.packageItem.id;
        this.xhrRequest("PUT",url,data,
          function(response) {
            module.showNotification("Success","Package updated successfully","success");
            module.editPackageDialogVisible = false;
            module.$emit("refresh");
          },
          function(error) {
            let errorMsg = error.msg;
            module.showNotification("Error",errorMsg,"error");
          }
        );
      },
      duplicate: function() {
        let module = this;

        if(this.duplicatePackageName.trim() == ""){
          this.showNotification("Error","Please fill in new package name","error");
          return false;
        }

        let data = {
          name: this.duplicatePackageName,
          clone_from: this.packageItem.id
        };

        this.xhrRequest("POST","/admin/packages/clone",data,
          function(response) {
            module.showNotification("Success","Package added successfully","success");
            module.duplicatePackageDialogVisible = false;
            module.$emit("refresh");
          },
          function(error) {
            let errorMsg = error.msg;
            module.showNotification("Error",errorMsg,"error");
          }
        );
      }
    }
  };
</script>

<style scoped lang="scss">
  .package-card-cont{
    border: 1px solid #DDDFE1;
    width: 200px;
    min-height: 210px;
    margin: 0 18px 18px 0;
    .gradient{
      background: linear-gradient(180deg, #2D2F3D -0.03%, #2D2F3D 55.72%, #00AF5B 100.01%);
      opacity: 0;
    }
    .infinity-icon{
      top: 8px;
      right: 10px;
      z-index: 1;
    }
    .card-content{
      &>i{
        font-size: 45px;
        background-color: #dcdcdc;
        padding: 18px;
        width: 80px;
        height: 80px;
        margin: 24px 0;
      }
      &>p{
        padding: 0 8px;
      }
      .actions-cont{
        padding-bottom: 20px;
        button{
          border: 1px solid #DDDFE1;
          width: 140px;
          height: 32px;
          margin: 6px 0;
          i{
            left: 10px;
          }
        }
      }
    }
    &:hover{
      .gradient{
        opacity: 1;
      }
      .card-content{
        &>p{
          color: #FFF;
        }
        .actions-cont{
          button{
            background-color: transparent;
            border-color: #FFF;
            color: #FFF;
          }
        }
      }
    }
    .delete-package-dialog{
      button{
        width: 100px;
        height: 32px;
        margin-left: 8px;
      }
    }
    .edit-package-dialog,.duplicate-package-dialog,.form-package-dialog{
      .form-cont{
        &>div{
          margin-bottom: 18px;
          p{
            margin: 0 0 8px 0;
          }
          input{
            height: 48px;
          }
          .el-switch{
            margin: -2px 8px 0 8px;
          }
        }
      }
      button{
        width: 100px;
        height: 32px;
        margin-left: 8px;
      }
    }
  }

  @media only screen and (max-width: 575px){
    .package-card-cont{
      margin: 0 auto 18px auto;
    }
  }
</style>