<template>
  <div class="user-group-page-cont w-100 relative">
    <div v-if="userGroupType">
      <p class="title ft-size-20 ft-green ft-w-bold">{{userGroupName}} User group</p>
      <span class="flex"></span>
      <div v-if="userGroupType == 'sponsors' || userGroupType == 'delegates'" class="export-btns-cont horizontal layout absolute">
        <el-dropdown v-if="userGroupType == 'delegates' && tableSelections.length > 0" trigger="click" @command="delegatesBulkStatus">
          <button class="approve-selected-btn el-dropdown-link secondary ft-size-12 ft-primary border-radius-4 relative">
            Statuses update for selected<i class="el-icon-arrow-down el-icon--right"></i>
          </button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item v-for="(item,index) in statuses" v-bind:key="index" v-bind:command="item">{{item}}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <button class="export-selected-btn secondary ft-size-14 ft-primary border-radius-4 relative" v-if="tableSelections.length > 0" v-on:click="exportData">
          Export Selected
          <i class="fas fa-file-download absolute"></i>
        </button>
        <button class="export-all-btn secondary ft-size-14 ft-primary border-radius-4 relative" v-on:click="exportAllData">
          Export All
          <i class="fas fa-file-download absolute"></i>
        </button>
        <button v-if="userGroupType == 'delegates'" class="form-btn primary ft-size-16 ft-neutral-white border-radius-4 relative" v-on:click="goToDelegatesForm">
          Form
          <i class="fas fa-list absolute"></i>
        </button>
        <button class="add-sponsors primary ft-size-16 ft-neutral-white border-radius-4 relative" v-bind:disabled="isSubmitting" v-on:click="openFilePicker">
          <img v-if="isSubmitting" src="../../assets/images/spinner.gif" alt="spinner" style="position: absolute;width: 25px;left: 4px;top: 8px;">
          Import {{userGroupType}} (CSV)
        </button>
        <input id="users-input" class="users-input" type="file" accept=".csv" hidden v-on:change="filePicked">
      </div>
      <div v-if="userGroupType == 'delegates'">
        <div class="filter-cont horizontal layout relative align-center">
          <el-cascader v-bind:options="filterOptions" v-bind:props="cascaderProps" v-bind:show-all-levels="true" placeholder="Filter" separator=": " v-model="selectedFilter"></el-cascader>
          <input type="text" placeholder="Name" spellcheck="false" v-model="nameFilter" class="ft-size-14 ft-primary border-radius-4">
          <input type="text" placeholder="Email" spellcheck="false" v-model="emailFilter" class="ft-size-14 ft-primary border-radius-4">
          <button class="secondary ft-size-14 ft-primary border-radius-4 relative" v-bind:disabled="!selectedFilter && nameFilter == '' && emailFilter == ''" v-on:click="clearFilters">
            Clear
            <i class="fas fa-times absolute"></i>
          </button>
        </div>
        <div class="table-cont width-fit">
          <el-table ref="delegatesMultipleTable" class="shadow" v-bind:data="userGroupData" style="width:100%" v-bind:row-key="rowKey" @selection-change="handleSelectionChange">
            <el-table-column type="selection" reserve-selection width="45"></el-table-column>
            <el-table-column label="Actions">
              <template slot-scope="scope">
                <button class="view-btn primary ft-size-14 ft-neutral-white border-radius-4" @click="viewDelegate(scope.$index, scope.row)">View</button>
              </template>
            </el-table-column>
            <el-table-column v-for="(col,key) in userGroupData[0]" v-bind:key="key" v-bind:prop="key" v-bind:label="key" sortable width="150">
              <template slot-scope="scope">
                <select v-if="key == 'Status'" class="ft-primary ft-size-12 w-100 pointer" v-bind:value="scope.row[key]" v-on:change="updateUserStatus($event,scope.row.Id)">
                  <option v-for="(item,index) in statuses" v-bind:key="index" v-bind:value="item" class="ft-primary ft-size-14">{{item}}</option>
                </select>
                <p v-else class="m-0">{{scope.row[key]}}</p>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination layout="prev, pager, next" v-bind:total="total" @current-change="setPage" background></el-pagination>
        </div>
      </div>
      <div v-else-if="userGroupType == 'sponsors'">
        <div class="table-cont width-fit">
          <el-table class="shadow" v-bind:data="userGroupData" style="width:100%" v-bind:row-key="rowKey" @selection-change="handleSelectionChange">
            <el-table-column type="selection" reserve-selection width="45"></el-table-column>
            <el-table-column label="Actions">
              <template slot-scope="scope">
                <button class="view-btn primary ft-size-14 ft-neutral-white border-radius-4" @click="viewSponsor(scope.$index, scope.row)">View</button>
              </template>
            </el-table-column>
            <el-table-column v-for="(col,key) in userGroupData[0]" v-bind:key="key" v-bind:prop="key" v-bind:label="key" sortable width="150"></el-table-column>
          </el-table>
          <el-pagination layout="prev, pager, next" v-bind:total="total" @current-change="setPage" background></el-pagination>
        </div>
      </div>
      <div v-else-if="userGroupType == 'speakers'">
        <div class="horizontal layout wrap">
          <usergroup-card v-for="(data,index) in userGroupData" v-bind:key="index" v-bind:userGroup="data" v-bind:speaker="true" @openSpeaker="openSpeakerItems"></usergroup-card>
        </div>
        <el-dialog class="speaker-dialog" title="Speaker Items" v-bind:visible.sync="speakerDialogVisible" width="800px">
          <div class="form-cont">
            <div v-for="(field,fieldIndex) in speakerItems" v-bind:key="fieldIndex + '-' + getDateNow()" class="field flex">
              <field-component v-bind:details="field" v-bind:noOptionalLabel="true"></field-component>
            </div>
          </div>
        </el-dialog>
      </div>
    </div>
    <p v-else class="title ft-size-20 ft-green ft-w-bold">User group not found</p>
  </div>
</template>

<script>
  import Vue from "vue";
  import UsergroupCard from "../../components/Admin/UsergroupCard.vue";
  import {Dialog,Dropdown,DropdownMenu,DropdownItem,Pagination,Cascader} from "element-ui";
  import FieldComponent from "../../components/Shared/FieldComponent.vue";

  Vue.component("usergroup-card",UsergroupCard);
  Vue.component("el-dialog",Dialog);
  Vue.component("field-component",FieldComponent);
  Vue.component("el-dropdown",Dropdown);
  Vue.component("el-dropdown-menu",DropdownMenu);
  Vue.component("el-dropdown-item",DropdownItem);
  Vue.component("el-pagination",Pagination);
  Vue.component("el-cascader",Cascader);

  export default {
    name: "user-group-page",
    components: {
      UsergroupCard,
      Dialog,
      FieldComponent,
      Dropdown,
      DropdownMenu,
      DropdownItem,
      Pagination,
      Cascader
    },
    created() {
      this.userGroupId = this.$route.params.id;
      this.getUserGroup();
      this.getFilters();
    },
    mounted() {

    },
    watch: {
      nameFilter: function() {
        this.tableSelections = [];
        this.$refs.delegatesMultipleTable.clearSelection();
        (this.page == 1)? this.getUserGroup() : this.page = 1;
      },
      emailFilter: function() {
        this.tableSelections = [];
        this.$refs.delegatesMultipleTable.clearSelection();
        (this.page == 1)? this.getUserGroup() : this.page = 1;
      },
      selectedFilter: function() {
        this.tableSelections = [];
        this.$refs.delegatesMultipleTable.clearSelection();
        (this.page == 1)? this.getUserGroup() : this.page = 1;
      },
      page: function() {
        this.getUserGroup();
      }
    },
    data() {
      return {
        userGroupId: "",
        userGroupName: "",
        userGroupType: null,
        userGroupData: [],
        speakerDialogVisible: false,
        speakerItems: [],
        tableSelections: [],
        isSubmitting: false,
        selectedStatus: null,
        statusArray: [],
        total: 0,
        pageSize: 10,
        page: 1,
        selectedFilter: null,
        nameFilter: "",
        emailFilter: "",
        filterOptions: [],
        cascaderProps: {
          multiple: true
        }
      }
    },
    methods: {
      clearFilters: function() {
        this.selectedFilter = null;
        this.nameFilter = "";
        this.emailFilter = "";
      },
      setPage: function(page) {
        this.page = page;
      },
      getUserGroup: function() {
        let module = this;

        let url = "";
        if(this.selectedFilter || this.nameFilter.trim() != "" || this.emailFilter.trim() != ""){
          let filtersParams = "";
          let nameParam = "";          
          let emailParam = "";          
          let filterHash = {};

          if(this.selectedFilter){
            this.selectedFilter.forEach(item => {
              if(!filterHash[item[0]])
                filterHash[item[0]] = item[1];
              else
                filterHash[item[0]] = filterHash[item[0]] + "," + item[1];
            });
            Object.entries(filterHash).forEach(item => {
              filtersParams += ("&" + item[0] + "=" + item[1]);
            });
          }
          if(this.nameFilter.trim() != ""){
            nameParam = "&name=" + this.nameFilter;
          }
          if(this.emailFilter.trim() != ""){
            emailParam = "&email=" + this.emailFilter;
          }

          url = "/admin/user_groups/" + this.userGroupId + "?per_page=" + this.pageSize + "&page=" + this.page + filtersParams + nameParam + emailParam;
        }else{
          url = "/admin/user_groups/" + this.userGroupId + "?per_page=" + this.pageSize + "&page=" + this.page;
        }
        this.xhrRequest("GET",url,null,
          function(response) {
            module.userGroupName = response.name;
            module.userGroupType = response.user_group_type;
            module.statuses = response.status;
            module.userGroupData = response.data;

            if(response.user_group_type == "delegates" || response.user_group_type == "sponsors"){
              module.total = response.count;
            }
            else{
              module.userGroupData.forEach(function(item){
                item.user_group_type = "speakers";
              });
            }
          },
          function(error) {
            console.log(error);
          }
        );
      },
      openSpeakerItems: function(speakerId) {
        let module = this;
        
        let url = "/admin/user_groups/" + this.userGroupId + "/speaker/" + speakerId;
        this.xhrRequest("GET",url,null,
          function(response) {
            if(response.speaker){
              module.speakerItems = [];
              module.speakerItems = response.speaker;
              module.speakerDialogVisible = true;
            }else{
              module.showNotification("Error","Speaker has no filled in items","error");
            }
          },
          function(error) {
            console.log(error);
          }
        );
      },
      rowKey: function(row) {
        return row.Id;
      },
      handleSelectionChange: function(val) {
        this.tableSelections = val;
      },
      exportAllData: function() {
        let module = this;
        
        let url = "";
        switch(this.userGroupType){
          case "sponsors":
            url = "/admin/user_groups/" + this.userGroupId + "/user_sponsor/export_all";
            break;
          case "delegates":
            url = "/admin/user_groups/" + this.userGroupId + "/delegates/export_all";
            break;
        }
        this.xhrRequest("POST",url,null,
          function(response) {
            module.showNotification("Success",response.msg,"success");
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          },
          function(error) {
            let errorMsg = error.msg;
            module.showNotification("Error",errorMsg,"error");
          }
        );
      },
      exportData: function() {
        let module = this;

        let idsList = [];
        this.tableSelections.forEach(item => {
          idsList.push(item.Id);
        });

        let data = {
          user_sponsor_ids: idsList
        };

        let url = "";
        switch(this.userGroupType){
          case "sponsors":
            url = "/admin/user_groups/" + this.userGroupId + "/user_sponsor/export";
            break;
          case "delegates":
            url = "/admin/user_groups/" + this.userGroupId + "/delegates/export";
            break;
        }
        this.xhrRequest("POST",url,data,
          function(response) {
            module.showNotification("Success",response.msg,"success");
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          },
          function(error) {
            let errorMsg = error.msg;
            module.showNotification("Error",errorMsg,"error");
          }
        );
      },
      delegatesBulkStatus: function(status) {
        let module = this;

        let idsList = [];
        this.tableSelections.forEach(item => {
          idsList.push(item.Id);
        });

        let data = {
          delegates_ids: idsList,
          status: status
        };

        let url = "/admin/user_groups/" + this.userGroupId + "/delegates/bulk_status_update";
        this.xhrRequest("POST",url,data,
          function(response) {
            module.showNotification("Success",response.msg,"success");
          },
          function(error) {
            let errorMsg = error.msg;
            module.showNotification("Error",errorMsg,"error");
          }
        );
      },
      openFilePicker: function() {
        this.$el.querySelector(".users-input").click();
      },
      filePicked: function() {
        let module = this;
        let file = this.$el.querySelector("#users-input").files[0];

        if(file){
          let fileName = file.name;
          let data = new FormData();
          data.append("file",file);
  
          this.isSubmitting = true;
          let url = "";
          switch(this.userGroupType){
            case "sponsors":
              url = "/admin/user_groups/" + this.userGroupId + "/user_sponsor/create_user_sponsors_bulk";
              break;
            case "delegates":
              url = "/admin/user_groups/" + this.userGroupId + "/delegates/create_delegates_bulk";
              break;
          }
          this.fileUploader("POST",url,data,fileName,
            function(response) {
              module.showNotification("Success",module.userGroupType + " added successfully","success");
              module.isSubmitting = false;
            },
            function(error) {
              console.log(error);
              module.isSubmitting = false;
            }
          );
        }
      },
      viewSponsor: function(index,row) {
        let path = this.$route.fullPath + "/sponsor/" + row.Id;
        this.$router.push({path:path});
      },
      viewDelegate: function(index,row) {
        let path = this.$route.fullPath + "/delegate/" + row.Id;
        this.$router.push({path:path});
      },
      updateUserStatus: function(el,deligateId) {
        let module = this;

        let status = el.target.value;
        let data = {
          status: status
        };
        let url = "/admin/user_groups/" + this.userGroupId + "/delegates/" + deligateId + "/update_status";
        this.xhrRequest("POST",url,data,
          function(response) {
            module.showNotification("Success","Delegate status updated successfully","success");
          },
          function(error) {
            console.log(error);
          }
        );
      },
      goToDelegatesForm: function() {
        let path = this.$route.path + "/delegates-form";
        this.$router.push({path: path});
      },
      getDateNow: function() {
        let date = new Date();
        return date.getTime();
      },
      getFilters: function() {
        let module = this;

        this.xhrRequest("GET","/admin/filters",null,
          function(response) {
            let parsedResponse = [];
            response.filters.forEach(item => {
              let childrenArray = [];
              item.data.forEach(child => {
                let childItem = {
                  value: child.value,
                  label: child.value
                }

                childrenArray.push(childItem);
              });

              let filterItem = {
                value: item.label,
                label: item.label,
                children: childrenArray
              };

              parsedResponse.push(filterItem);
            });
          
            module.filterOptions = parsedResponse;
          },
          function(error) {
            console.log(error);
          }
        );
      }
    }
  };
</script>

<style scoped lang="scss">
  .user-group-page-cont{
    padding: 32px 0;
    max-width: calc(100vw - 350px);
    .title{
      margin: 0 0 60px 0;
    }
    .export-btns-cont{
      top: 28px;
      right: 0;
      button{
        height: 45px;
        width: 85px;
        margin-left: 8px;
        border: 1px solid #dcdcdc;
        padding: 0 0 0 10px;
        i{
          left: 15px;
          top: 15px;
        }
        &.add-sponsors{
          width: 220px;
        }
      }
      .export-all-btn{
        width: 115px;
      }
      .export-selected-btn{
        width: 152px;
      }
      .approve-selected-btn{
        width: 200px;
      }
      .form-btn{
        width: 100px;
        i{
          top: 13px;
        }
      }
    }
    .filter-cont{
      margin: -24px 0 32px 0;
      &>input{
        margin-left: 8px;
        width: 250px;
        height: 45px;
        border: 1px solid #00AF36;
      }
      button{
        height: 45px;
        width: 85px;
        margin-left: 8px;
        border: 1px solid #dcdcdc;
        padding: 0 0 0 10px;
        i{
          left: 15px;
          top: 15px;
        }
      }
    }
    .table-cont{
      margin-top: 20px;
      max-width: 100%;
      min-width: 300px;
      &>button{
        width: 200px;
        height: 40px;
        margin: 20px auto 0 auto;
      }
      .view-btn{
        padding: 6px 12px;
      }
      select{
        padding: 4px 10px;
        text-align-last: center;
      }
    }
    .speaker-dialog{
      .form-cont{
        &>div{
          margin-bottom: 18px;
        }
      }
    }
  }

  @media only screen and (max-width: 575px){
    .user-group-page-cont{
      max-width: calc(100vw - 40px);
      .title{
        margin: 0 0 8px 0;
      }
      .export-btns-cont{
        position: relative;
        margin-bottom: 42px;
        flex-direction: column;
        button{
          width: 100%!important;
          margin: 8px 0 0 0;
          padding: 0;
        }
      }
    }
  }
</style>