<template>
  <div class="certificate-page-cont w-100 relative">
    <div v-if="certificateFiles.length > 0">
      <p class="title ft-size-20 ft-green ft-w-bold">{{certificateName}} Certificate</p>
      <button class="add-users primary ft-size-16 ft-neutral-white border-radius-4 absolute" v-bind:disabled="isSubmitting" v-on:click="openUsersFilePicker">
        <img v-if="isSubmitting" src="../../assets/images/spinner.gif" alt="spinner" style="position: absolute;width: 25px;left: 4px;top: 8px;">
        Set Users (CSV)
      </button>
      <input id="users-input" class="users-input" type="file" accept=".csv" hidden v-on:change="usersFilePicked">
      <div class="file-cont border-radius-4 w-100 shadow">
        <p class="ft-size-18 ft-primary"><i class="far fa-file"></i> File 1</p>
        <div class="form-cont">
          <div class="field flex">
            <p class="ft-size-18 ft-primary">File:</p>
            <a v-if="certificateFiles[0].certificate_file_url" v-bind:href="certificateFiles[0].certificate_file_url" target="_blank" class="ft-size-16">{{certificateFiles[0].certificate_file_name}}</a>
            <p v-else>
              <span v-if="file1" class="ft-size-16 ft-primary">{{file1}}</span>
              <span v-else class="ft-size-16 ft-primary">Not set</span>
            </p>
            <button v-bind:disabled="isSubmittingFile1" v-if="!certificateFiles[0].certificate_file_url || certificateFiles[0].certificate_file_url == ''" class="primary ft-size-14 ft-neutral-white border-radius-4 d-block" v-on:click="openFilePicker(1)">
              <span v-if="file1">Change File</span>
              <span v-else>Set File</span>
            </button>
            <input id="file-input-1" class="file-input-1" type="file" accept=".pdf" hidden v-on:change="filePicked1">
          </div>
          <div class="field flex">
            <p class="ft-size-18 ft-primary">Credit:</p>
            <input type="number" placeholder="Credit" spellcheck="false" class="ft-size-16 ft-primary w-100" v-model="credit1">
          </div>
        </div>
        <div class="horizontal layout">
          <span class="flex"></span>
          <button v-if="certificateFiles[0].certificate_file_url != '' && certificateFiles[0].credit != 0" class="primary ft-size-14 ft-neutral-white border-radius-4 relative" v-on:click="clear(1)">Clear</button>
          <button v-else class="primary ft-size-14 ft-neutral-white border-radius-4 relative" v-bind:disabled="isSubmittingFile1" v-on:click="submit(1)">
            <img v-if="isSubmittingFile1" src="../../assets/images/spinner.gif" alt="spinner" style="position: absolute;width: 25px;left: 4px;top: 3px;">
            Submit
          </button>
        </div>
      </div>
      <div class="file-cont border-radius-4 w-100 shadow">
        <p class="ft-size-18 ft-primary"><i class="far fa-file"></i> File 2</p>
        <div class="form-cont">
          <div class="field flex">
            <p class="ft-size-18 ft-primary">File:</p>
            <a v-if="certificateFiles[1].certificate_file_url" v-bind:href="certificateFiles[1].certificate_file_url" target="_blank" class="ft-size-16">{{certificateFiles[1].certificate_file_name}}</a>
            <p v-else>
              <span v-if="file2" class="ft-size-16 ft-primary">{{file2}}</span>
              <span v-else class="ft-size-16 ft-primary">Not set</span>
            </p>
            <button v-bind:disabled="isSubmittingFile2" v-if="!certificateFiles[1].certificate_file_url || certificateFiles[1].certificate_file_url == ''" class="primary ft-size-14 ft-neutral-white border-radius-4 d-block" v-on:click="openFilePicker(2)">
              <span v-if="file2">Change File</span>
              <span v-else>Set File</span>
            </button>
            <input id="file-input-2" class="file-input-2" type="file" accept=".pdf" hidden v-on:change="filePicked2">
          </div>
          <div class="field flex">
            <p class="ft-size-18 ft-primary">Credit:</p>
            <input type="number" placeholder="Credit" spellcheck="false" class="ft-size-16 ft-primary w-100" v-model="credit2">
          </div>
        </div>
        <div class="horizontal layout">
          <span class="flex"></span>
          <button v-if="certificateFiles[1].certificate_file_url != '' && certificateFiles[1].credit != 0" class="primary ft-size-14 ft-neutral-white border-radius-4 relative" v-on:click="clear(2)">Clear</button>
          <button v-else class="primary ft-size-14 ft-neutral-white border-radius-4 relative" v-bind:disabled="isSubmittingFile2" v-on:click="submit(2)">
            <img v-if="isSubmittingFile2" src="../../assets/images/spinner.gif" alt="spinner" style="position: absolute;width: 25px;left: 4px;top: 3px;">
            Submit
          </button>
        </div>
      </div>
    </div>
    <p v-else class="title ft-size-20 ft-green ft-w-bold">Certificate not found</p>
  </div>
</template>

<script>
  export default {
    name: "certificate-page",
    created() {
      this.certificateId = this.$route.params.id;
      this.getCertificate();
    },
    mounted() {

    },
    data() {
      return {
        certificateId: "",
        certificateName: "",
        certificateFiles: [],
        credit1: "",
        credit2: "",
        file1: "",
        file2: "",
        fileSource1: "",
        fileSource2: "",
        isSubmitting: false,
        isSubmittingFile1: false,
        isSubmittingFile2: false
      }
    },
    methods: {
      getCertificate: function() {
        let module = this;

        let url = "/admin/certificates/" + this.certificateId;
        this.xhrRequest("GET",url,null,
          function(response) {
            module.certificateName = response.name;
            module.certificateFiles = response.certificate_files;
            module.credit1 = response.certificate_files[0].credit;
            module.credit2 = response.certificate_files[1].credit;
            module.file1 = response.certificate_files[0].certificate_file_url;
            module.file2 = response.certificate_files[1].certificate_file_url;
          },
          function(error) {
            console.log(error);
          }
        );
      },
      openUsersFilePicker: function() {
        this.$el.querySelector(".users-input").click();
      },
      openFilePicker: function(index) {
        switch(index){
          case 1:
            this.$el.querySelector(".file-input-1").click();
            break;
          case 2:
            this.$el.querySelector(".file-input-2").click();
            break;
        }
      },
      usersFilePicked: function() {
        let module = this;
        let file = this.$el.querySelector("#users-input").files[0];

        if(file){
          let fileName = file.name;
          let data = new FormData();
          data.append("file",file);
  
          this.isSubmitting = true;
          let url = "/admin/certificates/" + this.certificateId + "/add_users";
          this.fileUploader("POST",url,data,fileName,
            function(response) {
              module.showNotification("Success","Certificate users updated successfully","success");
              module.isSubmitting = false;
            },
            function(error) {
              console.log(error);
              module.isSubmitting = false;
            }
          );
        }
      },
      filePicked1: function() {
        let module = this;
        let file = this.$el.querySelector("#file-input-1").files[0];

        if(file){
          this.file1 = file.name;
          let fileName = file.name;
          let data = new FormData();
          data.append("file",file);
  
          this.isSubmittingFile1 = true;
          
          let url = "/admin/certificates/" + this.certificateId + "/upload_file";
          this.fileUploader("POST",url,data,fileName,
            function(response) {
              module.fileSource1 = response.blob_id;
              module.isSubmittingFile1 = false;
            },
            function(error) {
              console.log(error);
              module.isSubmittingFile1 = false;
            }
          );
        }
      },
      filePicked2: function() {
        let module = this;
        let file = this.$el.querySelector("#file-input-2").files[0];

        if(file){
          this.file2 = file.name;
          let fileName = file.name;
          let data = new FormData();
          data.append("file",file);
  
          this.isSubmittingFile2 = true;
          
          let url = "/admin/certificates/" + this.certificateId + "/upload_file";
          this.fileUploader("POST",url,data,fileName,
            function(response) {
              module.fileSource2 = response.blob_id;
              module.isSubmittingFile2 = false;
            },
            function(error) {
              console.log(error);
              module.isSubmittingFile2 = false;
            }
          );
        }
      },
      submit: function(index) {
        let module = this;

        let fileId = "";
        let credit = "";
        let fileSource = "";
        let fileUrl = "";
        switch(index) {
          case 1:
            fileId = this.certificateFiles[0].id;
            credit = this.credit1;
            fileUrl = this.file1;
            fileSource = this.fileSource1;
            break;
          case 2:
            fileId = this.certificateFiles[1].id;
            credit = this.credit2;
            fileUrl = this.file2;
            fileSource = this.fileSource2;
            break;
        }

        if(fileSource == "" && (fileUrl == "" || !fileUrl)){
          this.showNotification("Error","Please set file","error");
          return false;
        }

        if(credit == 0){
          this.showNotification("Error","Please set credit","error");
          return false;
        }

        let data = {
          credit: credit
        };

        if(fileSource == "")
          data.blob_id = fileUrl;
        else
          data.blob_id = fileSource;

        switch(index) {
          case 1:
            this.isSubmittingFile1 = true;
            break;
          case 2:
            this.isSubmittingFile2 = true;
            break;
        }
        let url = "/admin/certificates/" + this.certificateId + "/upload_file/" + fileId;
        this.xhrRequest("POST",url,data,
          function(response) {
            module.showNotification("Success","Certificate file updated successfully","success");
            switch(index) {
              case 1:
                module.isSubmittingFile1 = false;
                break;
              case 2:
                module.isSubmittingFile2 = false;
                break;
            }
            module.getCertificate();
          },
          function(error) {
            console.log(error);
            switch(index) {
              case 1:
                module.isSubmittingFile1 = false;
                break;
              case 2:
                module.isSubmittingFile2 = false;
                break;
            }
          }
        );
      },
      clear: function(index) {
        switch(index) {
          case 1:
            this.credit1 = "";
            this.file1 = "";
            this.fileSource1 = "";
            this.certificateFiles[0].certificate_file_url = "";
            this.certificateFiles[0].credit = 0;
            break;
          case 2:
            this.credit2 = "";
            this.file2 = "";
            this.fileSource2 = "";
            this.certificateFiles[1].certificate_file_url = "";
            this.certificateFiles[1].credit = 0;
            break;
        }
      }
    }
  };
</script>

<style scoped lang="scss">
  .certificate-page-cont{
    padding: 32px 0;
    .title{
      margin: 0 0 24px 0;
    }
    .file-cont{
      max-width: 500px;
      padding: 12px 18px;
      margin-bottom: 32px;
      border: 1px solid #e8e8e8;
      &>p{
        margin: 12px 0 24px 0;
        i{
          margin-right: 4px;
        }
      }
      .form-cont{
        &>div{
          margin-bottom: 18px;
          p{
            margin: 0 0 8px 0;
          }
          a{
            color: #0000ee;
          }
          input{
            height: 48px;
          }
          button{
            height: 32px;
            width: 110px;
            margin: 0 auto;
          }
        }
      }
      button{
        height: 32px;
        width: 110px;
      }
    }
    .add-users{
      top: 22px;
      right: 0;
      height: 42px;
      width: 175px;
    }
  }

  @media only screen and (max-width: 575px){
    .certificate-page-cont{
      .add-users{
        position: relative;
        top: 0;
        margin-bottom: 24px;
      }
    }
  }
</style>