<template>
  <div class="packages-cont w-100 relative">
    <p class="title ft-size-20 ft-green ft-w-bold">Packages</p>
    <div class="packages-cont horizontal layout wrap">
      <package-card v-for="(packageItem,index) in packages" v-bind:key="index" v-bind:packageItem="packageItem" @refresh="getPackages"></package-card>
    </div>
    <button class="primary ft-size-16 ft-neutral-white border-radius-4 absolute" v-on:click="newPackageDialogVisible = true">+ Create New Package</button>
    <el-dialog class="new-package-dialog" title="Create New Package" v-bind:visible.sync="newPackageDialogVisible" width="500px">
      <div class="form-cont">
        <div class="field flex">
          <p class="ft-size-18 ft-primary">Package name:</p>
          <input type="text" placeholder="Package name" spellcheck="false" class="ft-size-16 ft-primary w-100" v-model="packageName">
        </div>
        <div class="field flex">
          <span class="ft-size-18 ft-primary">Unlimited Package</span>
          <el-switch active-color="#13ce66" v-model="limited"></el-switch>
          <span class="ft-size-18 ft-primary">Limited Package</span>
        </div>
        <div v-if="limited" class="field flex">
          <p class="ft-size-18 ft-primary">Package limit:</p>
          <input type="number" placeholder="Package limit" spellcheck="false" class="ft-size-16 ft-primary w-100" v-model="packageLimit">
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <button class="secondary ft-size-16 ft-primary border-radius-4" v-on:click="newPackageDialogVisible = false">Cancel</button>
        <button class="primary ft-size-16 ft-neutral-white border-radius-4 relative" v-on:click="addPackage">Create</button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import Vue from "vue";
  import PackageCard from "../../components/Admin/PackageCard.vue";
  import {Switch as ElSwitch} from "element-ui";

  Vue.component("package-card",PackageCard);
  Vue.component("el-switch",ElSwitch);

  export default {
    name: "packages",
    components: {
      PackageCard,
      ElSwitch
    },
    mounted() {
      this.getPackages();
    },
    watch: {
      newPackageDialogVisible: function() {
        if(this.newPackageDialogVisible){
          this.packageName = "";
          this.packageLimit = "";
          this.limited = false;
        }
      }
    },
    data() {
      return {
        packages: [],
        newPackageDialogVisible: false,
        packageName: "",
        packageLimit: "",
        limited: false
      }
    },
    methods: {
      getPackages: function() {
        let module = this;

        this.xhrRequest("GET","/admin/packages",null,
          function(response) {
            module.packages = response.packages;
          },
          function(error) {
            console.log(error);
          }
        );
      },
      addPackage: function() {
        let module = this;

        if(this.packageName.trim() == "" || (this.limited && this.packageLimit == "")){
          this.showNotification("Error","Please fill in all fields","error");
          return false;
        }
        
        let package_type = "";
        if(this.limited)
          package_type = 0
        else
          package_type = 1;

        let data = {
          name: this.packageName,
          package_type: package_type
        };

        if(this.limited)
          data.package_limit = this.packageLimit;

        this.xhrRequest("POST","/admin/packages",data,
          function(response) {
            module.showNotification("Success","Package created successfully","success");
            module.newPackageDialogVisible = false;
            module.getPackages();
          },
          function(error) {
            let errorMsg = error.msg;
            module.showNotification("Error",errorMsg,"error");
          }
        );
      }
    }
  };
</script>

<style scoped lang="scss">
  .packages-cont{
    padding: 32px 0;
    .title{
      margin: 0 0 42px 0;
    }
    &>button{
      top: 22px;
      right: 0;
      height: 42px;
      width: 200px;
    }
    .new-package-dialog{
      .form-cont{
        &>div{
          margin-bottom: 18px;
          p{
            margin: 0 0 8px 0;
          }
          input{
            height: 48px;
          }
          .el-switch{
            margin: -2px 8px 0 8px;
          }
        }
      }
      button{
        width: 115px;
        height: 32px;
        margin-left: 8px;
      }
    }
  }

  @media only screen and (max-width: 575px){
    .packages-cont{

    }
  }
</style>