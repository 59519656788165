<template>
  <div class="login-cont horizontal layout">
    <div class="hero-img-cont flex" v-bind:style="{'background-image':'url('+ heroBgImage + ')'}"></div>
    <div class="form-cont d-flex align-center relative">
      <div class="inner-form-cont w-100 height-fit">
        <img v-if="this.$route.name != 'user-login'" src="../assets/images/logo.svg" alt="Form logo" class="w-100">
        <img v-else-if="userFormImage" v-bind:src="userFormImage" alt="Form logo" class="w-100">
        <div class="tuple">
          <p class="ft-primary ft-size-16">Email:</p>
          <input type="text" placeholder="Enter your email address" class="ft-primary w-100" v-model="email">
        </div>
        <div class="tuple">
          <p class="ft-primary ft-size-16">Password:</p>
          <input type="password" placeholder="Enter your email password" class="ft-primary w-100" v-model="password">
        </div>
        <p v-if="this.$route.name == 'user-login'" class="ft-size-14 ft-primary width-fit pointer" v-on:click="resetPasswordDialogVisible = true">Forgot your password?</p>
        <button class="primary ft-size-18 ft-neutral-white w-100 border-radius-32 d-block" v-on:click="login">Login</button>
      </div>
      <p class="copyrights-text ft-size-12 ft-grey absolute text-center m-0">{{currentYear}} Copyrights © <span class="ft-green">{{copyrights}}</span></p>
    </div>
    <el-dialog class="reset-password-dialog" title="Reset Account Password" v-bind:visible.sync="resetPasswordDialogVisible" width="400px">
      <div class="tuple">
        <p class="ft-primary ft-size-16">Email:</p>
        <input type="text" placeholder="Enter your email address" class="ft-primary w-100" v-model="resetPasswordEmail">
      </div>
      <span slot="footer" class="dialog-footer">
        <button class="secondary ft-size-16 ft-primary border-radius-4" v-on:click="resetPasswordDialogVisible = false">Cancel</button>
        <button class="primary ft-size-16 ft-neutral-white border-radius-4" v-on:click="changePassword">Confirm</button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  export default {
    name: "login",
    created() {
      // if(this.$route.query.confirmation_token)
      //   this.verifyUser(this.$route.query.confirmation_token);
      this.getAssets();
    },
    mounted() {

    },
    computed: {
      currentYear: function() {
        return new Date().getFullYear();
      },
      copyrights: function() {
        let copyrights = "";
        
        switch(this.$route.name){
          case "user-login":
            copyrights = "MENA ISC";
            break;
          case "sponsor-login":
          case "admin-login":
            copyrights = "VirtuPort";
            break;
        }

        return copyrights;
      }
    },
    data() {
      return {
        email: "",
        password: "",
        heroBgImage: "",
        userFormImage: "",
        resetPasswordDialogVisible: false,
        resetPasswordEmail: ""
      }
    },
    methods: {
      getAssets: function() {
        let module = this;

        this.xhrRequest("GET","/banners",null,
          function(response) {
            module.setBanner(response.banners);
          },
          function(error) {
            console.log(error);
          }
        );
      },
      setBanner: function(banners) {
        let module = this;
        switch(this.$route.name){
          case "user-login":
            module.heroBgImage = banners.user_banner;
            module.userFormImage = banners.registration_logo;
            break;
          case "sponsor-login":
            module.heroBgImage = banners.sponsor_banner;
            break;
          case "admin-login":
            module.heroBgImage = banners.admin_banner;
            break;
        }
      },
      login: function() {
        let module = this;

        if(this.email.trim() == "" || this.password.trim() == ""){
          this.showNotification("Error","Please fill in both email and password","error");
          return false;
        }

        let data = {
          email: this.email,
          password: this.password
        };

        let url = "";
        switch(this.$route.name){
          case "user-login":
            url = "/user/login";
            break;
          case "sponsor-login":
            url = "/sponsor/login";
            break;
          case "admin-login":
            url = "/admin/login";
            break;
        }
        this.xhrRequest("POST",url,data,
          function(response) {
            let entityType = "";
            if(response.user)
              entityType = response.user.entity_type;
            else if(response.admin)
              entityType = response.admin.entity_type;
  
            localStorage.setItem("token","Bearer " + response.token);
            localStorage.setItem("user-type",entityType);
            module.showNotification("Success","Logged in successfully","success");
            
            switch(entityType){
              case "User":
                module.$router.push("/user-dashboard/my-profile");
                break;
              case "Sponsor":
                module.$router.push("/sponsor-dashboard/my-profile");
                break;
              case "Admin":
                module.$router.push("/admin-dashboard/user-groups");
                break;
            }
          },
          function(error) {
            console.log(error);
            module.showNotification("Error","Invalid email or password","error");
          }
        );
      },
      verifyUser: function(token) {
        let module = this;
        
        let data = {
          confirmation_token: token
        };

        this.xhrRequest("POST","/user/email_verify",data,
          function(response) {
            module.$router.push(module.$route.path);
            module.showNotification("Success","Account verified successfully","success");
          },
          function(error) {
            console.log(error);
            module.showNotification("Error","There was an error verifying your account","error");
          }
        );
      },
      changePassword: function() {
        let module = this;
        
        if(this.resetPasswordEmail.trim() == ""){
          this.showNotification("Error","Please fill in email address","error");
          return false;
        }

        let data = {
          email: this.resetPasswordEmail
        };

        this.xhrRequest("POST","/user/send_reset_password_instructions",data,
          function(response) {
            module.showNotification("Success","An email has been sent to you for next steps","success");
            module.resetPasswordDialogVisible = false;
            module.resetPasswordEmail = "";
          },
          function(error) {
            console.log(error);
            module.showNotification("Error","There was an error resetting your account password","error");
            module.resetPasswordDialogVisible = false;
            module.resetPasswordEmail = "";
          }
        );
      }
    }
  };
</script>

<style scoped lang="scss">
  .login-cont{
    min-height: 100vh;
    .hero-img-cont{
      background-color: #c4c4c4;
      background-size: cover;
      background-repeat: no-repeat;
    }
    .form-cont{
      width: 425px;
      max-width: 100%;
      padding: 0 42px;
      .inner-form-cont{
        margin: 100px 0;
        img{
          margin-bottom: 36px;
        }
        &>div:nth-of-type(1){
          margin-bottom: 20px;
        }
        .tuple{
          p{
            margin: 0 0 8px 0;
          }
          input{
            height: 48px;
          }
        }
        button{
          max-width: 300px;
          height: 48px;
          margin: 68px auto 0 auto;
        }
      }
      .copyrights-text{
        bottom: 24px;
        right: 0;
        left: 0;
      }
    }
    .reset-password-dialog{
      .tuple{
        p{
          margin: 0 0 8px 0;
        }
        input{
          height: 48px;
        }
      }
      button{
        width: 100px;
        height: 32px;
        margin-left: 8px;
      }
    }
  }

  @media only screen and (max-width: 575px){
    .login-cont{
      .form-cont{
        padding: 0 24px;
        .inner-form-cont{
          margin: -100px 0 0 0;
        }
      }
    }
  }
</style>