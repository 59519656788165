<template>
  <div class="usergroup-card-cont border-radius-4 relative pointer shadow" v-on:click="goToGroupPage">
    <div class="gradient transition-300 border-radius-4 w-100 h-100 absolute"></div>
    <div class="card-content vertical layout align-center relative">
      <i class="text-center ft-neutral-white border-radius-full" v-bind:class="userGroupIcon"></i>
      <p class="ft-size-18 ft-primary m-0 transition-300 text-center">{{userGroup.name}}</p>
    </div>
  </div>
</template>

<script>
  export default {
    name: "usergroup-card",
    mounted() {
      
    },
    computed: {
      userGroupIcon: function() {
        let icon = "";
        switch(this.userGroup.user_group_type){
          case "delegates":
            icon = "fas fa-users";
            break;
          case "sponsors":
            icon = "fas fa-id-card";
            break;
          case "speakers":
            icon = "fas fa-user-tie";
            break;
        }

        return icon;
      }
    },
    props: {
      userGroup: Object,
      speaker: Boolean
    },
    data() {
      return {
        
      }
    },
    methods: {
      goToGroupPage: function() {
        if(this.speaker){
          this.$emit("openSpeaker",this.userGroup.id);
        }else{
          let url = "/admin-dashboard/user-groups/" + this.userGroup.id;
          this.$router.push({path:url});
        }
      }
    }
  };
</script>

<style scoped lang="scss">
  .usergroup-card-cont{
    border: 1px solid #DDDFE1;
    width: 200px;
    min-height: 175px;
    margin: 0 18px 18px 0;
    .gradient{
      background: linear-gradient(180deg, #2D2F3D -0.03%, #2D2F3D 55.72%, #00AF5B 100.01%);
      opacity: 0;
    }
    .card-content{
      &>i{
        font-size: 45px;
        background-color: #dcdcdc;
        padding: 18px 10px;
        width: 80px;
        height: 80px;
        margin: 24px 0;
      }
      &>p{
        padding: 0 8px;
      }
    }
    &:hover{
      .gradient{
        opacity: 1;
      }
      .card-content{
        &>p{
          color: #FFF;
        }
      }
    }
  }

  @media only screen and (max-width: 575px){
    .usergroup-card-cont{
      margin: 0 auto 18px auto;
    }
  }
</style>