<template>
  <div class="admin-graphs-cont">
    <p class="title ft-size-20 ft-primary-dark">Graphs</p>
    <div class="header horizontal layout align-center">
      <div class="filter-cont horizontal layout relative align-center">
        <el-cascader v-bind:options="filterOptions" v-bind:show-all-levels="true" placeholder="Filter" separator=": " v-model="selectedFilter"></el-cascader>
      </div>
      <span class="flex"></span>
    </div>
    <div class="graph-cont shadow border-radius-4">
      <p class="ft-size-18 ft-primary m-0">Pie Chart: {{selectedFilterText}}</p>
      <v-chart class="chart" v-bind:option="option" v-bind:loading="chartLoading"></v-chart>
    </div>
  </div>
</template>

<script>
  import {use} from "echarts/core";
  import {CanvasRenderer} from "echarts/renderers";
  import {PieChart} from "echarts/charts";
  import {TitleComponent,TooltipComponent,LegendComponent,ToolboxComponent} from "echarts/components";
  import VChart from "vue-echarts";

  use([CanvasRenderer,PieChart,TitleComponent,TooltipComponent,LegendComponent,ToolboxComponent]);

  export default {
    name: "admin-graphs",
    components: {
      VChart
    },
    created() {
      this.getFilters();
    },
    mounted() {
  
    },
    watch: {
      selectedFilter: function() {
        this.drawGraphData();
      }
    },
    computed: {
      selectedFilterText: function() {
        let text = "";
        if(this.selectedFilter)
          text = this.selectedFilter[0].charAt(0).toUpperCase() + this.selectedFilter[0].slice(1);
        
        return text;
      },
      showLegend: function() {
        if(this.isMobile())
          return false;

        return true;
      },
      getRadius: function() {
        if(this.isMobile())
          return ["20%","55%"];

        return ["40%","75%"];
      }
    },
    data() {
      return {
        filterOptions: [],
        selectedFilter: null,
        chartLoading: false,
        option: {}
      }
    },
    methods: {
      getFilters: function() {
        let module = this;
        this.chartLoading = true;

        this.xhrRequest("GET","/admin/filters",null,
          function(response) {
            let parsedResponse = [];
            response.filters.forEach(item => {
              let filterItem = {
                value: item.label,
                label: item.label,
                data: item.data
              };

              parsedResponse.push(filterItem);
            });
          
            module.filterOptions = parsedResponse;
            module.selectedFilter = new Array(module.filterOptions[0].value);
          },
          function(error) {
            console.log(error);
          }
        );
      },
      drawGraphData: function() {
        let dataArray = [];
        this.filterOptions.forEach(item => {
          if(item.value == this.selectedFilter[0]){
            item.data.forEach(data => {
              let dataItem = {
                name: data.value,
                value: data.count
              }

              dataArray.push(dataItem);
            });
          }
        });

        let data = dataArray;

        this.option = {
          title: {
            show: "false"
          },
          color: ["#ABAFB3","#464A53","#00AF36"],
          legend: {
            show: this.showLegend,
            type: "scroll",
            orient: "vertical",
            right: "right",
            top: "middle",
            data: data
          },
          toolbox: {
            show: true,
            feature: {
              saveAsImage: {
                show: true,
                title: "Export",
                name: "VirtuPort - Pie Chart " + this.selectedFilterText,
                emphasis: {
                  iconStyle: {
                    color: "#6A707E",
                    textPosition: "left"
                  }
                }
              }
            }
          },
          series: [
            {
              name: this.selectedFilterText,
              type: "pie",
              radius: this.getRadius,
              center: ["50%", "50%"],
              data: data,
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: "rgba(0, 0, 0, 0.5)"
                }
              },
              labelLine: {
                length: 30
              },
              label: {
                formatter: "  {b|{b}:} {c} {per|{d}%}  ",
                backgroundColor: "#F6F8FC",
                borderColor: "#8C8D8E",
                borderWidth: 1,
                borderRadius: 4,
                rich: {
                  a: {
                    color: "#6E7079",
                    lineHeight: 22,
                    align: "center"
                  },
                  hr: {
                    borderColor: "#8C8D8E",
                    width: "100%",
                    borderWidth: 1,
                    height: 0
                  },
                  b: {
                    color: "#4C5058",
                    fontSize: 14,
                    lineHeight: 33
                  },
                  per: {
                    color: "#fff",
                    backgroundColor: "#4C5058",
                    padding: 4,
                    borderRadius: 4
                  }
                }
              }
            }
          ]
        }

        this.chartLoading = false;
      }
    }
  };
</script>

<style scoped lang="scss">
  .admin-graphs-cont{
    padding-bottom: 50px;
    .title{
      margin: 0 0 20px 0;
    }
    .header{
      margin: 0 0 30px 0;
      .filter-cont{
        &>i{
          top: 13px;
          left: 10px;
          z-index: 1;
        }
      }
    }
    .graph-cont{
      padding: 40px;
      min-height: calc(100vh - 300px);
      .chart{
        height: 400px;
      }
    }
  }

  @media only screen and (max-width: 575px){
    .admin-graphs-cont{
      .header{
        flex-direction: column;
        align-items: unset;
        .filter-cont{
          .el-cascader{
            width: 100%;
          }
        }
      }
      .graph-cont{
        padding: 16px;
        min-height: calc(100vh - 312px);
      }
    }
  }
</style>