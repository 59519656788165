import Vue from "vue";
import VueRouter from "vue-router";

/**********  GLOBAL VIEWS **********/
import Login from "../views/Login.vue";
import Dashboard from "../views/Dashboard.vue";
import Fallback from "../views/Fallback.vue";
import AccountResetPassword from "../views/AccountResetPassword.vue";

/**********  USER VIEWS  ***********/
import UserRegistration from "../views/User/UserRegistration.vue";
import UserVerification from "../views/User/UserVerification.vue";
import MyProfile from "../views/User/MyProfile.vue";
import Events from "../views/User/Events.vue";

/********  SPONSOR VIEWS  **********/
import Leads from "../views/Sponsor/Leads.vue";
import RequiredItems from "../views/Sponsor/RequiredItems.vue";
import Graphs from "../views/Sponsor/Graphs.vue";

/*********  ADMIN VIEWS  ***********/
import UserGroups from "../views/Admin/UserGroups.vue";
import UserGroupPage from "../views/Admin/UserGroupPage.vue";
import SponsorPage from "../views/Admin/SponsorPage.vue";
import DelegatePage from "../views/Admin/DelegatePage.vue";
import DelegatesFormPage from "../views/Admin/DelegatesFormPage.vue";
import Packages from "../views/Admin/Packages.vue";
import Certificates from "../views/Admin/Certificates.vue";
import CertificatePage from "../views/Admin/CertificatePage.vue";
import AdminGraphs from "../views/Admin/AdminGraphs.vue";
import Settings from "../views/Admin/Settings.vue";


Vue.use(VueRouter);

function isAuthorized(){
  let authorized = false;
  if(localStorage.getItem("token"))
    authorized = true;

  return authorized;
}

function userType(){
  let userType = "";
  if(localStorage.getItem("token") && localStorage.getItem("user-type"))
    userType = localStorage.getItem("user-type");

  return userType;
}

const routes = [
  {
    path: "/user-login",
    name: "user-login",
    component: Login,
    meta: {
      pageTitle: "User Login"
    },
    beforeEnter: (to, from, next) => {
      if(isAuthorized() && userType() == "User")
        next({path:"/user-dashboard/my-profile"});

      next();
    }
  },
  {
    path: "/sponsor-login",
    name: "sponsor-login",
    component: Login,
    meta: {
      pageTitle: "Sponsor Login"
    },
    beforeEnter: (to, from, next) => {
      if(isAuthorized() && userType() == "Sponsor")
        next({path:"/sponsor-dashboard/my-profile"});

      next();
    }
  },
  {
    path: "/admin-login",
    name: "admin-login",
    component: Login,
    meta: {
      pageTitle: "Admin Login"
    },
    beforeEnter: (to, from, next) => {
      if(isAuthorized() && userType() == "Admin")
        next({path:"/admin-dashboard/user-groups"});

      next();
    }
  },
  {
    path: "/user-dashboard",
    component: Dashboard,
    beforeEnter: (to, from, next) => {
      if(!isAuthorized() || userType() != "User")
        next({path:"/user-login"});
      else
        if(to.path == "/user-dashboard")
          next({path:"/user-dashboard/my-profile"});

      next();
    },
    children: [
      {
        path: "",
        beforeEnter: (to, from, next) => {
          if(to.path == "/user-dashboard/")
            next({path:"/user-dashboard/my-profile"});
    
          next();
        }
      },
      {
        path: "my-profile",
        name: "my-profile-user",
        component: MyProfile,
        meta: {
          pageTitle: "My Profile"
        }
      },
      {
        path: "events",
        name: "events",
        component: Events,
        meta: {
          pageTitle: "Events"
        }
      }
    ]
  },
  {
    path: "/sponsor-dashboard",
    component: Dashboard,
    beforeEnter: (to, from, next) => {
      if(!isAuthorized() || userType() != "Sponsor")
        next({path:"/sponsor-login"});
      else
        if(to.path == "/sponsor-dashboard")
          next({path:"/sponsor-dashboard/my-profile"});

      next();
    },
    children: [
      {
        path: "",
        beforeEnter: (to, from, next) => {
          if(to.path == "/sponsor-dashboard/")
            next({path:"/sponsor-dashboard/my-profile"});
    
          next();
        }
      },
      {
        path: "my-profile",
        name: "my-profile-sponsor",
        component: MyProfile,
        meta: {
          pageTitle: "My Profile"
        }
      },
      {
        path: "leads",
        name: "leads",
        component: Leads,
        meta: {
          pageTitle: "Leads"
        }
      },
      {
        path: "required-items",
        name: "required-items",
        component: RequiredItems,
        meta: {
          pageTitle: "Required Items"
        }
      },
      {
        path: "graphs",
        name: "graphs",
        component: Graphs,
        meta: {
          pageTitle: "Graphs"
        }
      }
    ]
  },
  {
    path: "/admin-dashboard",
    component: Dashboard,
    beforeEnter: (to, from, next) => {
      if(!isAuthorized() || userType() != "Admin")
        next({path:"/admin-login"});
      else
        if(to.path == "/admin-dashboard")
          next({path:"/admin-dashboard/user-groups"});

      next();
    },
    children: [
      {
        path: "",
        beforeEnter: (to, from, next) => {
          if(to.path == "/admin-dashboard/")
            next({path:"/admin-dashboard/user-groups"});
    
          next();
        }
      },
      {
        path: "user-groups",
        name: "user-groups",
        component: UserGroups,
        meta: {
          pageTitle: "User Groups"
        }
      },
      {
        path: "user-groups/:id",
        name: "user-group",
        component: UserGroupPage,
        meta: {
          pageTitle: "User Group"
        }
      },
      {
        path: "user-groups/:id/delegates-form",
        name: "delegates-form",
        component: DelegatesFormPage,
        meta: {
          pageTitle: "Delegates Form"
        }
      },
      {
        path: "user-groups/:id/sponsor/:sponsorId",
        name: "sponsor-page",
        component: SponsorPage,
        meta: {
          pageTitle: "Sponsor Page"
        }
      },
      {
        path: "user-groups/:id/delegate/:delegateId",
        name: "delegate-page",
        component: DelegatePage,
        meta: {
          pageTitle: "Delegate Page"
        }
      },
      {
        path: "packages",
        name: "packages",
        component: Packages,
        meta: {
          pageTitle: "Packages"
        }
      },
      {
        path: "certificates",
        name: "certificates",
        component: Certificates,
        meta: {
          pageTitle: "Certificates"
        }
      },
      {
        path: "certificates/:id",
        name: "certificate",
        component: CertificatePage,
        meta: {
          pageTitle: "Certificate"
        }
      },
      {
        path: "graphs",
        name: "admin-graphs",
        component: AdminGraphs,
        meta: {
          pageTitle: "Graphs"
        }
      },
      {
        path: "settings",
        name: "settings",
        component: Settings,
        meta: {
          pageTitle: "Settings"
        }
      }
    ]
  },
  {
    path: "/user-registration/:id",
    name: "user-registration",
    component: UserRegistration,
    meta: {
      pageTitle: "User Registration"
    }
  },
  {
    path: "/user-verification",
    name: "user-verification",
    component: UserVerification,
    meta: {
      pageTitle: "User Verification"
    }
  },
  {
    path: "/account-reset-password/:token",
    name: "account-reset-password",
    component: AccountResetPassword,
    meta: {
      pageTitle: "Account Reset Password"
    }
  },
  {
    path: "/*",
    name: "404",
    component: Fallback,
    meta: {
      pageTitle: "Not Found"
    }
  }
]

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;