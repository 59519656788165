<template>
  <div class="step-group-cont w-100 border-radius-4 relative">
    <i class="fas fa-trash-alt ft-red absolute pointer" v-on:click="removeGroup"></i>
    <div class="fields-cont horizontal layout">
      <div v-for="(field,fieldIndex) in fields" v-bind:key="fieldIndex" class="field flex" v-bind:class="fieldMargin(fieldIndex,fields.length)">
        <field-component v-bind:details="field" ref="field"></field-component>
        <div class="field-actions-cont horizontal layout justify-center">
          <i class="ft-size-18 fas fa-edit ft-green pointer" v-on:click="openEditField(field,fieldIndex)"></i>
          <i class="ft-size-18 fas fa-trash-alt ft-red pointer" v-on:click="removeField(fieldIndex)"></i>
        </div>
      </div>
    </div>
    <button v-if="fields.length < 3" class="primary ft-size-16 ft-neutral-white border-radius-4 d-block center" v-on:click="openAddField">Add Field</button>
    <el-dialog class="add-field-dialog" v-bind:title="title" v-bind:visible.sync="addFieldDialogVisible" width="650px" append-to-body>
      <div class="field-settings">
        <p class="ft-size-18 ft-primary">Field Settings:</p>
        <div class="tuple horizontal layout align-center">
          <p class="ft-size-16 ft-primary">Label:<span class="ft-red">*</span></p>
          <input class="ft-primary flex" type="text" v-model="label">
        </div>
        <div class="tuple horizontal layout align-center">
          <p class="ft-size-16 ft-primary">Link:</p>
          <input class="ft-primary flex" type="text" v-model="link">
        </div>
        <div class="tuple horizontal layout align-center">
          <p class="ft-size-16 ft-primary">Optional:</p>
          <div>
            <span class="ft-size-16 ft-primary">No</span>
            <el-switch active-color="#13ce66" v-model="optional"></el-switch>
            <span class="ft-size-16 ft-primary">Yes</span>
          </div>
        </div>
        <div class="tuple horizontal layout align-center relative">
          <p class="ft-size-16 ft-primary">Input type:</p>
          <select class="ft-primary flex pointer" v-model="selectedInputType">
            <option value="text" class="ft-primary">Text</option>
            <option value="number" class="ft-primary">Number</option>
            <option value="dropdown" class="ft-primary">Dropdown</option>
            <option value="checkbox" class="ft-primary">Checkbox</option>
            <option value="radio" class="ft-primary">Radio</option>
            <option value="file" class="ft-primary">File</option>
            <option value="country" class="ft-primary">Country</option>
            <option value="phone" class="ft-primary">Phone</option>
            <option value="email" class="ft-primary">Email</option>
          </select>
          <i class="fas fa-chevron-down postfix-icon ft-icon-color absolute" v-on:click="clickSelect"></i>
        </div>
        <div v-if="selectedInputType == 'dropdown'" class="tuple horizontal layout align-center">
          <p class="ft-size-16 ft-primary">Dropdown Data:<span class="ft-red">*</span></p>
          <input class="ft-primary flex" type="text" placeholder="CSV" v-model="dropdownData">
        </div>
        <div v-if="selectedInputType == 'dropdown'" class="tuple horizontal layout align-center">
          <p class="ft-size-16 ft-primary">Allow other values:</p>
          <div>
            <span class="ft-size-16 ft-primary">No</span>
            <el-switch active-color="#13ce66" v-model="otherValues"></el-switch>
            <span class="ft-size-16 ft-primary">Yes</span>
          </div>
        </div>
        <div v-if="selectedInputType == 'country'" class="tuple horizontal layout align-center">
          <p class="ft-size-16 ft-primary">Countries:
            <span class="ft-red">*</span>
            <el-popover class="countries-popover" placement="right" title="Countries codes list" width="700" trigger="click">
              <i slot="reference" class="country-trigger fas fa-globe pointer"></i>
              <div class="countries-cont horizontal layout wrap space-evenly">
                <div v-for="(country,index) in countries" v-bind:key="index" class="country-item">
                  <p class="ft-size-12 ft-primary text-center m-0">{{country.name}} - <span class="ft-w-bold">{{country.code}}</span></p>
                </div>
              </div>
            </el-popover>
          </p>
          <input class="ft-primary flex" type="text" placeholder="CSV for countries codes" v-model="countriesData">
        </div>
        <div class="tuple horizontal layout align-center">
          <p class="ft-size-16 ft-primary">Placeholder:<span class="ft-red">*</span></p>
          <input class="ft-primary flex" type="text" v-bind:placeholder="placeholderMsg" v-model="placeholder">
        </div>
        <div v-if="selectedInputType == 'text' || selectedInputType == 'number' || selectedInputType == 'email' || selectedInputType == 'phone'" class="tuple horizontal layout align-center">
          <p class="ft-size-16 ft-primary">Postfix icon:</p>
          <input class="ft-primary flex" type="text" placeholder="Class name from fontawesome.com free collection (e.g. fas fa-font)" v-model="postfix">
        </div>
        <div class="tuple horizontal layout align-center">
          <p class="ft-size-16 ft-primary">Description:</p>
          <input class="ft-primary flex" type="text" v-model="desc">
        </div>
        <div v-if="selectedInputType == 'email' || selectedInputType == 'phone'" class="tuple horizontal layout align-center">
          <p class="ft-size-16 ft-primary">Field validation:</p>
          <div>
            <span class="ft-size-16 ft-primary">No</span>
            <el-switch active-color="#13ce66" v-model="withValidation" disabled></el-switch>
            <span class="ft-size-16 ft-primary">Yes</span>
          </div>
        </div>
        <div class="tuple horizontal layout align-center">
          <p class="ft-size-16 ft-primary">Read only:</p>
          <div>
            <span class="ft-size-16 ft-primary">No</span>
            <el-switch active-color="#13ce66" v-model="readOnly"></el-switch>
            <span class="ft-size-16 ft-primary">Yes</span>
          </div>
        </div>
      </div>
      <hr/>
      <div class="preview-cont">
        <p class="ft-size-18 ft-primary">Preview:</p>
        <div class="preview-inner-cont border-radius-4">
          <field-component v-bind:details="fieldSettings"></field-component>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <button class="secondary ft-size-16 ft-primary border-radius-4" v-on:click="addFieldDialogVisible = false">Cancel</button>
        <button v-if="mode == 'add'" class="primary ft-size-16 ft-neutral-white border-radius-4 relative" v-on:click="addField">Add</button>
        <button v-else class="primary ft-size-16 ft-neutral-white border-radius-4 relative" v-on:click="editField">Edit</button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import Vue from "vue";
  import {Dialog,Switch as ElSwitch,Popover} from "element-ui";
  import FieldComponent from "../FieldComponent.vue";

  Vue.component("el-dialog",Dialog);
  Vue.component("el-switch",ElSwitch);
  Vue.component("field-component",FieldComponent);
  Vue.component("el-popover",Popover);

  export default {
    name: "step-group",
    components: {
      Dialog,
      ElSwitch,
      FieldComponent,
      Popover
    },
    mounted() {
      if(this.group){
        this.fields = this.group.fields;
      }
    },
    watch:{
      placeholder: function() {
        let placeholder = this.placeholder;

        if((this.selectedInputType == 'checkbox' || this.selectedInputType == 'radio') && typeof placeholder == 'string')
          placeholder = placeholder.split(",");
        
        this.placeholder = placeholder;
      },
      selectedInputType: function() {
        (this.selectedInputType == 'checkbox' || this.selectedInputType == 'radio')? this.placeholderMsg = "CSV" : this.placeholderMsg = "";
        (this.selectedInputType == 'email' || this.selectedInputType == 'phone')? this.withValidation = true : this.withValidation = false;

        switch(this.selectedInputType){
          case "email":
            this.postfix = "fas fa-envelope";
            break;
          case "phone":
            this.postfix = "fas fa-phone";
            break;
          default:
            this.postfix = "";
            break;
        }
      }
    },
    computed: {
      fieldSettings: function() {
        let settings = {
          label: this.label,
          link: this.link,
          optional: this.optional,
          read_only: this.readOnly,
          desc: this.desc,
          input_type: this.selectedInputType,
          placeholder: this.placeholder,
          postfix: this.postfix
        };

        if(this.selectedInputType == "dropdown" && this.dropdownData != ""){
          let data = [];
          let dropdownDataArray = this.dropdownData.split(",");
          dropdownDataArray.forEach(element => {
            let item = {
              value: element
            };

            data.push(item);
          });

          settings.data = data;
          settings.other_values = this.otherValues;
        }

        if(this.selectedInputType == "country" && this.countriesData != ""){
          let data = [];
          let countriesDataArray = this.countriesData.split(",");
          countriesDataArray.forEach(element => {
            let item = {
              value: element
            };

            data.push(item);
          });

          settings.data = data;
        }

        return settings;
      },
      title: function() {
        let title = "Add Field";
        if(this.mode == "edit")
          title = "Edit Field";

        return title;
      }
    },
    props: {
      group: null
    },
    data() {
      return {
        fields: [],
        addFieldDialogVisible: false,
        label: "",
        link: null,
        optional: false,
        readOnly: false,
        withValidation: false,
        desc: "",
        selectedInputType: "text",
        placeholder: null,
        postfix: "",
        placeholderMsg: "",
        dropdownData: "",
        countriesData: "",
        mode: "add",
        editIndex: null,
        otherValues: true
      }
    },
    methods: {
      removeGroup: function() {
        this.$emit("removeGroup");
      },
      clickSelect: function() {
        this.$el.querySelector("select").click();
      },
      addField: function() {
        if(this.label.trim() == "" || !this.placeholder || (this.selectedInputType == "dropdown" && this.dropdownData.trim() == "") || (this.selectedInputType == "country" && this.countriesData.trim() == "")){
          this.showNotification("Error","Please fill in required fields","error");
          return false;
        }

        let fieldSettings = this.fieldSettings;
        this.fields.push(fieldSettings);
        this.addFieldDialogVisible = false;
      },
      clearAddFieldDialog: function() {
        this.label =  "";
        this.link =  null;
        this.optional =  false;
        this.readOnly = false;
        this.withValidation = false;
        this.desc =  "";
        this.selectedInputType =  "text";
        this.placeholder =  null;
        this.postfix =  "";
        this.placeholderMsg =  "";
        this.dropdownData =  "";
        this.countriesData = "";
        this.otherValues = true;
      },
      openAddField: function() {
        this.mode = "add";
        this.clearAddFieldDialog();
        this.addFieldDialogVisible = true;
      },
      fieldMargin: function(fieldIndex,fieldGroupLength){
        if(fieldGroupLength == 1)
          return "";

        if(fieldIndex == 0){
          return "fieldRightMargin";
        }
        if((fieldIndex + 1) ==  fieldGroupLength){
          return "fieldLeftMargin";
        }
        if(fieldIndex != 0 && (fieldIndex + 1) !=  fieldGroupLength){
          return "fieldBothMargin";
        }
      },
      removeField: function(fieldIndex) {
        this.fields.splice(fieldIndex,1);
      },
      openEditField: function(settings,fieldIndex) {
        this.mode = "edit";
        this.clearAddFieldDialog();
        this.editIndex = fieldIndex;
        this.label = settings.label;
        this.link = settings.link;
        this.optional = settings.optional;
        this.readOnly = settings.read_only;
        this.desc = settings.desc;
        this.selectedInputType = settings.input_type;
        switch(settings.input_type){
          case "dropdown":
            this.setDropDownCSV(settings.data);
            this.otherValues = settings.other_values;
            break;
          case "country":
            this.setCountriesCSV(settings.data);
            break;
          case "email":
          case "phone":
            this.withValidation = true;
            break;
        }
        this.placeholder = settings.placeholder;
        this.postfix = settings.postfix;
        this.addFieldDialogVisible = true;
      },
      editField: function() {
        this.fields[this.editIndex] = this.fieldSettings;
        this.addFieldDialogVisible = false;
      },
      setDropDownCSV: function(data) {
        let CSVString = "";
        data.forEach((item,index) => {
          let separator = ",";
          if(index == (data.length - 1))
            separator = "";
          CSVString += (item.value + separator);
        });

        this.dropdownData = CSVString;
      },
      setCountriesCSV: function(data) {
        let CSVString = "";
        data.forEach((item,index) => {
          let separator = ",";
          if(index == (data.length - 1))
            separator = "";
          CSVString += (item.value + separator);
        });

        this.countriesData = CSVString;
      }
    }
  };
</script>

<style scoped lang="scss">
  .step-group-cont{
    padding: 32px 12px 24px 12px;
    margin-bottom: 24px;
    border: 1px solid #DDDFE1;
    background-color: #f5f5f5;
    &>i{
      top: 8px;
      right: 14px;
    }
    &>button{
      width: 100px;
      height: 35px;
      margin-top: 32px;
    }
    .fields-cont{
      .field{
        pointer-events: none;
        &.share-space{
          max-width: calc(100% - 16px);
        }
        &.fieldRightMargin{
          margin-right: 16px;
        }
        &.fieldLeftMargin{
          margin-left: 16px;
        }
        &.fieldBothMargin{
          margin-right: 16px;
          margin-left: 16px;
        }
        .field-actions-cont{
          i{
            margin: 10px 6px;
            pointer-events: auto!important;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 575px){
    .step-group-cont{

    }
  }
</style>