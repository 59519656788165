<template>
  <div class="dashboard-header-cont d-flex justify-center align-center relative">
    <i v-if="!$store.state.mobileNavExpanded" class="fas fa-bars ft-size-20 pointer absolute" v-on:click="$store.commit('expandMobileNav')"></i>
    <i v-else class="fas fa-times ft-size-20 pointer absolute" v-on:click="$store.commit('shrinkMobileNav')"></i>
    <img src="../../assets/images/logo.svg" alt="virtuPort logo">
  </div>
</template>

<script>
  export default {
    name: "dashboard-header",
    mounted() {

    },
    data() {
      return {
 
      }
    },
    methods: {

    }
  };
</script>

<style scoped lang="scss">
  .dashboard-header-cont{
    height: 60px;
    z-index: 9;
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.1);
    img{
      max-width: 200px;
    }
    i{
      padding: 10px;
      left: 12px;
      color: #2d2f3d;
      display: none;
    }
  }

  @media only screen and (max-width: 768px){
    .dashboard-header-cont{
      i{
        display: block;
      }
    }
  }
</style>