<template>
  <div class="required-items-cont">
    <el-tabs v-model="activeTab">
      <el-tab-pane label="Sponsor Items" name="sponsorItems">
        <div class="sponsor-items-cont w-100 relative">
          <p class="title ft-size-18 ft-green">Sponsor Items</p>
          <div class="form-cont">
            <div v-for="(field,fieldIndex) in sponsorItems" v-bind:key="'sponsor' + fieldIndex" class="field flex">
              <field-component v-bind:details="field" ref="sponsorField" v-bind:noOptionalLabel="true"></field-component>
            </div>
          </div>
          <button class="primary ft-size-16 ft-neutral-white border-radius-4 absolute" v-bind:disabled="isSubmitting || $store.state.uploadStack.length > 0" v-on:click="submit">
            <img v-if="isSubmitting" src="../../assets/images/spinner.gif" alt="spinner" style="position: absolute;width: 25px;left: 4px;top: 8px;">
            Submit
          </button>
        </div>
      </el-tab-pane>
      <el-tab-pane label="Speakers Items" name="speakersItems" v-if="speakersTabVisible">
        <div class="speakers-items-cont w-100 relative">
          <p class="title ft-size-18 ft-green">Speakers</p>
          <div class="speakers-cont horizontal layout wrap">
            <speaker-card v-for="(speaker,index) in speakers" v-bind:key="'speaker' + index" v-bind:speaker="speaker" v-bind:entity="'speaker'" @refresh="getSpeakers"></speaker-card>
          </div>
          <button class="primary ft-size-16 ft-neutral-white border-radius-4 absolute" v-on:click="newSpeakerDialogVisible = true">+ Create New Speaker</button>
        </div>
        <el-dialog class="new-speaker-dialog" title="Create New Speaker" v-bind:visible.sync="newSpeakerDialogVisible" width="800px">
          <div class="form-cont">
            <div class="field flex">
              <field-component v-bind:details="nameField" ref="newSpeakerNameField"></field-component>
            </div>
            <div v-for="(field,fieldIndex) in newSpeakerForm" v-bind:key="'newspeaker' + fieldIndex" class="field flex">
              <field-component v-bind:details="field" ref="newSpeakerField" v-bind:noOptionalLabel="true"></field-component>
            </div>
          </div>
          <span slot="footer" class="dialog-footer">
            <button class="secondary ft-size-16 ft-primary border-radius-4" v-on:click="newSpeakerDialogVisible = false">Cancel</button>
            <button class="primary ft-size-16 ft-neutral-white border-radius-4 relative" v-bind:disabled="isSubmittingCreate || $store.state.uploadStack.length > 0" v-on:click="addSpeaker">
              <img v-if="isSubmittingCreate" src="../../assets/images/spinner.gif" alt="spinner" style="position: absolute;width: 25px;left: 4px;top: 4px;">
              Create
            </button>
          </span>
        </el-dialog>
      </el-tab-pane>
      <el-tab-pane v-for="(tab,index) in listingTabs" v-bind:key="'listing' + index" v-bind:label="tab.tab_name" v-bind:name="tab.tab_name">
        <div class="speakers-items-cont w-100 relative">
          <p class="title ft-size-18 ft-green">{{tab.tab_name}}</p>
          <div class="speakers-cont horizontal layout wrap">
            <speaker-card v-for="(listItem,listIndex) in tab.list" v-bind:key="'listing' + listIndex" v-bind:speaker="listItem" v-bind:entity="tab.tab_name" @refresh="getTabListings"></speaker-card>
          </div>
          <button class="primary ft-size-16 ft-neutral-white border-radius-4 absolute" v-on:click="openNewListingDialog(index)">+ Create New {{tab.tab_name}}</button>
        </div>
        <el-dialog class="new-listing-dialog" v-bind:title="'Create New ' + tab.tab_name" v-bind:visible.sync="newListingDialogVisible[index]" width="800px">
          <div class="form-cont">
            <div class="field flex">
              <field-component v-bind:details="listingNameField(tab.tab_name)" v-bind:ref="'newListingNameField' + index"></field-component>
            </div>
            <div v-for="(field,fieldIndex) in tab.listing_form" v-bind:key="'newlisting' + fieldIndex" class="field flex">
              <field-component v-bind:details="field" v-bind:ref="'newListingField' + index" v-bind:noOptionalLabel="true"></field-component>
            </div>
          </div>
          <span slot="footer" class="dialog-footer">
            <button class="secondary ft-size-16 ft-primary border-radius-4" v-on:click="closeNewListingDialog(index)">Cancel</button>
            <button class="primary ft-size-16 ft-neutral-white border-radius-4 relative" v-bind:disabled="isSubmittingCreate || $store.state.uploadStack.length > 0" v-on:click="addListing(index)">
              <img v-if="isSubmittingCreate" src="../../assets/images/spinner.gif" alt="spinner" style="position: absolute;width: 25px;left: 4px;top: 4px;">
              Create
            </button>
          </span>
        </el-dialog>
      </el-tab-pane>
      <el-tab-pane v-for="(tab,index) in dynamicTabs" v-bind:key="'dynamic' + index" v-bind:label="tab.tab_name" v-bind:name="tab.tab_name">
        <div class="dynamic-items-cont w-100 relative">
          <p class="title ft-size-18 ft-green">{{tab.tab_name}} Items</p>
          <div class="form-cont">
            <div v-for="(field,fieldIndex) in tab.tab_form" v-bind:key="'dynamic' + fieldIndex" class="field flex">
              <field-component v-bind:details="field" v-bind:ref="'dynamic_field_' + tab.tab_name" v-bind:noOptionalLabel="true"></field-component>
            </div>
          </div>
          <button v-if="tab.submit_url" class="primary ft-size-16 ft-neutral-white border-radius-4 absolute" v-bind:disabled="isSubmittingDynamic" v-on:click="submitDynamic(tab.tab_name,tab.submit_url)">
            <img v-if="isSubmittingDynamic" src="../../assets/images/spinner.gif" alt="spinner" style="position: absolute;width: 25px;left: 4px;top: 8px;">
            Submit
          </button>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
  import Vue from "vue";
  import {TabPane,Tabs,Dialog} from "element-ui";
  import FieldComponent from "../../components/Shared/FieldComponent.vue";
  import SpeakerCard from "../../components/Sponsor/SpeakerCard.vue";

  Vue.component("el-tabs",Tabs);
  Vue.component("el-tab-pane",TabPane);
  Vue.component("el-dialog",Dialog);
  Vue.component("field-component",FieldComponent);
  Vue.component("speaker-card",SpeakerCard);

  export default {
    name: "required-items",
    components: {
      Tabs,
      TabPane,
      Dialog,
      FieldComponent,
      SpeakerCard
    },
    created() {
      this.getSponsorItems();
      this.getSpeakers();
      this.getTabListings();
      this.getDynamicTabs();
    },
    mounted() {

    },
    data() {
      return {
        activeTab: "sponsorItems",
        newSpeakerDialogVisible: false,
        sponsorItems: [],
        sponsorItemsSubmitUrl: null,
        speakersTabVisible: false,
        speakers: [],
        newSpeakerForm: [],
        nameField: {
          input_type: "text",
          label: "Speaker Name",
          optional: false,
          placeholder: "Speaker name"
        },
        listingTabs: [],
        newListingDialogVisible: [],
        dynamicTabs: [],
        isSubmitting: false,
        isSubmittingDynamic: false,
        isSubmittingCreate: false
      }
    },
    methods: {
      getSponsorItems: function() {
        let module = this;

        this.xhrRequest("GET","/sponsor/required_items",null,
          function(response) {
            module.sponsorItems = response.required_items;
            module.sponsorItemsSubmitUrl = response.submit_url;
          },
          function(error) {
            console.log(error);
          }
        );
      },
      submit: function() {
        let module = this;

        let valid = true;
        let allFields = this.$refs.sponsorField;

        allFields.forEach(item => {
          if(!item.isValid())
            valid = false;
        });

        if(!valid)
          this.showNotification("Error","Please fill in all required fields, and make sure emails/phones are valid","error");
        else{
          let url = this.sponsorItemsSubmitUrl;
          let data = [];

          allFields.forEach(item => {
            data.push(item.getInputValuePair());
          });

          this.isSubmitting = true;
          this.xhrRequest("POST",url,data,
            function(response) {
              module.showNotification("Success","Items updated successfully","success");
              module.isSubmitting = false;
            },
            function(error) {
              let errorMsg = error.msg;
              module.showNotification("Error",errorMsg,"error");
              module.isSubmitting = false;
            }
          );
        }
      },
      getSpeakers: function() {
        let module = this;

        this.xhrRequest("GET","/sponsor/speakers",null,
          function(response) {
            if(response.speaker_form){
              module.speakersTabVisible = true;
              module.newSpeakerForm = response.speaker_form;
            }else{
              module.speakersTabVisible = false;
            }
      
            module.speakers = response.speakers;
          },
          function(error) {
            console.log(error);
          }
        );
      },
      addSpeaker: function() {
        let module = this;

        let valid = true;
        let allFields = this.$refs.newSpeakerField;

        allFields.forEach(item => {
          if(!item.isValid())
            valid = false;
        });

        if(!valid)
          this.showNotification("Error","Please fill in all required fields, and make sure emails/phones are valid","error");
        else{
          let formArray = [];

          allFields.forEach(item => {
            formArray.push(item.getInputValuePair());
          });

          let data = {
            speaker_name: this.$refs.newSpeakerNameField.textValue,
            speaker_form: formArray
          };
    
          if(data.speaker_name.trim() == ""){
            this.showNotification("Error","Please fill in speaker name","error");
            return false;
          }

          this.isSubmittingCreate = true;
          this.xhrRequest("POST","/sponsor/speaker",data,
            function(response) {
              module.showNotification("Success","Speaker created successfully","success");
              module.newSpeakerDialogVisible = false;
              module.getSpeakers();
              module.isSubmittingCreate = false;
            },
            function(error) {
              let errorMsg = error.msg;
              module.showNotification("Error",errorMsg,"error");
              module.isSubmittingCreate = false;
            }
          );
        }
      },
      getTabListings: function() {
        let module = this;
        
        this.xhrRequest("GET","/sponsor/listing_tabs",null,
          function(response) {
            module.listingTabs = response.listing_tabs;
            module.newListingDialogVisible = Array(module.listingTabs.length).fill(false);
          },
          function(error) {
            console.log(error);
          }
        );
      },
      addListing: function(index) {
        let module = this;

        let valid = true;
        let allFields = this.$refs["newListingField" + index];

        allFields.forEach(item => {
          if(!item.isValid())
            valid = false;
        });

        if(!valid)
          this.showNotification("Error","Please fill in all required fields, and make sure emails/phones are valid","error");
        else{
          let formArray = [];

          allFields.forEach(item => {
            formArray.push(item.getInputValuePair());
          });

          let data = {
            listing_name: (this.$refs["newListingNameField" + index])[0].textValue,
            listing_form: formArray
          };
    
          if(data.listing_name.trim() == ""){
            this.showNotification("Error","Please fill in the name","error");
            return false;
          }

          this.isSubmittingCreate = true;
          this.xhrRequest("POST","/sponsor/listing_tab",data,
            function(response) {
              module.showNotification("Success","Created successfully","success");
              module.closeNewListingDialog(index);
              module.getTabListings();
              module.isSubmittingCreate = false;
            },
            function(error) {
              let errorMsg = error.msg;
              module.showNotification("Error",errorMsg,"error");
              module.isSubmittingCreate = false;
            }
          );
        }
      },
      getDynamicTabs: function() {
        let module = this;
        
        this.xhrRequest("GET","/sponsor/dynamic_tabs",null,
          function(response) {
            module.dynamicTabs = response;
          },
          function(error) {
            console.log(error);
          }
        );
      },
      submitDynamic: function(tabName,submitUrl) {
        let module = this;

        let valid = true;

        let tabRef = 'dynamic_field_' + tabName;
        let allFields = [];
        for(let ref in this.$refs) {
          if(ref == tabRef){
            Array.prototype.push.apply(allFields,this.$refs[ref]);
          }
        }

        allFields.forEach(item => {
          if(!item.isValid())
            valid = false;
        });

        if(!valid)
          this.showNotification("Error","Please fill in all required fields, and make sure emails/phones are valid","error");
        else{
          let data = [];

          allFields.forEach(item => {
            data.push(item.getInputValuePair());
          });

          this.isSubmittingDynamic = true;
          this.xhrRequest("POST",submitUrl,data,
            function(response) {
              module.showNotification("Success","Items updated successfully","success");
              module.isSubmittingDynamic = false;
            },
            function(error) {
              let errorMsg = error.msg;
              module.showNotification("Error",errorMsg,"error");
              module.isSubmittingDynamic = false;
            }
          );
        }
      },
      listingNameField: function(entity) {
        return {
          input_type: "text",
          label: entity + " Name",
          optional: false,
          placeholder: entity + " Name"
        }
      },
      openNewListingDialog: function(index) {
        this.$set(this.newListingDialogVisible, index, true);
      },
      closeNewListingDialog: function(index) {
        this.$set(this.newListingDialogVisible, index, false);
      }
    }
  };
</script>

<style scoped lang="scss">
  .required-items-cont{
    .el-tabs{
      max-width: calc(100vw - 350px);
    }
    .sponsor-items-cont,.dynamic-items-cont{
      padding: 32px 0;
      .title{
        margin: 0 0 32px 0;
      }
      .form-cont{
        max-width: 100%;
        &>div{
          margin-bottom: 18px;
        }
      }
      button{
        top: 22px;
        right: 0;
        height: 42px;
        width: 115px;
      }
    }
    .speakers-items-cont{
      padding: 32px 0;
      .title{
        margin: 0 0 42px 0;
      }
      button{
        top: 22px;
        right: 0;
        height: 42px;
        width: 200px;
      }
    }
    .new-speaker-dialog,.new-listing-dialog{
      .form-cont{
        &>div{
          margin-bottom: 18px;
        }
      }
      button{
        width: 115px;
        height: 32px;
        margin-left: 8px;
      }
    }
  }

  @media only screen and (max-width: 575px){
    .required-items-cont{

    }
  }
</style>