<template>
  <div class="leads-cont">
    <div class="statistics-cont horizontal layout justify-center w-100 shadow">
      <div v-for="(stat,index) in statistics" v-bind:key="index" class="statistic">
        <div class="circle border-radius-full center d-flex align-center justify-center">
          <p class="ft-size-22 ft-w-bold text-center m-0">{{stat.count}}</p>
        </div>
        <p class="ft-size-16 ft-primary text-center m-0">{{stat.label}}</p>
      </div>
    </div>
    <div class="header horizontal layout align-center">
      <div class="filter-cont horizontal layout relative align-center">
        <el-cascader v-bind:options="filterOptions" v-bind:props="cascaderProps" v-bind:show-all-levels="true" placeholder="Filter" separator=": " v-model="selectedFilter"></el-cascader>
        <button class="secondary ft-size-14 ft-primary border-radius-4 relative" v-bind:disabled="!selectedFilter" v-on:click="selectedFilter = null">
          Clear
          <i class="fas fa-times absolute"></i>
        </button>
      </div>
      <span class="flex"></span>
      <button class="export-selected-btn secondary ft-size-14 ft-primary border-radius-4 relative" v-if="tableSelections.length > 0" v-on:click="exportData">
        Export Selected
        <i class="fas fa-file-download absolute"></i>
      </button>
      <button class="export-all-btn secondary ft-size-14 ft-primary border-radius-4 relative" v-if="exportAllAllowed" v-on:click="exportAllData">
        Export All
        <i class="fas fa-file-download absolute"></i>
      </button>
    </div>
    <div class="table-cont width-fit">
      <el-table class="shadow" ref="multipleTable" v-bind:data="tableData" style="width:100%" v-bind:row-key="rowKey" @selection-change="handleSelectionChange">
        <el-table-column type="selection" reserve-selection width="45"></el-table-column>
        <el-table-column v-for="(col,key) in tableData[0]" v-bind:key="key" v-bind:prop="key" v-bind:label="key" sortable width="150"></el-table-column>
      </el-table>
      <el-pagination layout="prev, pager, next" v-bind:total="total" v-bind:current-page="page" @current-change="setPage" background></el-pagination>
    </div>
  </div>
</template>

<script>
  import Vue from "vue";
  import {Cascader,Table,TableColumn,Pagination} from "element-ui";

  Vue.component("el-cascader",Cascader);
  Vue.component("el-table",Table);
  Vue.component("el-table-column",TableColumn);
  Vue.component("el-pagination",Pagination);

  export default {
    name: "leads",
    components: {
      Cascader,
      Table,
      TableColumn,
      Pagination
    },
    created() {
      this.getStats();
      this.getLeads();
      this.getFilters();
    },
    mounted() {

    },
    watch: {
      selectedFilter: function() {
        this.tableSelections = [];
        this.$refs.multipleTable.clearSelection();
        (this.page == 1)? this.getLeads() : this.page = 1;
      },
      page: function() {
        this.getLeads();
      }
    },
    data() {
      return {
        statistics: [],
        selectedFilter: null,
        filterOptions: [],
        tableData: [],
        tableSelections: [],
        pageSize: 10,
        page: 1,
        total: 0,
        exportAllAllowed: false,
        cascaderProps: {
          multiple: true
        }
      }
    },
    methods: {
      setPage: function(page) {
        this.page = page;
      },
      rowKey: function(row) {
        return row.Id;
      },
      handleSelectionChange: function(val) {
        this.tableSelections = val;
      },
      exportData: function() {
        let module = this;

        let idsList = [];
        this.tableSelections.forEach(item => {
          idsList.push(item.Id);
        });

        let data = {
          leads_ids: idsList
        };

        this.xhrRequest("POST","/sponsor/export",data,
          function(response) {
            module.showNotification("Success",response.msg,"success");
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          },
          function(error) {
            let errorMsg = error.msg;
            module.showNotification("Error",errorMsg,"error");
          }
        );
      },
      exportAllData: function() {
        let module = this;
        
        this.xhrRequest("POST","/sponsor/export_all",null,
          function(response) {
            module.showNotification("Success",response.msg,"success");
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          },
          function(error) {
            let errorMsg = error.msg;
            module.showNotification("Error",errorMsg,"error");
          }
        );
      },
      getStats: function() {
        let module = this;

        this.xhrRequest("GET","/sponsor/package_details",null,
          function(response) {
            let statistics = [];

            let totalCount = {
              label: "Total Leads",
              count: response.total_leads
            }
            let packageCount = {
              label: "Package Leads",
              count: response.package_leads
            }
            let leadscount = {
              label: "Leads Remaining",
              count: response.leads_remaining
            }
            statistics.push(totalCount,packageCount,leadscount);
            module.statistics = statistics;
            module.exportAllAllowed = response.export_all;
          },
          function(error) {
            console.log(error);
          }
        );
      },
      getLeads: function() {
        let module = this;

        let queryParams = "";
        let filterHash = {};
        let url = "";
        if(this.selectedFilter){
          this.selectedFilter.forEach(item => {
            if(!filterHash[item[0]])
              filterHash[item[0]] = item[1];
            else
              filterHash[item[0]] = filterHash[item[0]] + "," + item[1];
          });
          Object.entries(filterHash).forEach(item => {
            queryParams += ("&" + item[0] + "=" + item[1]);
          });
          url = "/sponsor/leads?per_page=" + this.pageSize + "&page=" + this.page + queryParams;
        }else{
          url = "/sponsor/leads?per_page=" + this.pageSize + "&page=" + this.page;
        }

        this.xhrRequest("GET",url,null,
          function(response) {
            module.tableData = response.leads;
            module.total = response.count;
          },
          function(error) {
            console.log(error);
          }
        );
      },
      getFilters: function() {
        let module = this;

        this.xhrRequest("GET","/sponsor/filters",null,
          function(response) {
            let parsedResponse = [];
            response.filters.forEach(item => {
              let childrenArray = [];
              item.data.forEach(child => {
                let childItem = {
                  value: child.value,
                  label: child.value
                }

                childrenArray.push(childItem);
              });

              let filterItem = {
                value: item.label,
                label: item.label,
                children: childrenArray
              };

              parsedResponse.push(filterItem);
            });
          
            module.filterOptions = parsedResponse;
          },
          function(error) {
            console.log(error);
          }
        );
      }
    }
  };
</script>

<style scoped lang="scss">
  .leads-cont{
    padding-bottom: 50px;
    max-width: calc(100vw - 260px);
    .statistics-cont{
      padding: 24px 0;
      margin-bottom: 40px;
      .statistic{
        width: 130px;
        margin: 0 16px;
        .circle{
          width: 90px;
          height: 90px;
          border: 5px solid transparent;
          background-clip: content-box, border-box;
          background-origin: border-box;
          margin-bottom: 12px;
        }
        &:nth-of-type(1){
          .circle{
            color: #00AF36;
            background-image: linear-gradient(white, white),linear-gradient(to bottom, #20562E, #49A84B);
          }
        }
        &:nth-of-type(2){
          .circle{
            color: #00AF36;
            background-image: linear-gradient(white, white),linear-gradient(to bottom, #7fd79a, #4eaf36);
          }
        }
        &:nth-of-type(3){
          .circle{
            color: #FF9922;
            background-image: linear-gradient(white, white),linear-gradient(to bottom, #f6c889, #f09927);
          }
        }
      }
    }
    .header{
      button{
        height: 45px;
        width: 85px;
        margin-left: 8px;
        border: 1px solid #dcdcdc;
        padding: 0 0 0 10px;
        i{
          left: 15px;
          top: 15px;
        }
      }
      .export-all-btn{
        width: 115px;
      }
      .export-selected-btn{
        width: 152px;
      }
    }
    .table-cont{
      margin-top: 20px;
      max-width: 100%;
      min-width: 300px;
    }
  }

  @media only screen and (max-width: 768px){
    .leads-cont{
      max-width: calc(100vw - 40px);
    }
  }

  @media only screen and (max-width: 575px){
    .leads-cont{
      .statistics-cont{
        padding: 16px 0;
        .statistic{
          width: 100%;
          margin: 0;
          .circle{
            width: 80px;
            height: 80px;
            &>p{
              font-size: 18px;
            }
          }
          &>p{
            padding: 0 20px;
            font-size: 14px;
          }
        }
      }
      .header{
        flex-direction: column;
        align-items: unset;
        .filter-cont{
          .el-cascader{
            width: 100%;
          }
          button{
            width: 110px;
          }
        }
        &>button{
          width: 100%!important;
          margin: 8px 0 0 0;
          padding: 0;
        }
      }
    }
  }
</style>