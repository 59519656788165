<template>
  <div class="builder-step-cont w-100">
    <div class="step-name-cont horizontal layout width-fit">
      <p class="ft-size-16 ft-primary">{{label}} name:</p>
      <input type="text" class="ft-size-16 ft-primary w-100" v-model="stepName">
    </div>
    <div v-if="packageForm" class="entity-type-cont horizontal layout width-fit">
      <p class="ft-size-16 ft-primary">entity type:</p>
      <div class="relative">
        <select class="ft-size-16 ft-primary w-100 pointer" v-bind:class="{'placeholder-color':entityTypeLocal == '0'}" v-model="entityTypeLocal">
          <option value="0" disabled>Select entity type</option>
          <option v-for="(entity,entityIndex) in entities" v-bind:key="entityIndex" v-bind:value="entity.value" v-bind:hidden="!entity.allowed" class="ft-primary">{{entity.title}}</option>
        </select>
        <i class="fas fa-chevron-down postfix-icon ft-icon-color absolute" v-on:click="clickSelect"></i>
      </div>
    </div>
    <div v-if="packageForm && id" class="read-only-cont horizontal layout width-fit">
      <p class="ft-size-16 ft-primary">read only:</p>
      <el-switch active-color="#13ce66" v-model="readOnlyLocal" v-on:change="readOnlyChanged"></el-switch>
    </div>
    <div v-if="groups.length == 0" class="empty-step">
      <i class="fas fa-ban ft-size-50 ft-primary text-center d-block"></i>
      <p class="ft-size-16 ft-primary text-center">This step is currently empty, start adding field groups</p>
    </div>
    <div v-else class="field-group-cont">
      <step-group v-for="(group,index) in groups" v-bind:key="group.index" v-bind:group="group" @removeGroup="removeGroup(index)" ref="stepGroup"></step-group>
    </div>
    <button class="primary ft-size-16 ft-neutral-white border-radius-4 d-block center" v-on:click="addFieldGroup">Add Field Group</button>
  </div>
</template>

<script>
  import Vue from "vue";
  import StepGroup from "./StepGroup.vue";
  import {Switch as ElSwitch} from "element-ui";

  Vue.component("step-group",StepGroup);
  Vue.component("el-switch",ElSwitch);

  export default {
    name: "builder-step",
    components: {
      StepGroup,
      ElSwitch
    },
    mounted() {
      if(this.step && this.step.fields_group){
        this.step.fields_group.forEach((group,groupIndex) => {
          let groupItem = {
            index: groupIndex,
            fields: group
          };
          
          this.groups.push(groupItem);
        });

        this.stepName = this.name;
      }
    },
    watch: {
      entityTypeLocal: function(newVal,oldVal) {
        let data = {
          newEntity: newVal,
          oldEntity: oldVal
        };

        this.$emit("entityChanged",data);
      }
    },
    props: {
      id: null,
      url: null,
      index: null,
      step: null,
      name: null,
      label: null,
      readOnly: null,
      packageForm: null,
      entityType: null,
      entities: Array
    },
    data() {
      return {
        groups: [],
        stepName: "",
        readOnlyLocal: this.readOnly,
        entityTypeLocal: this.entityType
      }
    },
    methods: {
      clickSelect: function() {
        this.$el.querySelector("select").click();
      },
      addFieldGroup: function() {
        let maxGroup = 0;
        if(this.groups.length > 0){
          maxGroup = Math.max.apply(Math, this.groups.map(function(item) { return item.index; }));
        }

        let newGroup = {
          index: (maxGroup + 1),
          fields: []
        };
        this.groups.push(newGroup);
      },
      removeGroup: function(removeIndex) {
        this.groups.splice(removeIndex,1);
      },
      readOnlyChanged: function() {
        let module = this;
        let data = {
          read_only: this.readOnlyLocal
        };

        this.xhrRequest("PUT",this.url + "/" + this.id,data,
          function(response) {
            module.showNotification("Success","Read only updated for this step successfully","success");
          },
          function(error) {
            let errorMsg = error.msg;
            module.showNotification("Error",errorMsg,"error");
          }
        );
      }
    }
  };
</script>

<style scoped lang="scss">
  .builder-step-cont{
    padding: 32px 0;
    .step-name-cont,.read-only-cont,.entity-type-cont{
      margin: 0 auto 12px auto;
      p{
        margin: 6px 12px 6px 0;
      }
      input{
        max-width: 180px;
      }
      .el-switch{
        width: 182px;
        top: 5px;
      }
      select{
        height: 30px;
        padding-right: 35px;
        width: 173px;
        &.placeholder-color{
          color: #c4c4c4;
        }
      }
      i{
        right: 15px;
        top: 9px;
        pointer-events: none;
      }
    }
    .empty-step{
      padding: 24px 0;
      i{
        display: block!important;
      }
    }
    .field-group-cont{
      margin-top: 24px;
    }
    button{
      width: 150px;
      height: 45px;
      margin-top: 32px;
    }
  }

  @media only screen and (max-width: 575px){
    .builder-step-cont{

    }
  }
</style>