<template>
  <div class="settings-cont">
    <el-tabs v-model="activeTab">
      <el-tab-pane label="Admin Configuration" name="configuration">
        <div class="configuration-cont w-100 relative">
          <p class="title ft-size-18 ft-green">Admin configurations</p>
          <div v-for="(tuple,index) in configurations" v-bind:key="index" class="tuple-cont">
            <div class="tuple horizontal layout align-center">
              <p class="ft-primary ft-size-16 m-0">{{tuple.key}}:</p>
              <input v-if="tuple.field_type == 'text'" type="text" class="ft-primary w-100" v-model="configurations[index].value" v-bind:data-id="tuple.id" ref="configInput">
              <input v-if="tuple.field_type == 'number'" type="number" class="ft-primary w-100" v-model="configurations[index].value" v-bind:data-id="tuple.id" ref="configInput">
              <el-switch v-if="tuple.field_type == 'boolean'" active-color="#13ce66" v-model="configurations[index].value" class="w-100" v-bind:data-id="tuple.id" ref="configInput"></el-switch>
              <el-date-picker v-if="tuple.field_type == 'date'" v-model="configurations[index].value" type="date" placeholder="Pick a day" format="yyyy-MM-dd" class="w-100" v-bind:data-id="tuple.id" ref="configInput"></el-date-picker>
            </div>
          </div>
          <button class="primary ft-size-16 ft-neutral-white border-radius-4 absolute" v-on:click="submitConfig">
            Submit
          </button>
        </div>
      </el-tab-pane>
      <el-tab-pane label="Assets Management" name="assets">
        <div class="assets-cont w-100 relative">
          <p class="title ft-size-18 ft-green">Assets management</p>
          <div v-for="(tuple,index) in assets" v-bind:key="index" class="tuple-cont">
            <div v-for="(item,index) in tuple" v-bind:key="index" class="tuple horizontal layout align-center">
              <p class="ft-primary ft-size-16 m-0">{{index}}: 
                <a v-if="item" v-bind:href="item" target="_blank">
                  <i class="fas fa-link ft-icon-color ft-size-14 pointer"></i>
                </a>
              </p>
              <input type="file" class="file-input" v-bind:attr="index" blobId="null" v-on:change="assetsFilePicked" ref="assetInput" v-bind:disabled="isUploadingFile">
            </div>
          </div>
          <button class="primary ft-size-16 ft-neutral-white border-radius-4 absolute" v-on:click="submitAssets" v-bind:disabled="isUploadingFile">
            Submit
          </button>
        </div>
      </el-tab-pane>
      <el-tab-pane label="Reset Password" name="resetPassword">
        <div class="reset-password-cont w-100">
          <div class="tuple horizontal layout align-center">
            <p class="ft-primary ft-size-16 m-0">Current Password:</p>
            <input type="password" placeholder="Enter your current password" class="ft-primary w-100" v-model="oldPassword">
          </div>
          <div class="tuple horizontal layout align-center">
            <p class="ft-primary ft-size-16 m-0">New Password:</p>
            <input type="password" placeholder="Enter your new password" class="ft-primary w-100" v-model="newPassword">
          </div>
          <div class="tuple horizontal layout">
            <span class="flex"></span>
            <button class="primary ft-size-16 ft-neutral-white border-radius-4" v-on:click="changePassword">Change Password</button>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
  import Vue from "vue";
  import {Switch as ElSwitch,TabPane,Tabs,DatePicker} from "element-ui";

  Vue.component("el-switch",ElSwitch);
  Vue.component("el-tabs",Tabs);
  Vue.component("el-tab-pane",TabPane);
  Vue.component("el-date-picker",DatePicker);

  export default {
    name: "settings",
    components: {
      Tabs,
      TabPane,
      ElSwitch,
      DatePicker
    },
    created() {
      this.getConfig();
      this.getAssets();
    },
    mounted() {

    },
    data() {
      return {
        activeTab: "configuration",
        oldPassword: "",
        newPassword: "",
        configurations: [],
        assets: [],
        isUploadingFile: false
      }
    },
    methods: {
      changePassword: function() {
        let module = this;

        if(this.oldPassword.trim() == "" || this.newPassword.trim() == ""){
          this.showNotification("Error","Please fill in both current and new password","error");
          return false;
        }

        let data = {
          password: this.oldPassword,
          new_password: this.newPassword
        };

        this.xhrRequest("POST","/admin/reset_password",data,
          function(response) {
            module.showNotification("Success","Password updated successfully","success");
          },
          function(error) {
            let errorMsg = error.msg;
            module.showNotification("Error",errorMsg,"error");
          }
        );
      },
      getConfig: function() {
        let module = this;

        this.xhrRequest("GET","/admin/configurations",null,
          function(response) {
            module.configurations = response.configurations;
          },
          function(error) {
            console.log(error);
          }
        );
      },
      getAssets: function() {
        let module = this;

        this.xhrRequest("GET","/admin/banners",null,
          function(response) {
            module.assets = response.banners;
          },
          function(error) {
            console.log(error);
          }
        );
      },
      submitConfig: function() {
        let module = this;

        let configurations = [];
        this.configurations.forEach(function(item,index){
          let id = "";
          let value = "";

          switch(item.field_type){
            case "text":
            case "number":
              id = module.$refs.configInput[index].getAttribute("data-id");
              value = module.$refs.configInput[index].value;
              break;
            case "boolean":
              id = module.$refs.configInput[index].$el.getAttribute("data-id");
              value = module.configurations[index].value;
              break;
            case "date":
              id = module.$refs.configInput[index].$el.getAttribute("data-id");
              value = module.$refs.configInput[index].$el.querySelector("input").value;
              break;
          }

          let configItem = {
            id: id,
            value: value
          };

          configurations.push(configItem);
        });
        
        let data = {
          configurations: configurations
        };

        this.xhrRequest("PUT","/admin/configurations",data,
          function(response) {
            module.showNotification("Success","Configurations updated successfully","success")
          },
          function(error) {
            console.log(error);
          }
        );
      },
      submitAssets: function() {
        let module = this;

        let assets = [];
        this.$refs.assetInput.forEach(function(item,index){
          let bloblId = item.getAttribute("blobId");
          if(bloblId != "null"){
            let assetItem = {
              key: item.getAttribute("attr"),
              value: item.getAttribute("blobId")
            };
  
            assets.push(assetItem);
          }
        });
        
        let data = {
          configurations: assets
        };

        if(data.configurations.length > 0){
          this.xhrRequest("PUT","/admin/banners",data,
            function(response) {
              module.getAssets();
              module.showNotification("Success","Assets updated successfully","success")
            },
            function(error) {
              console.log(error);
            }
          );
        }else{
          this.showNotification("Error","No assets have been changed","error");
        }
      },
      assetsFilePicked: function(e) {
        let module = this;
        let file = e.target.files[0];

        if(file){
          let fileName = file.name;
          let data = new FormData();
          data.append("file",file);
  
          this.isUploadingFile = true;
          let url = "/admin/upload_file";
          this.fileUploader("POST",url,data,fileName,
            function(response) {
              e.target.setAttribute("blobId",response.blob_id);
              module.isUploadingFile = false;
            },
            function(error) {
              console.log(error);
              module.isUploadingFile = false;
            }
          );
        }
      }
    }
  };
</script>

<style scoped lang="scss">
  .settings-cont{
    .configuration-cont,.assets-cont{
      padding: 32px 0;
      .title{
        margin: 0 0 32px 0;
      }
      .tuple-cont{
        max-width: 700px;
        .tuple{
          margin-bottom: 20px;
          p{
            width: 350px;
            padding-right: 24px;
          }
          input{
            height: 48px;
          }
        }
      }
      button{
        top: 22px;
        right: 0;
        height: 42px;
        width: 115px;
      }
    }
    .assets-cont{
      input{
        padding: 11px 18px;
      }
    }
    .reset-password-cont{
      padding: 32px 0;
      max-width: 700px;
      .tuple{
        margin-bottom: 20px;
        p{
          width: 225px;
        }
        input{
          height: 48px;
        }
      }
      button{
        width: 150px;   
        height: 40px;
        margin-top: 24px;
      }
    }
  }

  @media only screen and (max-width: 575px){
    .settings-cont{

    }
  }
</style>