<template>
  <div class="form-builder-cont w-100 relative border-radius-4 shadow">
    <div class="actions-cont absolute">
      <button v-if="currentStep > 1" class="secondary ft-size-16 ft-primary border-radius-4" v-on:click="removeStep">Remove {{label}}</button>
      <button class="secondary ft-size-16 ft-primary border-radius-4" v-on:click="addStep">Add {{label}}</button>
      <button class="primary ft-size-16 ft-neutral-white border-radius-4" v-on:click="submitForm">Submit</button>
    </div>
    <div class="header horizontal layout align-center space-between">
      <i class="ft-size-18 ft-green fas fa-chevron-left pointer" v-bind:style="{opacity: prevOpacity}" v-on:click="prevStep"></i>
      <p class="ft-size-18 ft-green ft-w-bold m-0">{{label}} {{currentStep}}</p>
      <i class="ft-size-18 ft-green fas fa-chevron-right pointer" v-bind:style="{opacity: nextOpacity}" v-on:click="nextStep"></i>
    </div>
    <div class="steps-cont">
      <builder-step v-bind:packageForm="packageForm" v-for="(step,index) in steps" v-bind:key="step.index" v-bind:index="step.index" v-bind:id="step.id" v-bind:entityType="step.entity_type" v-bind:readOnly="step.read_only" v-bind:step="step" v-bind:name="step.name" v-bind:label="label" v-bind:url="url" v-bind:entities="entities" @entityChanged="entityChanged" v-bind:style="{display: ((index + 1) != currentStep)? 'none':'block'}" ref="builderStep"></builder-step>
    </div>
  </div>
</template>

<script>
  import Vue from "vue";
  import BuilderStep from "./Builder/BuilderStep.vue";

  Vue.component("builder-step",BuilderStep);

  export default {
    name: "form-builder",
    components: {
      BuilderStep
    },
    mounted() {
      this.getForm();
    },
    computed: {
      prevOpacity: function() {
        let opacity = "";
        (this.currentStep > 1)? opacity = "1" : opacity = "0.3";
        return opacity;
      },
      nextOpacity: function() {
        let opacity = "";
        (this.currentStep < this.steps.length)? opacity = "1" : opacity = "0.3";
        return opacity;
      }
    },
    props: {
      url: null,
      label: null,
      packageForm: null
    },
    data() {
      return {
        currentStep: 1,
        steps: [
          {
            index: 0
          }
        ],
        entities: [
          {
            title: "Speaker",
            value: "speaker",
            allowed: true
          },
          {
            title: "Sponsor",
            value: "sponsor",
            allowed: true
          },
          {
            title: "Tab",
            value: "tab",
            allowed: true
          },
          {
            title: "Listing Tab",
            value: "listing",
            allowed: true
          }
        ]
      }
    },
    methods: {
      entityChanged: function(data) {
        if(data.newEntity == "speaker" || data.newEntity == "sponsor")
          this.entities.find(entity => entity.value == data.newEntity).allowed = false;

        if(data.oldEntity == "speaker" || data.oldEntity == "sponsor")
          this.entities.find(entity => entity.value == data.oldEntity).allowed = true;
      },
      getForm: function() {
        let module = this;

        let url = this.url;
        this.xhrRequest("GET",url,null,
          function(response) {
            module.steps = response.form_template.template;
            module.steps.forEach(step => {
              if(step.entity_type == "speaker" || step.entity_type == "sponsor")
                module.entities.find(entity => entity.value == step.entity_type).allowed = false;
            });
          },
          function(error) {
            console.log(error);
          }
        );
      },
      submitForm: function() {
        let module = this;
        let steps = [];
        let valid = true;

        this.$refs.builderStep.forEach((step, stepIndex) => {
          if(step.groups.length == 0 || step.stepName.trim() == ""){
            if(step.groups.length == 0)
              this.showNotification("Error","One or more " + module.label + "s are empty","error");
            else
              this.showNotification("Error","Missing one or more " + module.label + "s names","error");

            valid = false;
            return false;
          }

          let groups = [];
          step.$refs.stepGroup.forEach(group => {
            if(group.fields.length == 0){
              this.showNotification("Error","One or more groups are empty","error");
              valid = false;
              return false;
            }

            let fields = [];
            group.$refs.field.forEach(field => {
              fields.push(field.$options.propsData.details);
            });

            groups.push(fields);
          });

          let read_only = false;
          if(step.id)
            read_only = step.readOnlyLocal

          let stepObject = {
            fields_group: groups,
            index: stepIndex + 1,
            name: step.stepName,
            read_only: read_only
          };

          let entity_type = step.entityTypeLocal;
          if(entity_type != "0")
            stepObject.entity_type = entity_type;

          steps.push(stepObject);
        });

        if(!valid)
          return false;

        let data = {
          form_template: {
            template: steps
          }
        };

        let url = this.url;
        this.xhrRequest("POST",url,data,
          function(response) {
            module.showNotification("Success",response.msg,"success");
            module.getForm();
          },
          function(error) {
            console.log(error);
            module.showNotification("Error",error.msg,"error");
          }
        );
      },
      prevStep: function() {
        if(this.currentStep > 1){
          this.currentStep--;
        }
      },
      nextStep: function() {
        if(this.currentStep < this.steps.length){
          this.currentStep++;
        }
      },
      addStep: function() {
        let newStep = {
          index: (this.steps.length + 1)
        };
        this.steps.push(newStep);

        this.currentStep = this.steps.length;
      },
      removeStep: function() {
        let removeStepIndex = (this.currentStep - 1);

        let stepEntity = "";
        this.$refs.builderStep.forEach((step, stepIndex) => {
          if(stepIndex == removeStepIndex)
            stepEntity = step.entityTypeLocal;  
        });
        
        let data = {
          oldEntity: stepEntity
        };
        this.entityChanged(data);

        this.steps.splice(removeStepIndex,1);
        this.currentStep--;
      }
    }
  };
</script>

<style scoped lang="scss">
  .form-builder-cont{
    border: 1px solid #DDDFE1;
    padding: 24px;
    .actions-cont{
      top: 16px;
      right: 16px;
      &>button{
        height: 45px;
        width: 110px;
        margin: 0 0 0 8px;
      }
    }
    .header{
      margin-top: 75px;
      i{
        padding: 10px;
      }
    }
  }

  @media only screen and (max-width: 575px){
    .form-builder-cont{

    }
  }
</style>