<template>
  <div class="delegates-form-page-cont w-100 relative">
    <p class="title ft-size-20 ft-green ft-w-bold">Delegates form</p>
    <div class="builder-cont">
      <form-builder v-bind:url="url" v-bind:label="'step'"></form-builder>
    </div>
  </div>
</template>

<script>
  import Vue from "vue";
  import FormBuilder from "../../components/Shared/FormBuilder.vue";

  Vue.component("form-builder",FormBuilder);

  export default {
    name: "delegates-form-page",
    components: {
      FormBuilder
    },
    mounted() {

    },
    computed: {
      url: function() {
        return "/admin/user_groups/" + this.$route.params.id +"/delegates/form_templates";
      }
    },
    data() {
      return {

      }
    },
    methods: {
      
    }
  };
</script>

<style scoped lang="scss">
  .delegates-form-page-cont{
    padding: 32px 0;
    .title{
      margin: 0 0 32px 0;
    }
  }

  @media only screen and (max-width: 575px){
    .delegates-form-page-cont{

    }
  }
</style>