<template>
  <div class="speaker-card-cont border-radius-4 relative">
    <div class="gradient transition-300 border-radius-4 w-100 h-100 absolute"></div>
    <div class="card-content vertical layout align-center relative">
      <i class="fas fa-user-tie text-center ft-neutral-white border-radius-full"></i>
      <p class="ft-size-18 ft-primary m-0 transition-300">{{speaker.name}}</p>
      <div class="actions-cont horizontal layout align-center">
        <button v-if="!admin" class="ft-size-14 ft-primary bg-neutral-white transition-300" v-on:click="editSpeakerDialogVisible = true">
          <i class="fas fa-pen"></i>
          Edit Items
        </button>
        <button v-else class="ft-size-14 ft-primary bg-neutral-white transition-300" v-on:click="editSpeakerDialogVisible = true">
          <i class="fas fa-eye"></i>
          View
        </button>
        <button v-if="!admin" class="ft-size-14 ft-primary bg-neutral-white transition-300" v-on:click="deleteSpeakerDialogVisible = true">
          <i class="fas fa-trash-alt"></i>
          Delete
        </button>
      </div>
    </div>
    <el-dialog class="delete-speaker-dialog" v-bind:title="deleteTitle" v-bind:visible.sync="deleteSpeakerDialogVisible" width="400px">
      <p class="ft-size-16 ft-primary m-0">Are you sure you want to delete this {{entity}}?</p>
      <span slot="footer" class="dialog-footer">
        <button class="secondary ft-size-16 ft-primary border-radius-4" v-on:click="deleteSpeakerDialogVisible = false">Cancel</button>
        <button class="primary ft-size-16 ft-neutral-white border-radius-4" v-on:click="deleteSpeaker">Delete</button>
      </span>
    </el-dialog>
    <el-dialog class="edit-speaker-dialog" v-bind:title="editModalTitle" v-bind:visible.sync="editSpeakerDialogVisible" width="800px">
      <div class="form-cont">
        <div v-for="(field,fieldIndex) in listings" v-bind:key="fieldIndex" class="field flex">
          <field-component v-if="!admin" v-bind:details="field" ref="speakerField" v-bind:noOptionalLabel="true"></field-component>
          <field-component v-else v-bind:details="field" ref="speakerField" v-bind:noOptionalLabel="true" v-bind:adminMode="true" v-bind:statuses="statuses" v-bind:entity="entity" v-bind:speakerId="speaker.id"></field-component>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <button v-if="!admin" class="secondary ft-size-16 ft-primary border-radius-4" v-on:click="editSpeakerDialogVisible = false">Cancel</button>
        <button v-if="!admin" class="primary ft-size-16 ft-neutral-white border-radius-4 relative" v-bind:disabled="isSubmitting || $store.state.uploadStack.length > 0" v-on:click="submit">
          <img v-if="isSubmitting" src="../../assets/images/spinner.gif" alt="spinner" style="position: absolute;width: 25px;left: 4px;top: 4px;">
          Save
        </button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import Vue from "vue";
  import {Dialog} from "element-ui";
  import FieldComponent from "../../components/Shared/FieldComponent.vue";

  Vue.component("el-dialog",Dialog);
  Vue.component("field-component",FieldComponent);

  export default {
    name: "speaker-card",
    components: {
      Dialog,
      FieldComponent
    },
    mounted() {

    },
    computed: {
      deleteTitle: function() {
        return "Delete " + this.speaker.name;
      },
      editModalTitle: function() {
        let title = "";
        (this.admin)? title = this.speaker.name + " Items" : title = "Edit " + this.speaker.name + " Items";
        return title;
      },
      listings:{
        get(){
          return (this.entity == "speaker")? this.listings = this.speaker.speaker_form : this.listings = this.speaker.listing_form;
        },
        set(){}
      }
    },
    props: {
      speaker: Object,
      admin: false,
      statuses: null,
      entity: null
    },
    data() {
      return {
        deleteSpeakerDialogVisible: false,
        editSpeakerDialogVisible: false,
        isSubmitting: false
      }
    },
    methods: {
      deleteSpeaker: function() {
        let module = this;
    
        let url = "";
        (this.entity == "speaker")? url = "/sponsor/speaker/" + this.speaker.id : url = "/sponsor/listing_tab/" + this.speaker.id; 
        this.xhrRequest("DELETE",url,null,
          function(response) {
            module.showNotification("Success",module.entity + " removed successfully","success");
            module.deleteSpeakerDialogVisible = false;
            module.$emit("refresh");
          },
          function(error) {
            let errorMsg = error.msg;
            module.showNotification("Error",errorMsg,"error");
          }
        );
      },
      submit: function() {
        let module = this;
    
        let valid = true;
        let allFields = this.$refs.speakerField;

        allFields.forEach(item => {
          if(!item.isValid())
            valid = false;
        });

        if(!valid)
          this.showNotification("Error","Please fill in all fields","error");
        else{
          let data = [];

          allFields.forEach(item => {
            data.push(item.getInputValuePair());
          });

          this.isSubmitting = true;
          let url = "";
          (this.entity == "speaker")? url = "/sponsor/speaker/" + this.speaker.id : url = "/sponsor/listing_tab/" + this.speaker.id; 
          this.xhrRequest("PUT",url,data,
            function(response) {
              module.showNotification("Success",module.entity + " items updated successfully","success");
              module.editSpeakerDialogVisible = false;
              module.$emit("refresh");
              module.isSubmitting = false;
            },
            function(error) {
              let errorMsg = error.msg;
              module.showNotification("Error",errorMsg,"error");
              module.isSubmitting = false;
            }
          );
        }
      }
    }
  };
</script>

<style scoped lang="scss">
  .speaker-card-cont{
    border: 1px solid #DDDFE1;
    width: 250px;
    height: 250px;
    margin: 0 18px 18px 0;
    .gradient{
      background: linear-gradient(180deg, #2D2F3D -0.03%, #2D2F3D 55.72%, #00AF5B 100.01%);
      opacity: 0;
    }
    .card-content{
      &>i{
        font-size: 75px;
        background-color: #dcdcdc;
        padding: 18px;
        width: 115px;
        height: 115px;
        margin: 24px 0;
      }
      .actions-cont{
        button{
          border: 1px solid #DDDFE1;
          width: 100px;
          height: 32px;
          margin: 14px 6px;
        }
      }
    }
    &:hover{
      .gradient{
        opacity: 1;
      }
      .card-content{
        &>p{
          color: #FFF;
        }
        .actions-cont{
          button{
            background-color: transparent;
            border-color: #FFF;
            color: #FFF;
          }
        }
      }
    }
    .delete-speaker-dialog{
      button{
        width: 100px;
        height: 32px;
        margin-left: 8px;
      }
    }
    .edit-speaker-dialog{
      .form-cont{
        &>div{
          margin-bottom: 18px;
        }
      }
      button{
        width: 100px;
        height: 32px;
        margin-left: 8px;
      }
    }
  }

  @media only screen and (max-width: 575px){
    .speaker-card-cont{
      margin: 0 auto 18px auto;
    }
  }
</style>